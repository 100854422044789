import { BaseSecuredApi } from "./BaseSecuredApi";

export interface IGetActions {
    pageNo: number;
    actionType: number;
    fromDate: string;
    accountId: string;
    pageSize: number;
}


export interface IAction {
    type: number;
    accountNumber: number;
    // type: number,
    fundName: string,
    employeeAmount: number,
    compensationAmount: number,
    employerAmount: number,
    totalAmount: number,
    salaryDate: string,
    valueDate: string,
    actionDate: string,
    month: number,
    itsEvaluated: number,
    reason: string,
    salary: string,
    transferFromFundName: string,
    transferToFundName: string,
    transferFromCourse: string,
    comment: string

}

class RecentActions extends BaseSecuredApi {

    constructor() {
        super("UserData");
    }

    get(data: IGetActions) {
        return this.POST<IAction[]>({
            data,
            action: "Recent/Actions",
            cache: false
        });
    }

}

export const RecentActionsApi = new RecentActions();