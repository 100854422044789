import { CmsApi } from "../api";
import { ICmsContent } from "../api/Cms"

let _content: ICmsContent;

const CmsService = {

    updateContent() {
        return CmsApi.content().then(content => {
            _content = content;

            const style = document.createElement("style");
            // WebKit hack :(
            style.appendChild(document.createTextNode(`.default-background { background-image: url(${content.backgroundImage}), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.74) 52%, rgba(255, 255, 255, 0.91))}`));
            document.head.appendChild(style);

        });
    },

    get content() {
        return _content;
    }

}

export default CmsService;