import { UserDataService } from '../services';
import config from '../config';
import { Subject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const pdfHeaders = {
  Accept: 'application/pdf',
};

const htmlHeaders = {
  Accept: 'application/json',
};

export class BaseSecuredApi {
  public static baseApiUrl = config.API_URL;
  protected readonly _controller: string;
  protected subjects: { [id: string]: any } = {};

  constructor(controller: string) {
    this._controller = controller;
  }

  protected static getUrl = (controller: string, action: string) =>
    `${BaseSecuredApi.baseApiUrl}/api/${controller}/${action}`;
  protected static getRelativeUrl = (action: string) => `${action}`;
  protected static getHeaders() {
    return !UserDataService.isLoggedIn
      ? headers
      : {
          ...headers,
          Authorization: 'Bearer ' + UserDataService.currentUser.token,
        };
  }
  private static getFormHeaders() {
    return !UserDataService.isLoggedIn
      ? {}
      : {
          Authorization: 'Bearer ' + UserDataService.currentUser.token,
        };
  }

  private static getPdfHeaders() {
    return !UserDataService.isLoggedIn
      ? {}
      : {
          ...pdfHeaders,
          Authorization: 'Bearer ' + UserDataService.currentUser.token,
          // responseType: 'blob',
        };
  }

  private static getHtmlHeaders() {
    return !UserDataService.isLoggedIn
      ? {}
      : {
          ...htmlHeaders,
          Authorization: 'Bearer ' + UserDataService.currentUser.token,
          // responseType: 'blob',
        };
  }

  protected POST<TResponse>(
    request: { action?: string; data?: {}; controller?: string; cache?: boolean; cacheKey?: string } = {}
  ) {
    const action = request.action || '';
    const controller = request.controller || this._controller;
    const cache = request.cache === true;

    const subject = new Subject<TResponse>();
    let result: Observable<TResponse>;

    if (cache) {
      const key = action + '_' + (request.cacheKey || '');
      result = this.subjects[key];

      if (result) {
        return result.toPromise();
      }

      result = this.subjects[key] = subject.pipe(shareReplay());
    } else {
      result = subject.asObservable();
    }

    fetch(BaseSecuredApi.getUrl(controller, action), {
      method: 'POST',
      headers: BaseSecuredApi.getHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      body: request.data ? JSON.stringify(request.data) : null,
    })
      .then((response) => {
        if (response.status === 204) {
          throw Error;
        }
        return (response ? (response.ok ? response.json() : { status: response.status }) : null) as Promise<TResponse>;
      })
      .then((res) => {
        subject.next(res);
        subject.complete();
      })
      .catch((res) => subject.error(res));

    return result.toPromise();
  }

  protected POST_LOGIN<TResponse>(
    request: { action?: string; data?: {}; controller?: string; cache?: boolean; cacheKey?: string } = {}
  ) {
    const action = request.action || '';
    const controller = request.controller || this._controller;
    const cache = request.cache === true;

    const subject = new Subject<TResponse>();
    let result: Observable<TResponse>;

    if (cache) {
      const key = action + '_' + (request.cacheKey || '');
      result = this.subjects[key];

      if (result) {
        return result.toPromise();
      }

      result = this.subjects[key] = subject.pipe(shareReplay());
    } else {
      result = subject.asObservable();
    }

    fetch(BaseSecuredApi.getUrl(controller, action), {
      method: 'POST',
      headers: BaseSecuredApi.getHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      body: request.data ? JSON.stringify(request.data) : null,
    })
      .then((response) => {
        return (response ? (response.status === 200 ? response.json() : { status: response.status }) : null) as Promise<TResponse>;
      })
      .then((res) => {
        subject.next(res);
        subject.complete();
      })
      .catch((res) => subject.error(res));

    return result.toPromise();
  }

  protected GET<TResponse>(
    request: { action?: string; data?: {}; controller?: string; cache?: boolean; cacheKey?: string } = {}
  ) {
    const action = request.action || '';
    const controller = request.controller || this._controller;
    const cache = request.cache === true;

    const subject = new Subject<TResponse>();
    let result: Observable<TResponse>;

    if (cache) {
      const key = action + '_' + (request.cacheKey || '');
      result = this.subjects[key];

      if (result) {
        return result.toPromise();
      }

      result = this.subjects[key] = subject.pipe(shareReplay());
    } else {
      result = subject.asObservable();
    }

    fetch(BaseSecuredApi.getUrl(controller, action), {
      method: 'GET',
      headers: BaseSecuredApi.getHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      body: request.data ? JSON.stringify(request.data) : null,
    })
      .then(
        (response) =>
          (response ? (response.ok ? response.json() : { status: response.status }) : null) as Promise<TResponse>
      )
      .then((res) => {
        subject.next(res);
        subject.complete();
      })
      .catch((res) => subject.error(res));

    return result.toPromise();
  }

  protected FORM_POST<TResponse>(
    request: {
      action?: string;
      data?: any;
      controller?: string;
      cache?: boolean;
      cacheKey?: string;
      isHtml?: boolean;
    } = {}
  ) {
    const action = request.action || '';
    const controller = request.controller || this._controller;
    const cache = request.cache === true;

    const subject = new Subject<TResponse>();
    let result: Observable<TResponse>;

    if (cache) {
      const key = action + '_' + (request.cacheKey || '');
      result = this.subjects[key];

      if (result) {
        return result.toPromise();
      }

      result = this.subjects[key] = subject.pipe(shareReplay());
    } else {
      result = subject.asObservable();
    }

    fetch(BaseSecuredApi.getUrl(controller, action), {
      method: 'POST',
      headers: UserDataService.isBlockedToAction
        ? !request.isHtml
          ? BaseSecuredApi.getPdfHeaders()
          : BaseSecuredApi.getHtmlHeaders()
        : BaseSecuredApi.getFormHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      body: request.data,
    })
      .then((response) => {
        if (UserDataService.isBlockedToAction) {
          if (!request.isHtml) return (response && response.ok ? response.blob() : null) as Promise<any>;
          return (response ? (response.ok ? response.text() : null) : null) as Promise<any>;
        }
        return (response ? (response.ok ? response.json() : { status: response.status }) : null) as Promise<TResponse>;
      })
      .then((res) => {
        subject.next(res);
        subject.complete();
      })
      .catch((res) => subject.error(res));

    return result.toPromise();
  }

  protected DownloadBlob<blob>(
    request: { action?: string; data?: {}; controller?: string; cache?: boolean; cacheKey?: string } = {}
  ) {
    const action = request.action || '';
    const controller = request.controller || this._controller;
    const cache = request.cache === true;
    const subject = new Subject<blob>();
    let result: Observable<blob>;

    if (cache) {
      const key = action + '_' + (request.cacheKey || '');
      result = this.subjects[key];

      if (result) {
        return result.toPromise();
      }

      result = this.subjects[key] = subject.pipe(shareReplay());
    } else {
      result = subject.asObservable();
    }
    fetch(BaseSecuredApi.getUrl(controller, action), {
      method: 'POST',
      headers: BaseSecuredApi.getHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      body: request.data ? JSON.stringify(request.data) : null,
    })
      .then((response) => (response && response.ok ? response.blob() : null) as Promise<any>)
      .then((res) => {
        subject.next(res);
        subject.complete();
      })
      .catch((res) => subject.error(res));

    return result.toPromise();
  }
}
