import React, { Fragment } from 'react';
import MultiSelect from 'react-bootstrap-multiselect';


export interface ICustomSelectItem {
  label: string;
  value: string;
}

interface ICustomSelectProps {
  id?: string;
  name?: string;
  required?: boolean;
  className?: string;
  buttonClassName?: string;
  onChange?: (value: string) => void;
  options: ICustomSelectItem[];
  value?: string;
  label?: string;
  closeButton?: boolean;
}
declare const $: any;


export default class CustomMultiSelect extends React.Component<ICustomSelectProps, { selected: ICustomSelectItem }> {
  static index = 0;
  id = 'dropdown-custom-' + CustomMultiSelect.index++;
  firstUse: boolean = true;
  constructor(props: ICustomSelectProps) {
    super(props);
  }

  componentDidMount() {
    $('.test-multiselect').multiselect({
      numberDisplayed: 1,
      includeSelectAllOption: true,
      allSelectedText: 'הכל נבחר',
      selectAllNumber: true,
      selectAllText: 'הכל',
      nSelectedText: 'נבחרו',
      nonSelectedText: 'לא בוצעה בחירה'
    });
    
    if ($('.multiselect-native-select').length === 4) {
      $('.multiselect-native-select')[0].style.display = 'none'
      $('.multiselect-native-select')[2].style.display = 'none'
    }

    $('.multiselect-native-select .dropdown-toggle').each(function (i: any, o: any) {
      $(o)[0].click();
    });
  }


  render() {
    return (
      <Fragment>
        <MultiSelect data={this.props.options} multiple data-placeholder={this.props.label} />
        <select className="test-multiselect" multiple>
          {this.props.options &&
            this.props.options.map((o, i) => {
              return (
                <option key={i} value={o.value}>
                  {o.label}
                </option>
              );
            })}
        </select>
      </Fragment>
    );
  }
}
