import React from 'react';
import { BaseComponent } from '..';
import { Redirect } from 'react-router';
import { UserDataService } from '../../services';

interface IProps {
    match: any
}

interface IState {
    redirectTo: string
}

export default class RemoteLogin extends BaseComponent<IProps, IState> {

    constructor(props: Readonly<IProps>) {
        super(props);

        this.state = {
            redirectTo: null
        };
    }

    componentDidMount() {
        UserDataService.remoteLogin(this.token)
            .then(res => {
                this.setState({
                    redirectTo: res && res.success ? '/customers/home' : '/customers/login'
                });
            });
    }

    get token() {
        return this.props.match.params.token as string;
    }

    render() {

        if (this.state.redirectTo) {
            sessionStorage.setItem("imp", "1");
            return <Redirect to={this.state.redirectTo}></Redirect>;
        }

        return <div style={{ textAlign: "center", paddingTop: '2em' }}><img src="/img/loading.gif" alt="loading" /></div>;
    }
};