import React from "react";
import { CmsService } from "../services";
import { ICmsPage } from "../api/Cms";
import $ from "jquery";

export default class BaseComponent<P, S> extends React.Component<P, S> {
  protected cmsPageAlias: string = null;

  get cmsContent() {
    return this.cmsPageAlias &&
      CmsService.content &&
      CmsService.content.pages[this.cmsPageAlias]
      ? (CmsService.content.pages[this.cmsPageAlias] as ICmsPage)
      : null;
  }

  formToJson(form: HTMLFormElement) {
    const inputs = $(form).serializeArray();
    const result: any = {};
    inputs.forEach((n) => {
      result[n.name] = n.value;
    });
    return result;
  }

  deserializeForm<T>(form: HTMLFormElement) {
    return this.formToJson(form) as T;
  }

  label(key: string) {
    var retVal: string = "";
    try {
      retVal = CmsService.content.labels[key.toLowerCase()] || key;
    } catch (e) {
      return key;
    }
    return retVal;
  }

  siteSetting(key: string) {
    var retVal: string = "";
    try {
      retVal = CmsService.content.pages.sitesettings[key] || key;
    } catch (e) {
      return key;
    }
    return retVal;
  }

  getYearsArray(comp: string) {
   
    
    var syb =  this.siteSetting('numberyearstoshow')
    if (syb == null) {
      if (comp == "SYBS") syb = "3";
      if (comp == "SYBT") syb = "3";
      if (comp == "SYBR") syb = "11";
    }

    var maxRevaluationDate = sessionStorage.getItem("maxRevaluationDate");
    var years = new Array();
    let i: number;
    for (i = 0; i < parseInt(syb); i++) {
      years.push(parseInt(maxRevaluationDate) - i);
    }
    return years;
  }
}
