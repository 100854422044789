import React from "react";
import { BaseComponent } from "..";
import { NavLink } from "react-router-dom";
import { TopDucks, Chart, CmsBanners, CmsBannersInner } from "../Macros";
import { DashboardApi } from "../../api";
import { IGetDashboardResult } from "../../api/Dashboard";
import { UserDataService } from "../../services";

export default class Dashboard extends BaseComponent<{}, IGetDashboardResult> {
  cmsPageAlias = "dashboard";

    constructor(props: any) {
        super(props);
        this.state = {
         // showGemel: sessionStorage.getItem('isGemelShowed')as unknown as boolean
          showGemel:  JSON.parse(sessionStorage.getItem('isGemelShowed')?.toLowerCase())
        };
      
    }

  getPalletteArray() {
    var retVal = new Array();
    for (var i = 1; i < 6; i++) {
      var str = this.siteSetting("colorpallette" + i);
      var arr = str.split(",");
      retVal.push(arr);
    }

    return retVal;
  }

  isCharts() {
    return this.state && this.state.charts?.some((c:any) => c.label !== "0");
  }

  componentDidMount() {
    
    

    if ( this.checkKindBunner[0]?.EndOfYearInvestmentGemel == 1 && this.checkKindBunner[0]?.EndOfYearSelfEmployed == 1){
      
    //  sessionStorage.setItem("isGemelShowed",JSON.stringify(!(this.state.showGemel)as unknown as boolean))
     sessionStorage.setItem("isGemelShowed",JSON.stringify(!(JSON.parse(this?.state?.showGemel))))

      }
    

    // if ( this.checkKindBunner[0]?.EndOfYearInvestmentGemel == 1 && this.checkKindBunner[0]?.EndOfYearSelfEmployed == 1){
    //  this.isGemelShow = 
    // }

    DashboardApi.get().then((res) => {
      var arr = this.getPalletteArray();

      for (var i = 0; i < res.charts.length; i++) {
        res.charts[i].colors = arr[i];
      }
      this.setState(res);
      sessionStorage.setItem("updatedate", res.updateDate);
    });
  }



  groupedData = this.cmsContent.banners.reduce((acc: any, item: any) => {
    // Create a new group if it doesn't exist
    if (!acc[item.group]) {
      acc[item.group] = [];
    }
    // Add the item to the group
    acc[item.group].push(item);
    return acc;
  }, {});

  group_1 = this.groupedData['1'] || [];
  group_2 = this.groupedData['2'] || [];
  group_3 = this.groupedData['3'] || [];
  group_4 = this.groupedData['4'] || [];
  group_5 = this.groupedData['5'] || [];



  checkKindBunner = JSON.parse(sessionStorage.getItem("zoomAnaliticsData"))


  render() {
    const balanceNote = this.label("dashboard_balance_note").split(" ");
    return (
      <>
        <TopDucks />

        <section className="main-dashboard">
          <div className="content-wrapper">
            <div className="main-dashboard__welcome">
              <h3 className="main-dashboard__welcome-person">
                {UserDataService.data.firstname} {UserDataService.data.lastname}
              </h3>
              <p className="main-dashboard__welcome-last-login">
                {this.state && this.state.updateDate && (
                  <span>
                    {this.label("dashboard_date")}
                    <time dateTime={this.state.updateDate} className="number">
                      {this.state.updateDate}
                    </time>
                  </span>
                )}
              </p>
            </div>
            <div className="main-dashboard__header">
              <h1 className="main-dashboard__heading">
                {this.label("dashboard_balance")}{" "}
                <span className="number">{this.state && this.state.total}</span>
              </h1>

              {
                this.state && this.state.totalTransactionAmount != '0' ? (
                  <div className="totalTransactionAmount">

                    <span className="amount-style">לידיעתך סך של  <span style={{fontWeight:"bold"}}>{this.state && this.state.totalTransactionAmount} ₪  </span>נקלט בחשבונותיך לאחר מועד השיערוך

                    </span>

                    <div className="col-auto main-dashboard__btn-col">
                      <NavLink
                        to="/customers/home/recent-actions"
                        className="main-dashboard__btn"
                      >
                        לצפיה בפעולות אחרונות
                      </NavLink>
                    </div>
                  </div>)
                  :
                  <span></span>

              }
              {UserDataService.data.userRole === 3 && (
                <span className="main-dashboard__balance-note">
                  <span>
                    {balanceNote.map((w, i) => {
                      if (w === "{amount}")
                        return (
                          <span key={i} className="number">
                            ₪{UserDataService.data.lastActionAmount || 0}
                          </span>
                        );
                      return `${w} `;
                    })}
                  </span>
                  <br />
                  {this.label("after_last_revision_date")}
                </span>
              )}
              <p className="main-dashboard__note dispbelow992">
                {this.state && this.state.updateDate && (
                  <div className="updatetxt">
                    {this.label("dashboard_date")}
                    <time dateTime={this.state.updateDate} className="number">
                      {this.state.updateDate}
                    </time>
                  </div>
                )}
                <div>{this.label("dashboard_disc")}</div>
              </p>
            </div>
            <div
              className="main-dashboard__charts-block"
              style={{
                visibility:
                  UserDataService.data.userRole === 3 ? "hidden" : "inherit",
                height: this.isCharts() ? "auto" : "190px",
              }}
            >
              <div className="container-fluid main-dashboard__charts-container">
                <div className="row main-dashboard__charts-row">
                  {this.state &&
                    this.state?.charts?.map((x:any, i:any) => (
                      <div className="main-dashboard__charts-col" key={i}>
                        <Chart {...x} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="main-dashboard__bottom">
              <div className="container-fluid">
                <div className="row main-dashboard__bottom-row">
                  <p className="main-dashboard__note hidebelow992">
                    {this.state && this.state.updateDate && (
                      <span style={{ marginLeft: "22px" }}>
                        {this.label("dashboard_date")}
                        <time
                          dateTime={this.state.updateDate}
                          className="number"
                        >
                          {this.state.updateDate}
                        </time>
                      </span>
                    )}
                    <span>{this.label("dashboard_disc")}</span>
                  </p>

                  <div className="col-auto main-dashboard__btn-col">
                    <NavLink
                      to="/customers/home/balance"
                      className="main-dashboard__btn"
                    >
                      {this.label("dashboard_button")}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            {

           ( this.checkKindBunner[0]?.EndOfYearInvestmentGemel == 1 && this.checkKindBunner[0]?.EndOfYearSelfEmployed != 1 ) || 
          ( this.checkKindBunner[0]?.EndOfYearInvestmentGemel == 1 && this.checkKindBunner[0]?.EndOfYearSelfEmployed == 1  && (this.state.showGemel)as unknown as boolean) ?
              <>

              
             
                <div className="desktop-banner-view">
                  <div className="image-container">
                    <CmsBannersInner banners={this.cmsContent && this.group_3} />
                    <div className="number-overlay">
                      בשנת 2024 ניתן להפקיד עד 79,005 ₪ בקופת גמל להשקעה,
                    <br/>
                    מה שמאפשר לך להפקיד 
                    עוד {this.checkKindBunner[0]?.AvailableDepositAmount} ₪ עד סוף השנה.
                    </div>
                    <button className="more-deatils-btn" onClick={()=>{
                      window.location.href='https://www.yl-invest.co.il/%D7%94%D7%A4%D7%A7%D7%93%D7%94-%D7%9C%D7%A7%D7%95%D7%A4%D7%AA-%D7%92%D7%9E%D7%9C-%D7%9C%D7%94%D7%A9%D7%A7%D7%A2%D7%94'
                    }}>
                    <img src="/img/more-details.png" className="more-deatils-img"/>
                    </button>                       
                    </div>
                </div>

                <div className="mobile-banner-view">
                  <div className="image-container">
                    <CmsBannersInner banners={this.cmsContent && this.group_5} />
                    <div className="number-overlay">
                    בשנת 2024 ניתן 
                    <br/>
                    להפקיד עד 79,005 ₪ 
                    <br/>
                    בקופת גמל להשקעה,
                    <br/>
                    מה שמאפשר לך להפקיד 
                    <br/>
                    עוד {this.checkKindBunner[0]?.AvailableDepositAmount} ₪
                    <br/>
                    עד סוף השנה.
                    </div>
                    <button onClick={()=>{
                      window.location.href='https://www.yl-invest.co.il/%D7%94%D7%A4%D7%A7%D7%93%D7%94-%D7%9C%D7%A7%D7%95%D7%A4%D7%AA-%D7%92%D7%9E%D7%9C-%D7%9C%D7%94%D7%A9%D7%A7%D7%A2%D7%94'
                    }} className="more-deatils-btn">
                    <img src="/img/more-details.png" className="more-deatils-img"/>
                    </button>
                  </div>
                </div>
              </>
            
            : (this.checkKindBunner[0]?.EndOfYearSelfEmployed == 1 &&this.checkKindBunner[0]?.EndOfYearInvestmentGemel != 1 ) ||
            ( this.checkKindBunner[0]?.EndOfYearInvestmentGemel == 1 && this.checkKindBunner[0]?.EndOfYearSelfEmployed == 1  && !(this.state.showGemel as unknown as boolean) ) ? 

              <>
        
              <div className="desktop-banner-view">
    
              <CmsBannersInner banners={this.cmsContent && this.group_2} />
              </div>
              <div className="mobile-banner-view">
    
              <CmsBannersInner banners={this.cmsContent && this.group_4} />
              </div>
              </>
              : <CmsBanners banners={this.cmsContent && this.group_1} />
        }
          </div>
        </section>


    

        <style>
          {" "}
          {
            "@media (max-width:991px) { .desktop-banner-view{display:none !important} }"
          }{" "}
        </style>
        <style>
          {" "}
          {
            "@media (min-width:991px) { .mobile-banner-view{display:none !important} }"
          }{" "}
        </style>
        <style>
          {" "}
          {
            ".account-table-block__table-notice {display: block !important;}"
          }{" "}
        </style>
        <style>
          {" "}
          {
            '.discFont{ font-size:14px; font-family:"Almoni DL AAA", sans-serif; white-space:pre-wrap }'
          }{" "}
        </style>

        <style>
          {" "}
          {
            "@media (max-width:991px) { .dispbelow992{display:block !important; margin-top:30px; max-width:100%;font-size:10px} time{font-size:10px !important} }"
          }{" "}
        </style>
        <style>
          {" "}
          {
            "@media (min-width:991px) { .dispbelow992{display:none !important} .updatetxt{ margin-left:36px} } .account-table-block__table-notice{margin-top:0px} "
          }{" "}
        </style>
        {this.label("dashboard_bottomdisc") ? (
          <pre
            style={{ fontSize: "14px" }}
            className={
              "account-table-block__table-notice content-wrapper discFont"
            }
          >
            {this.label("dashboard_bottomdisc").replace(
              "{date}",
              sessionStorage.getItem("updatedate")
            )}
          </pre>
        ) : null}
      </>
    );
  }
}
