/* eslint-disable */
import React from "react";
import { Route, Switch, Redirect } from "react-router";
import * as ReactDOM from "react-dom";
import * as H from "history";
import { Header } from "./Header";
import { Footer } from "./Footer";
import {
  BaseComponent,
  Dashboard,
  Balance,
  AccountStatus,
  RequestsStatus,
  ContactUs,
  PersonalDetails,
  Reports,
  UpdatePersonalDetails,
  RecentActions,
  TaxReports,
  ScanLetter,
  CustomerLetter

} from "../";
import { UserDataService, CmsService } from "../../services";
import { UserDataApi } from "../../api";
import { Subscription, of } from "rxjs";
import PopupBackground, { PopupService } from "../Macros/Popup";
import SessionTimeout from "./SessionTimeout";
import FormWithValidation from "../Macros/FormWithValidation";
import config from "../../config";

const PrivteRoute = (props: any) => {

  return props.role !== 5 ? (
    <Route {...props} />
  ) : (
    <Redirect to={props.homeRoute} />
  );
};

export interface RouteComponentProps {
  match: match;
  location: H.Location;
  history: H.History;
  staticContext?: any;
}

export interface match {
  params: any;
  isExact: boolean;
  path: string;
  url: string;
}

export default class AfterLoginLayout extends BaseComponent<
  RouteComponentProps,
  {
    isLoading: boolean;
    redirectTo: string;
    isUserMailingPopupNeeded: boolean;
    emailAddress: string;
    homeRoute: string;
  }
> {
  renewTimer: NodeJS.Timeout = null;
  userListener: Subscription = null;
  currentServices = 0;

  constructor(props: Readonly<RouteComponentProps>) {
    super(props);

    this.state = {
      isLoading: true,
      redirectTo: !UserDataService.isLoggedIn ? "/" : null,
      isUserMailingPopupNeeded: false,
      emailAddress: "",
      homeRoute: "/customers/home/dashboard",
    };
  }


  // mergeCourseMsg =  this.label("merge_tracks_1");
  mesagetype = parseInt(sessionStorage.getItem('margeMessageType'))
  displayPopup = sessionStorage.getItem("toDisplayCourseMargePopUp");
  mergePopupShow = parseInt(sessionStorage.getItem("isMergePpopupShow"));
  showLetterPopup = sessionStorage.getItem('showLetterPopup');
  showBirthdayPopup = sessionStorage.getItem('showBirthdayPopup')
  isShowPopup = parseInt(sessionStorage.getItem('isShowPopup'))
  checkKindBunner = JSON.parse(sessionStorage.getItem("zoomAnaliticsData"))



  componentDidMount() {

    
    //checking if there is return url
    if (UserDataService.isLoggedIn) {
      const url = sessionStorage.getItem("returnUrl");
      if (url && UserDataService.isLoggedIn) {
        if (url.includes("forms")) window.location.href = config.API_URL + url;
        else {
          this.props.history.push(url);
          sessionStorage.removeItem("returnUrl");
        }
      }
      //get user data
      this.currentServices++;
      UserDataService.updateData().finally(this.onServiceLoaded.bind(this));

      //get cms data
      this.currentServices++;
      CmsService.updateContent().finally(this.onServiceLoaded.bind(this));
    }

    //listen for logout
    this.userListener = UserDataService.listen((x) => {
      this.setState({
        redirectTo: !UserDataService.isLoggedIn ? "/" : null,
      });
    });

    //renew token before expiration
    this.renewTimer = setInterval((x) => {
      UserDataService.renew();
    }, 2 * 60 * 1000);
  }

  onServiceLoaded() {
    this.currentServices--;
    if (this.currentServices <= 0) {
      if (!CmsService.content) {
        this.onLogout();
        UserDataService.logout();
      } else {
        if (UserDataService.data) {
          this.setState({
            isLoading: false,
            isUserMailingPopupNeeded: UserDataService.isUserMailingPopupNeeded,
            homeRoute: this.routeByRole(UserDataService.data.userRole || 1),
          });
          this.onLogin();
        } else {
          this.setState({
            redirectTo: "/",
          });
          this.onLogout();
        }
      }
    }
  }

  routeByRole(role: number) {
    switch (role) {
      case 2:
      case 4:
        return "/customers/home/balance";
      case 3:
        return "/customers/home/recent-actions";
      case 5:
        return "/customers/home/reports";
      default:
        return "/customers/home/dashboard";
    }
  }

  rules = [
    {
      input: "emailAddress",
      validators: ["email"],
    },
  ];
  handleInput = (event: { target: { value: any } }) => {
    const { value } = event.target;
    this.setState({ emailAddress: value });
  };

  onSendEmail = () => {
    const elInput = document.querySelector(".popup-form") as HTMLElement;
    const elLoading = document.querySelector(".send-loading") as HTMLElement;
    const elConfirmErr = document.querySelector(".confirm-err") as HTMLElement;
    const elConfirmSucc = document.querySelector(
      ".confirm-succ"
    ) as HTMLElement;
    const elConfirmBtn = document.querySelector(".confirm_btn") as HTMLElement;

    elInput.style.display = "none";
    elLoading.style.display = "block";

    UserDataApi.confirmPopUp({
      isConfirm: true,
      emailAddress: this.state.emailAddress,
    })
      .then(() => {
        sessionStorage.setItem(
          "isUserMailingPopupNeeded",
          JSON.stringify(false)
        );
        elLoading.style.display = "none";
        elConfirmSucc.style.display = "block";
        elConfirmBtn.innerText = "אישור";
      })
      .catch(() => {
        elConfirmBtn.innerText = "סגור";
        elLoading.style.display = "none";
        elConfirmErr.style.display = "block";
        sessionStorage.setItem(
          "isUserMailingPopupNeeded",
          JSON.stringify(false)
        );
      });
  };

  onShowMegerCourse() {

    this.mesagetype != 0 &&
      PopupService.show(
        `<div style='text-align: center'>  </div>`,
        `${this.mesagetype == 1 ?
          this.label("merge_tracks_1") :
          this.mesagetype == 2 ?
            this.label("merge_tracks_2") :
            this.label("merge_tracks_3")


        }`,
        [
          {
            caption: " קראתי והבנתי ",
            onClick: () => {
             


              UserDataApi.confirmMergePopup({ isView: true }).then(() => {
                console.log("ok");
              });
              PopupService.hide();
            },
          },
          {
            caption: " למידע נוסף",
            onClick: () => {
              window.open("https://www.yl-invest.co.il/227", "_blank");


              UserDataApi.confirmMergePopup({ isView: true }).then(() => {
                console.log("no");
              });
              PopupService.hide();

            },
          },
        ]
        , true
        , true
      );


  }

  onShowMailPopup() {
    const closeEl = ((
      document.querySelector(".close-icon") as HTMLElement
    ).style.display = "block");
    PopupService.show(
      this.label("mailing_popup_title"),
      `<div style='line-height: 0.8;text-align: justify;'>
        <div style='font-size:20px;margin-bottom:10px'>
        ${this.label("mailing_popup_subtitle")}
        </div>
        <span style='font-size:13px'>
        ${this.label("mailing_popup_body")
        .replace(
          "{link}",
          `<a href="${this.label(
            "mailing_link"
          )}" target="_blank">${this.label("mailing_link_name")}</a>`
        )
        .replace(
          "{email}",
          `<a href="mailto:${this.label(
            "mailing_tomail_address"
          )}">${this.label("mailing_tomail_address")}</a>`
        )}
        </span>
        </div>`,
      [
        {
          caption: this.label("mailing_popup_confirm_btn"),
          onClick: () => {
            PopupService.show(
              this.label("mail_authentication"),
              `<div id="mail-popup">
            </div>`,
              [
                {
                  caption: this.label("mailing_popup_confirm_btn"),
                  onClick: () => {
                    const elConfirmBtn = document.querySelector(
                      ".confirm_btn"
                    ) as HTMLElement;
                    if (
                      elConfirmBtn.innerText ===
                      this.label("mailing_popup_confirm_btn")
                    ) {
                      const elBtnForm = document.querySelector(
                        ".submit-mail"
                      ) as HTMLElement;
                      elBtnForm.click();
                    } else {
                      PopupService.hide();
                    }
                  },
                },
              ],
              false
            );
            const inputMailEl = document.getElementById("mail-popup");
            ReactDOM.render(
              <>
                <div
                  className="send-loading"
                  style={{
                    textAlign: "center",
                    paddingTop: "2em",
                    display: "none",
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src="/img/loading.gif"
                    alt="loading"
                  />
                </div>
                <span
                  className="confirm-err"
                  style={{
                    color: "red",
                    marginBottom: "36px",
                    display: "none",
                  }}
                >
                  {this.label("popup_confirm_err")}
                </span>
                <span
                  className="confirm-succ"
                  style={{
                    color: "green",
                    marginBottom: "36px",
                    display: "none",
                  }}
                >
                  בקשתך נקלטה בהצלחה
                </span>
                <FormWithValidation
                  rules={this.rules}
                  className="popup-form"
                  onSubmit={this.onSendEmail}
                >
                  <span>
                    {this.label("you_must_enter")} {this.label("email_address")}
                    :
                  </span>
                  <div
                    style={{ marginTop: "10px" }}
                    className="cp-form__form-group"
                  >
                    <input
                      name="emailAddress"
                      type="email"
                      onChange={this.handleInput}
                      placeholder={this.label("email_address")}
                      required
                      className="cp-form__input"
                    />
                    <button
                      style={{ opacity: 0, position: "absolute" }}
                      className="submit-mail"
                    ></button>
                  </div>
                </FormWithValidation>
              </>,
              inputMailEl
            );
          },
        },
        {
          caption: this.label("mailing_popup_cancel_btn"),
          onClick: () => {
            UserDataApi.confirmPopUp({ isConfirm: false }).then(() => {
              sessionStorage.setItem(
                "isUserMailingPopupNeeded",
                JSON.stringify(false)
              );
            });
          },
        },
      ]
    );
  }


  onShowLetterPopup() {

    PopupService.show(
      `<div ></div>`,
      ` <div className="image-size" ></div>`,
      [
        {
          caption:   ` <img src="/img/new-yes-button.jpg" style='width:160px'/>`,
          onClick: () => {
            UserDataApi.confirmLetterPopup({ isApproved: true }).then(() => {
            });
            PopupService.hide();
          },},
        {
          caption:` <img src="/img/new-no-button.jpg"  style='width:160px ; border:none'/>`,
          onClick: () => {

            UserDataApi.confirmLetterPopup({ isApproved: false }).then(() => {
            });
            PopupService.hide();
          },
        },
      ],
      false, false, true,false
    );


  }

  onShowBirthdayPopup(){
   
   PopupService.show(
    `<div ></div>`,
    ` <div className="image-size" ></div>`,
    [
      {
        caption: ` <div></div>`,
     },
     {
      caption: ` <div></div>`,
   },

    ],
    false,false, false,true
  );
  UserDataApi.confirmBirthdayPopup().then(() => {
  });

  }

  sessionTimeout?: SessionTimeout;
  onLogin() {
    this.sessionTimeout = new SessionTimeout();
    this.sessionTimeout.OnWarning = () => {
      const closeEl = ((
        document.querySelector(".close-icon") as HTMLElement
      ).style.display = "none");
      PopupService.alert(
        "התנתקות",
        "לא זיהינו פעילות באתר לכן יבוצע ניתוק בעוד 60 שניות, האם להישאר מחובר?",
        () => {
          this.sessionTimeout.CancelWarning();
        }
      );
    };
    this.sessionTimeout.OnLogout = () => {
      UserDataService.logout();
    };
    this.sessionTimeout.Start();
    //checking if the user return from forms web
    const isReturnForms =
      sessionStorage.getItem("formsReturn") &&
      JSON.parse(sessionStorage.getItem("formsReturn"));
    sessionStorage.removeItem("formsReturn");
    if (
      sessionStorage.getItem("imp") !== "1" &&
      this.props.location.pathname === "/customers/home/dashboard" &&
      !isReturnForms
    ) {
      if (this.state.isUserMailingPopupNeeded) {
        this.onShowMailPopup();
      } else {
        if (JSON.parse(sessionStorage.getItem("showPopup"))) {
          PopupService.alert(
            this.label("commonpopuptitle"),
            `<div style='line-height: 0.9;text-align:justify'>
        <div style='font-size:20px;margin-bottom:10px'>
        ${this.label("commonpopupbody")}
        </div>
        <span style='font-size:16px'>
        ${this.label("commonpopupdisc")}
        </span>
       </div>`,
            () => { },
            this.label("commonpopupbtn")
          );
        }
        if (JSON.parse(sessionStorage.getItem("showGemelPopup"))) {
          PopupService.alert(
            this.label("Gemel_popup_title"),
            `<div style='line-height: 0.9;text-align:justify'>
        <div style='font-size:20px;margin-bottom:10px'>
        ${this.label("Gemel_popup_subtitle")
              .replace(
                "{link}",
                `<a style='text-decoration: underline;font-weight: 600;' href="${this.label(
                  "Gemel_popup_link"
                )}" target="_blank">${this.label("Gemel_popup_link_name")}</a>`
              )
              .replace("n/", `<br/>`)}

        </div>
       </div>`,
            () => { },
            this.label("gemelpopupbtn")
          );
        }
      }
    }



    if ((this.siteSetting("isshowmergepopup") == "true") && this.displayPopup == 'true' && (this.showLetterPopup == "1")) {
      
      if (this.mergePopupShow == 0) {
        this.onShowMegerCourse()
        sessionStorage.setItem("isMergePpopupShow", "1")
      } else {
               
        if(this.checkKindBunner[0]?.DisplayBirthDatepopUp == 1){
          this.onShowBirthdayPopup()
          sessionStorage.setItem('showBirthdayPopup', '1')

        }
        if ( this.showLetterPopup == "1" &&  ((this.showBirthdayPopup == '1' && this.checkKindBunner[0]?.DisplayBirthDatepopUp == 1 ) ||  this.checkKindBunner[0]?.DisplayBirthDatepopUp != 1) &&  this.isShowPopup != 1) {
          this.onShowLetterPopup()
          sessionStorage.setItem('isShowPopup', '1')
        }
      }

    }

    else {
      

      if (this.siteSetting("isshowmergepopup") == "true") {
        if (this.displayPopup == 'true') {
          if (this.mergePopupShow == 0) {
            this.onShowMegerCourse()
            sessionStorage.setItem("isMergePpopupShow", "1")
          }
        }
      }

    

      if(this.checkKindBunner[0]?.DisplayBirthDatepopUp == 1 && this.showBirthdayPopup != '1'){
        this.onShowBirthdayPopup()
        sessionStorage.setItem('showBirthdayPopup', '1')
      }

      else if (this.showLetterPopup == "1") {
        if (this.isShowPopup == 0) {
          this.onShowLetterPopup()
          sessionStorage.setItem('isShowPopup', '1')
        }
      }
    }

  


    // {
    //   console.log("zoomAnaliticsData",this.checkKindBunner[0]
    //   )
      
    // }


  }

  onLogout() {
    if (this.sessionTimeout) {
      this.sessionTimeout.Stop();
      this.sessionTimeout = null;
    }
  }

  componentWillUnmount() {
    UserDataService.stopListen(this.userListener);
    if (this.renewTimer) {
      clearTimeout(this.renewTimer);
    }

    if (this.sessionTimeout) {
      this.sessionTimeout.Stop();
    }
  }

  render() {


    const { isLoading } = this.state;
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }



    return isLoading ? (
      <div style={{ textAlign: "center", paddingTop: "2em" }}>
        <img src="/img/loading.gif" alt="loading" />
      </div>
    ) : (
      <div className="site-wrapper">
        <PopupBackground />
        <div className="main-wrapper">
          <main
            className={
              this.props.location.pathname === "/customers/home/dashboard"
                ? "home-main"
                : "contact-main"
            }
          >
            <Header />
            <Switch>
              <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                exact
                path="/customers/home/dashboard"
                component={Dashboard}
              />
              <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                exact
                path="/customers/home/balance"
                component={Balance}
              />
              <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                path="/customers/home/balance/accountStatus/:id"
                component={AccountStatus}
              />
              <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                exact
                path="/customers/home/requests"
                component={RequestsStatus}
              />
              <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                exact
                path="/customers/home/recent-actions"
                component={RecentActions}
              />
              <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                exact
                path="/customers/home/contact"
                component={ContactUs}
              />
              {/* <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                exact
                path="customers/home/personalDetails"
                component={PersonalDetails}
              /> */}
              <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                exact
                path="/customers/home/tax-reports"
                component={TaxReports}
              />
              <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                exact
                path="/customers/home/personalDetails/update"
                component={UpdatePersonalDetails}
              />

              <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                exact
                path="/customers/home/scan"
                component={ScanLetter}
              />

              <PrivteRoute
                role={UserDataService.data.userRole || 1}
                homeRoute={this.state.homeRoute}
                exact
                path="/customers/home/customerLetter"
                component={CustomerLetter}
              />

              <Route exact path="/customers/home/reports" component={Reports} />
              <Route
                path="/customers/home"
                render={() => <Redirect to={this.state.homeRoute} />}
              />

            </Switch>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}
