
import React from "react";
import BasicTable from '../CustomTable'
import BaseComponent from "../BaseComponent";
import { CustomerLetterApi, ILetters , IShowLetter } from '../../api/CustomerLetter';
import { Tab, Nav } from 'react-bootstrap';
import { PopupService } from "../Macros/Popup";

interface IRequestsState {
  isLoading: boolean,
  letters: ILetters[]
  filterData: IShowLetter[],
  noReadnumber: number,
  allLetterNumber: number
  showLetter: IShowLetter[],
  totalCount:number,
  totalUnViewedCount?:number
  selectedTab:string
}

const headerColumns = [
  {
    header: 'מכתב'
  },
  {
    header: 'תאריך'
  }, {
    header: 'צפיה והורדה'
  }, {
    header: 'הסרה'
  }
];


export default class CustomerLetter extends BaseComponent<{}, IRequestsState> {

  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      letters: null,
      isLoading: false,
      filterData: null,
      showLetter: null,
      totalCount:0,
      totalUnViewedCount:0,
      noReadnumber: parseInt(sessionStorage.getItem('sumUnViewed')) || 0,
      allLetterNumber: parseInt(sessionStorage.getItem('sumLetter')) ||0,
      selectedTab: 'noRead'

    }
  }

  componentDidMount() {


    
    CustomerLetterApi.getUnReadNumber().then((res) => {
      sessionStorage.setItem("sumUnViewed", res.sumUnViewed)
      sessionStorage.setItem("sumLetter", res.sumLetter)
      if(res){
        if(parseInt(sessionStorage.getItem('sumUnViewed')) == res.sumUnViewed &&  parseInt(sessionStorage.getItem('sumLetter'))== res.sumLetter ){
          
          sessionStorage.setItem('noReadBold',null)
        }
        this.fetchLetterData();
      }
    })
  
    
  }

  componentDidUpdate(prevProps :any, prevState :IRequestsState){
    if(prevState.selectedTab && this.state.selectedTab && prevState.selectedTab != this.state.selectedTab){
     this.fetchLetterData()
    }
  }


  handleDelete = (data: any) => {

    PopupService.show(
      'מכתבים שיוסרו לא יוצגו בדואר שלי, האם הינך רוצה להסיר את המכתבים? ',
      `<div id="mail-popup">
      </div>`,
      [
        {
          caption: this.label("mailing_popup_confirm_btn"),
          onClick: () => {

            this.deleteLetter({ id: data.id, kind: data.kind });
            PopupService.hide();

          },
        },
        {
          caption: "לא מאשר",
          onClick: () => {

            PopupService.hide();

          },
        },
      ],
      false
    );

  }




  deleteLetter = (data: any) => {
    const selectedItems = JSON.parse(sessionStorage.getItem("noReadBold")) ||[]

    const checkIfIdInList= selectedItems.some((item:any) => item === data.id)


    CustomerLetterApi.delete(data.id).then((res) => {
      if (res === true) {
        this.setState(prevState => ({
          allLetterNumber: prevState.allLetterNumber - 1,
          showLetter:prevState.showLetter.filter((item)=> item.id != data.id)
        }))
        if (checkIfIdInList==false  ) {
          this.setState(prevState => ({
            noReadnumber: prevState.noReadnumber - 1
          }))

        }

        CustomerLetterApi.getUnReadNumber().then((res) => {
          sessionStorage.setItem("sumUnViewed", res.sumUnViewed)
          sessionStorage.setItem("sumLetter", res.sumLetter)
        })
      }
    })

      .catch((err) =>
        console.log(err)
      );
  }

  handleSelect = (eventKey:string) => {
    
    this.setState({ selectedTab: eventKey }); // Update state when a tab is selected
  };

  fetchLetterData = (page?: number) => {

    const choosenTab = this.state.selectedTab == "noRead" ? false : null ;
    this.setState({
      isLoading: true,
    });
    CustomerLetterApi.get({
      pageNo: page || 1,
      pageSize: 20,
      viewd : choosenTab
    })
      .then((res) => {
        if(res=== null){
          this.setState({
            isLoading:false,
            letters:[],
            showLetter:[]
          })
        }else{

          this.setState(prevState =>({
            isLoading: false,
            letters: res.letters,
            totalCount: choosenTab==false ? prevState.totalCount : res.totalCount,
            allLetterNumber:parseInt(sessionStorage.getItem('sumLetter')),
            noReadnumber:parseInt(sessionStorage.getItem('sumUnViewed')),
            totalUnViewedCount:parseInt(sessionStorage.getItem('sumUnViewed')),
            showLetter :res.letters.map(item => ({
              displayName: item.displayName,
              letterCreatedDate: item.letterCreatedDate ,
              id:item.id,
              isViewed: item.isViewed
          })),
        

           
          }))


     

        }

      })
      .catch(() =>{
        PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
        this.setState({
          isLoading: false,
          letters: null,
        })
      });
  }

  deleteAllLetters = () => {
    CustomerLetterApi.deleteAll().then((res) => {
      if (res === true) {
      //  this.fetchLetterData()
      CustomerLetterApi.getUnReadNumber().then((res) => {
        sessionStorage.setItem("sumUnViewed", res.sumUnViewed)
        sessionStorage.setItem("sumLetter", res.sumLetter)

        this.setState(prevState => ({
          allLetterNumber: res.sumUnViewed
        }))
      })
      this.fetchLetterData()
        
         }
    })
      .catch((err) =>
        console.log(err)
      );
  }

  download(letterId: string , isViewed?:boolean) {
    const selectedItems = JSON.parse(sessionStorage.getItem("noReadBold")) ||[]

    const checkIfIdInList= selectedItems.some((item:any) => item === parseInt(letterId))
  
    CustomerLetterApi.Download(letterId).then((res) => {
      if (res === null) {
        PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
        return
      }
      if (window.navigator.msSaveOrOpenBlob) {
        const blobObject = new Blob([res], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blobObject, 'report.pdf');
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        let link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
       // link.download = 'report.pdf';
        link.click();

      }
      CustomerLetterApi.getUnReadNumber().then((res) => {
        sessionStorage.setItem("sumUnViewed", res.sumUnViewed)
        sessionStorage.setItem("sumLetter", res.sumLetter)
      })
      
      if(checkIfIdInList==false){
        this.setState(prevState => ({
          noReadnumber: prevState.noReadnumber - 1,
        }));
      }
    //  if(this.state.selectedTab =='all'){
    //   this.fetchLetterData()

    //  }

    }).catch((err) => {
      PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
    }
    );
  }

  render() {

    return (
      <div className="customer-letter">
        {
          this.state.letters ?
            <>
               <div className="tax-reports-intro">
          <div className="content-wrapper">
            <h1 className="tax-reports-intro__heading">
            הדואר שלי
            </h1>
          </div>
        </div>

              <div className="content-wrapper content-wrapper--sheet">
                <div className="content-sheet">
                <div className="reports-data">
              <div className="pane-content pane-content--reports">

                  <Tab.Container defaultActiveKey="noRead" activeKey={this.state.selectedTab} onSelect={this.handleSelect}>
                    <div className="content-wrapper content-wrapper--sheet">

                      <div className="content-sheet">
                        <div className="tabs-container">
                          <Nav variant={null} className="nav nav-tabs">
                            <Nav.Item className="nav-item">
                              <Nav.Link eventKey="all" className={`nav-link ${this.state.selectedTab =='all' ? 'active-tab' : ''}  `}>
                                {` כל הדואר ( ${this.state.allLetterNumber} )`}
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="nav-item">
                              <Nav.Link eventKey="noRead" className={`nav-link ${this.state.selectedTab =='noRead' ? 'active-tab' : ''}  `}>
                                {`  לא נקרא ( ${this.state.noReadnumber} )`}
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </div>

                      {
                        ( this.state.selectedTab =="all") &&
                        <div className="col-auto data-filter__buttons-col remove-all-letter">
                        <button
                          type="button"
                          className="data-filter__btn"
                          onClick={() => {
                            PopupService.show(
                           `${this.state.allLetterNumber - this.state.noReadnumber} 
                           מכתבים שיוסרו לא יוצגו בדואר שלי, האם הינך רוצה להסיר את המכתבים?

                           `,
                              `<div id="mail-popup">
                               </div>`,
                              [
                                {
                                  caption: this.label("mailing_popup_confirm_btn"),
                                  onClick: () => {

                                    this.deleteAllLetters();
                                    PopupService.hide();

                                  },
                                },
                                {
                                  caption: "לא מאשר",
                                  onClick: () => {

                                    PopupService.hide();

                                  },
                                },
                              ],
                              false
                            );
                          }}
                        >
                          הסר דואר נקרא
                        </button>
                      </div>
                      }
                   
                    </div>

                    <Tab.Content className="tab-content">
                      <Tab.Pane eventKey={'all'}>
                        <BasicTable
                          headerColumns={headerColumns}
                          data={this.state.showLetter}
                          downloadPdf={(id , isViewed) =>
                            this.download(
                              id.toString(), 
                              isViewed
                            )
                          }
                          onDelete={this.handleDelete}
                          paging={this.fetchLetterData}
                          totalCount ={ Math.ceil(this.state.totalCount / 20)}

                        />

                      </Tab.Pane>

                      <Tab.Pane eventKey="noRead">
                        <BasicTable
                          headerColumns={headerColumns}
                          data={this.state.showLetter}
                          downloadPdf={(id , isViewed) =>
                            this.download(
                              id.toString(),
                              isViewed
                            )
                          }
                          onDelete={this.handleDelete}
                          paging={this.fetchLetterData}
                          totalCount ={Math.ceil(this.state.totalUnViewedCount/20)}


                        />
                      </Tab.Pane>

                    </Tab.Content>

                  </Tab.Container>
                </div>
              </div>
              </div>
              </div>
            </>


            :
            <div style={{ textAlign: "center", paddingTop: "2em" }}>
            <img src="/img/loading.gif" alt="loading" />
          </div>


        }


      </div>
    );
  }
}
