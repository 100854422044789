import React from 'react';
import BaseComponent from '../BaseComponent';

interface IProps {
  title: string;
  disc?: string;
  class?: string;
  isReplace?: boolean;
  replaceObj?: { name: string; value: any };
}
export default class FormTitle extends BaseComponent<IProps, {}> {
  constructor(props: Readonly<IProps>) {
    super(props);
  }
  render() {
    return (
      <div className="personal-update__block-header">
        <h2 className="personal-update__heading">{this.label(this.props.title)}</h2>
        <span className={'personal-update__sub-heading ' + this.props.class}>
          {this.props.isReplace
            ? this.label(this.props.disc).replace(this.props.replaceObj.name, '')
            : this.label(this.props.disc)}
          {this.props.isReplace && this.props.replaceObj && this.props.replaceObj.value}
        </span>
      </div>
    );
  }
}
