import React from 'react';
import { BaseComponent } from '..';
import { TopDucks, CmsBanners } from '../Macros';
import { UserDataService } from '../../services';
import { IGetActions, IAction } from '../../api/RecentActions';
import CustomSelect, { ICustomSelectItem } from '../Macros/CustomSelect';
import ResponsiveTable, { IResponsiveTable, IResponsiveTableRow } from '../Macros/ResponsiveTable';
import { RecentActionsApi } from '../../api';
import { Pagination } from 'react-bootstrap';
import moment from 'moment'


interface IState {

    currentPage: number,

    selectedYear: string,
    years: ICustomSelectItem[],

    selectedType: string,
    types: ICustomSelectItem[],

    selectedAccount: string,
    accounts: ICustomSelectItem[],

    selectedTime: string,
    times: ICustomSelectItem[]

    actions?: any[],
    isLoading: boolean,

    totalPages: number

}

declare const $: any;

export default class RecentActions extends BaseComponent<{}, IState> {

    cmsPageAlias = 'recentactions';

    constructor(props: Readonly<{}>) {

        super(props);

        const accounts = UserDataService.data.accounts.map(x => {
            return {
                label: x.id.toString(),
                value: x.id.toString()
            };
        });
        accounts.splice(0, 0, { label: "כל החשבונות", value: '' });

        this.state = {
            totalPages: 0,
            selectedTime: '0',
            times: [this.label('last_30_days'), this.label('three_last_months'), this.label('last_six_months'), this.label('last_year')]
                .map((label, index) => ({ label, value: index.toString() } as ICustomSelectItem)),
            currentPage: 1,
            selectedYear: new Date().getFullYear().toString(),
            selectedType: '0',
            selectedAccount: '',

            years: [new Date().getFullYear(), new Date().getFullYear() - 1, new Date().getFullYear() - 2]
                .map(x => ({ label: x.toString(), value: x.toString() } as ICustomSelectItem)),

            types: ['כל הסוגים', 'הפקדה', 'משיכה', 'העברה נכנסת לחשבון', 'העברה יוצאת מהחשבון', '', '', 'העברה בין מסלולים', 'העברה מחשבון אחר']
                .map((label, index) => ({ label, value: index.toString() } as ICustomSelectItem)).filter((item) => item.label),

            accounts,
            isLoading: false
        };
    };

    componentDidMount() {
        this.fetchData();
    }

    getPeriod = (value: any) => {

        switch (value) {
            case '1':
                return moment().subtract(3, 'months').calendar();
            case '2':
                return moment().subtract(6, 'months').calendar();
            case '3':
                return moment().subtract(1, 'year').calendar();
            default:
                return moment().subtract(30, 'days').calendar();

        }
    }

    getPages() {
        const result: number[] = [];
        if (this.state.actions) {
            for (let i = 1; i <= this.state.totalPages; i++) {
                result.push(i);
            }
        }
        return result;
    }

    setPage(page: number) {
        if (page !== this.state.currentPage) {
            this.setState({
                currentPage: page
            })
            this.fetchData(page);
        }
    }


    resetForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        this.setState({
            selectedType: '0',
            selectedAccount: '',
            selectedTime: '0'
        });
        event.preventDefault();
    }

    submitForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        this.setState({ currentPage: 1 })
        this.fetchData(1);
        event.preventDefault();
    }
    fetchData(page?: number) {
        const data = {
            pageNo: page || this.state.currentPage,
            fromDate: moment(this.getPeriod(this.state.selectedTime)).format('DD-MM-YYYY'),
            actionType: this.state.selectedType ? parseInt(this.state.selectedType) : 0,
            pageSize: 20,
            accountId: this.state.selectedAccount

        } as IGetActions;

        if (this.state.selectedAccount !== '') {
            data.accountId = this.state.selectedAccount;
        }
        else {
            data.accountId = null;
        }

        this.setState({
            isLoading: true,
            // currentPage: 1
        });

        RecentActionsApi.get(data).then((x: any) => {
            this.setState({
                isLoading: false,
                actions: x.data.transactions,
                totalPages: x.data.totalPages,

            })
        }
        ).catch((x) => {
            this.setState({
                actions: [],
                isLoading: false,
            })
        })
    }

    getComment = (action: any) => {
        const commentLabel = ['depositForMonthSalary', '', 'transferFromFundName', 'transferToFundName', '', '', 'transferFromCourse']
        if (action.type === 8 || action.type === 7) return action.comment || '';
        if (action.type === 2) return ''
        return `${(action.type === 1 && action.comment) ? '' : this.label(commentLabel[action.type - 1])}${action.type === 3 ?
            (action.comment || '') : ' ' + (action.comment || '')}`
    }

    getLabel = (typeAction: any) => {
        const type = this.state.types.find((type) => parseInt(type.value) == typeAction)
        if (type) return type.label
    }

    toTable() {

        return {
            headers: ['סוג פעולה', 'מספר חשבון', '** סכום הפעולה בש"ח', 'שם קופה ומסלול', 'תאריך ביצוע הפעולה', 'פירוט', ''],
            rows: this.state.actions.map((action) => ({
                cells: [{
                    value: this.getLabel(action.type)
                }, {
                    value: action.accountNumber,
                    valueType: 'number'
                },
                {
                    value: '₪ ' + action.totalAmount.toLocaleString(),
                    valueType: 'number'
                },
                {
                    value: action.fundName
                }, {
                    value: action.actionDate
                },
                {
                    value: this.getComment(action)
                }, {
                    value: '',
                    valueType: ''
                }
                ]
            } as IResponsiveTableRow))
        } as IResponsiveTable;




    }


    render() {
        return <>

            <TopDucks small={true} />

            <div className="action-intro">
                <div className="content-wrapper">
                    <div className="row action-intro__row">
                        <div className="col-lg action-intro__col-text">
                            <h1 className="action-intro__heading">{this.cmsContent && this.cmsContent.title}</h1>
                            <h2 className="contact-data__heading">{this.cmsContent && this.cmsContent.subtitle} </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wrapper content-wrapper--sheet">
                <div className="content-sheet">
                    <div className="action-data">
                        <div className="pane-content pane-content--requests">
                            <section className="data-section data-section--concentration">
                                <div className="filter-block-wrapper">
                                    <div className="filter-block">

                                        <h3 className="filter-block__heading">סנן לפי</h3>
                                        <button type="button" data-toggle="collapse" data-target="#collapseFilter" aria-expanded="true" aria-controls="collapseFilter" aria-label="toggle controls" className="filter-block__toggle">
                                            <span className="caption">סנן לפי</span>
                                        </button>
                                        <div id="collapseFilter" className="filter-block__collapse collapse show">

                                            <form id="transactions-filter-form" className="data-filter filter-block__form">
                                                <div className="data-filter__row">
                                                    <div className="data-filter__inputs">
                                                        <div className="data-filter__inputs-row">
                                                            <div className="data-filter__col">
                                                                <label htmlFor="df-accounts" className="sr-only">כל החשבונות</label>
                                                                <CustomSelect id="df-accounts" buttonClassName="data-filter__input data-filter__input--accounts dropdown-user-id"
                                                                    options={this.state.accounts}
                                                                    value={this.state.selectedAccount}
                                                                    onChange={(value) => { this.setState({ selectedAccount: value }) }}
                                                                />
                                                            </div>
                                                            <div className="data-filter__col">
                                                                <label htmlFor="df-type" className="sr-only">כל הסוגים</label>
                                                                <CustomSelect id="df-type" buttonClassName="data-filter__input data-filter__input"
                                                                    options={this.state.types}
                                                                    value={this.state.selectedType}
                                                                    onChange={(value) => { this.setState({ selectedType: value }) }}
                                                                />
                                                            </div>
                                                            <div className="data-filter__col">
                                                                <label htmlFor="df-time" className="sr-only">תקופה</label>
                                                                <CustomSelect id="df-time" buttonClassName="data-filter__input data-filter__input"
                                                                    options={this.state.times}
                                                                    value={this.state.selectedTime}
                                                                    onChange={(value) => { this.setState({ selectedTime: value }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-filter__buttons">
                                                        <div className="data-filter__buttons-row">
                                                            <div className="col-auto data-filter__buttons-col">
                                                                <button type="button" className="data-filter__btn data-filter__btn--reset" onClick={this.resetForm.bind(this)}>נקה סינון</button>
                                                            </div>
                                                            <div className="col-auto data-filter__buttons-col">
                                                                <button type="button" className="data-filter__btn" onClick={this.submitForm.bind(this)}>סינון</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {!this.state.isLoading && this.state.actions && !this.state.actions.length && <div className="empty-data">{this.label('no_data')}</div>}
                                <div className="actions-table-block">
                                    {this.state.isLoading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                                        </div>
                                        :

                                        this.state.actions && this.state.actions.length > 0 && <section className="data-section data-section--concentration" >
                                            <ResponsiveTable name="concentration" table={this.toTable()} />
                                        </section>


                                    }
                                </div>
                                {this.state && this.state.totalPages > 0 && !this.state.isLoading && <Pagination size="sm" className="d-flex justify-content-center flex-row-reverse p-4">
                                    {this.getPages().map(page => <Pagination.Item key={page}
                                        active={page === this.state.currentPage}
                                        onClick={() => this.setPage.call(this, page)}>
                                        {page}
                                    </Pagination.Item>)}
                                </Pagination>}
                            </section>
                        </div>
                    </div>
                </div>
            </div >

            <CmsBanners banners={this.cmsContent && this.cmsContent.banners} />
            <style> {'.account-table-block__table-notice {display: block !important;}'} </style>
            <style> {'.discFont{ font-size:14px; font-family:"Almoni DL AAA", sans-serif; white-space:pre-wrap }'} </style>

            <style> {'@media (max-width:991px) { .dispbelow992{display:block !important; margin-top:30px; max-width:100%;font-size:10px} time{font-size:10px !important} }'} </style>
            <style> {'@media (min-width:991px) { .dispbelow992{display:none !important} .updatetxt{ margin-left:36px} } .account-table-block__table-notice{margin-top:0px} '} </style>
            {this.label('recent_actions_disc') ? (
                <pre style={{ fontSize: '14px' }} className={'account-table-block__table-notice content-wrapper discFont'}>
                    {this.label('recent_actions_disc')}
                </pre>
            ) : null}
        </>;
    }
}
