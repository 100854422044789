import React from 'react';
import { NavLink } from 'react-router-dom';
import { ICmsBanner } from '../../api/Cms';

export default class CmsBanners extends React.Component<{ banners: ICmsBanner[] }, {}> {
  render() {
    return !this.props.banners ? null : (
      <section className="main-promo">
        <div className="content-wrapper">
          <h2 className="sr-only">promo banners</h2>
          <div className="row main-promo__row">
            {this.props.banners.map((x, i) => (
              <div className="col-lg-6 main-promo__col" key={i}>
                {!x.link ? (
                  <div className="main-promo__card">
                    <img src={x.image} alt={x.label} className="banner" />
                  </div>
                ) : x.link.toLowerCase().startsWith('http') ? (
                  <a href={x.link} target="_blank" rel="noopener noreferrer" className="main-promo__card">
                    <img src={x.image} alt={x.label} className="banner" />
                  </a>
                ) : (
                  <NavLink to={x.link} target="_blank" rel="noopener noreferrer" className="main-promo__card">
                    <img src={x.image} alt={x.label} className="banner" />
                  </NavLink>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}
