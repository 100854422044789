import { BaseSecuredApi } from "./BaseSecuredApi";

export interface IGetRequest {
    pageNo: number;
    pageSize: number;
    requestTypes: string[];
    fromDate?: string;
    toDate?: string;
    accountId: string;
}


export interface IRequest {
    data: [],
    files: any[],
    isLoading: boolean;
    isFileLoading: boolean;
    requestTypeID: number;
    requestType: string;
    requestTypeName: string;
    fundType: string;
    nameAccepts: string;
    accountNumberAccepts: string;
    accountNumberTransfer: string;
    dateAcceptRequest: string;
    expectedDate: string;
    statusRequest: string;
    transferName: string;
    faultDescription: string;
    rejectReasonId?: number;
    fileId: number;
    isOpen?: boolean;
    isFileListOpen?: boolean;
}


class RequestsStatus extends BaseSecuredApi {

    constructor() {
        super("UserData");
    }

    get(data: IGetRequest) {
        return this.POST<IRequest>({
            data,
            action: "Request/open/list",
        });
    }


    getFilesNames(folderId: number) {
        return this.GET<any>({
            action: `Request/files/list/${folderId}`,
        });
    }

    DownloadFile(data: {}) {
        return this.DownloadBlob<any>({
            action: 'Request/file/open',
            data
        });
    }

}

export const RequestsStatusApi = new RequestsStatus();