import React from "react";
import { BaseComponent } from "..";
import { Tab, Nav } from "react-bootstrap";
import LoginViaPassword from "./LoginViaPassword";
import LoginViaOtp from "./LoginViaOtp";
import { Redirect, SwitchProps } from "react-router";
import { CmsService } from "../../services";

import * as H from "history";

export interface RouteComponentProps {
  match: match;
  location: H.Location;
  history: H.History;
  staticContext?: any;
}

export interface match {
  params: any;
  isExact: boolean;
  path: string;
  url: string;
}

export default class LoginWrapper extends BaseComponent<
  RouteComponentProps,
  { redirectTo: string; resources: any }
> {
  cmsPageAlias = "resources";
  constructor(props: Readonly<RouteComponentProps>) {
    super(props);
    this.state = {
      redirectTo: null,
      resources: null,
    };
  }

  onLoginSuccess() {
    this.setState({
      redirectTo: "/",
    });
  }

  onLoginFailed(message: string) {
    alert(message);
  }

  componentDidMount() {
    CmsService.updateContent().finally(() => {
      //reset the popus params
      sessionStorage.setItem("showPopup", this.siteSetting("iscommonpopupon"));
      sessionStorage.setItem(
        "showGemelPopup",
        this.siteSetting("isgemelpopupon")
      );
      sessionStorage.setItem(
        "isMailingPopupOn",
        this.siteSetting("ismailingpopupon")
      );

      sessionStorage.setItem("SYBR", "11");
      sessionStorage.setItem("SYBS", "3");
      sessionStorage.setItem("SYBT", "3");
      if (this.cmsContent && this.cmsContent.resourceslist) {
        this.setState({
          resources: this.cmsContent.resourceslist,
        });
        $("#otpTerms").attr("href", this.getResource("termsOfUseUrl"));
        $("#pwdTerms").attr("href", this.getResource("termsOfUseUrl"));
        var yb = this.getResource("SelectYearsBackReports");

        if (yb != null) {
          sessionStorage.setItem("SYBR", yb);
        }

        yb = this.getResource("SelectYearsBackStatus");
        if (yb != null) {
          sessionStorage.setItem("SYBS", yb);
        }

        yb = this.getResource("SelectYearsBackTransactions");
        if (yb != null) {
          sessionStorage.setItem("SYBT", yb);
        }
      }
    });
  }

  getResource(txt: string) {
    var i = 0;
    for (i = 0; i < this.state.resources.length; i++) {
      if ($(this.state.resources[i]).attr("keytext") == txt) {
        return $(this.state.resources[i]).attr("valuetext");
      }
    }
  }
  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}></Redirect>;
    }

    return (
      <>
        <h4 className="login-block__subheading">כניסה עם</h4>

        <style>
          {
            "@media (max-width:991px) { .login-wrapper{padding-top:0px !important} .login-form__field{font-size:16px}  }"
          }
        </style>
        <Tab.Container id="login-tab" defaultActiveKey="otp">
          <Nav variant={null} className="nav-tabs login__nav-tabs">
            <Nav.Item className="login__nav-item">
              <Nav.Link eventKey="otp" className="login__nav-link">
                הודעת SMS
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="login__nav-item">
              <Nav.Link eventKey="password" className="login__nav-link">
                שם משתמש וסיסמה
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="otp" className="login-tab-pane">
              <LoginViaOtp
                onLoginSuccess={this.onLoginSuccess.bind(this)}
                onLoginFailed={this.onLoginFailed.bind(this)}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="password" className="login-tab-pane">
              <LoginViaPassword
                onLoginSuccess={this.onLoginSuccess.bind(this)}
                onLoginFailed={this.onLoginFailed.bind(this)}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </>
    );
  }
}
