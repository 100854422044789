import React from "react";
import { BaseComponent } from "..";
import { AccountStatusApi } from "../../api";
import ResponsiveTable, { IResponsiveTable } from "../Macros/ResponsiveTable";
import CustomSelect, { ICustomSelectItem } from "../Macros/CustomSelect";

interface IStatusProps {
  accountId: string;
}

interface IStatusState {
  year: string;
  years: ICustomSelectItem[];
  routesTable: IResponsiveTable;
  balanceTable: IResponsiveTable;
  isLoading: boolean;
  hasResults: boolean;
}

export default class Status extends BaseComponent<IStatusProps, IStatusState> {
  constructor(props: Readonly<IStatusProps>) {
    super(props);
    this.state = {
      year: sessionStorage.getItem("maxRevaluationDate") == null ? new Date().getFullYear().toString() : sessionStorage.getItem("maxRevaluationDate"),
      years: this.getYearsArray("SYBS").map(x => ({ label: x.toString(), value: x.toString() } as ICustomSelectItem)),
      routesTable: null,
      balanceTable: null,
      isLoading: true,
      hasResults: true
    };
    this.fetchData();
  }
  firstUse: boolean = true;
  fetchData(year?: string) {
    AccountStatusApi.getStatus(this.props.accountId, year || this.state.year).then(res => {
      if (res && res.total) {
        this.setState({
          hasResults: true,
          isLoading: false,
          routesTable: {
            title: this.label("account_title2"),
            headers: [this.label("account_course"), this.label("account_employeePay"), this.label("account_employerPay"), this.label("account_comp"), this.label("account_total")],
            rows:
              res == null
                ? []
                : res.courseBalance.map(course => {
                  return {
                    cells: [{ value: course.courseName }, { value: course.accStatus.customer && course.accStatus.customer.toLocaleString(), valueType: "number" }, { value: course.accStatus.employer && course.accStatus.employer.toLocaleString(), valueType: "number" }, { value: course.accStatus.comp && course.accStatus.comp.toLocaleString(), valueType: "number" }, { value: course.accStatus.total && course.accStatus.total.toLocaleString(), valueType: "number" }]
                  };
                })
          },
          balanceTable: {
            title: this.label("account_title3"),
            headers: [this.label("account_description"), this.label("account_employeePay"), this.label("account_employerPay"), this.label("account_comp"), this.label("account_total")],
            rows: [
              {
                cells: [
                  { value: this.label("account_remaingAssets") },
                  { value: res.remainingAssets.customer && res.remainingAssets.customer.toLocaleString(), valueType: "number" },
                  { value: res.remainingAssets.employer && res.remainingAssets.employer.toLocaleString(), valueType: "number" },
                  { value: res.remainingAssets.comp && res.remainingAssets.comp.toLocaleString(), valueType: "number" },
                  { value: res.remainingAssets.total && res.remainingAssets.total.toLocaleString(), valueType: "number" }
                ]
              },
              {
                cells: [{ value: this.label("account_deposited") }, { value: res.moneyDeposited.customer && res.moneyDeposited.customer.toLocaleString(), valueType: "number" }, { value: res.moneyDeposited.employer && res.moneyDeposited.employer.toLocaleString(), valueType: "number" }, { value: res.moneyDeposited.comp && res.moneyDeposited.comp.toLocaleString(), valueType: "number" }, { value: res.moneyDeposited.total && res.moneyDeposited.total.toLocaleString(), valueType: "number" }]
              },
              {
                cells: [
                  { value: this.label("account_transfered") },
                  { value: res.moneyTransferedTo.customer && res.moneyTransferedTo.customer.toLocaleString(), valueType: "number" },
                  { value: res.moneyTransferedTo.employer && res.moneyTransferedTo.employer.toLocaleString(), valueType: "number" },
                  { value: res.moneyTransferedTo.comp && res.moneyTransferedTo.comp.toLocaleString(), valueType: "number" },
                  { value: res.moneyTransferedTo.total && res.moneyTransferedTo.total.toLocaleString(), valueType: "number" }
                ]
              },
              {
                cells: [
                  { value: this.label("account_transferedFrom") },
                  { value: res.moneyTransferedFrom.customer && res.moneyTransferedFrom.customer.toLocaleString(), valueType: "number" },
                  { value: res.moneyTransferedFrom.employer && res.moneyTransferedFrom.employer.toLocaleString(), valueType: "number" },
                  { value: res.moneyTransferedFrom.comp && res.moneyTransferedFrom.comp.toLocaleString(), valueType: "number" },
                  { value: res.moneyTransferedFrom.total && res.moneyTransferedFrom.total.toLocaleString(), valueType: "number" }
                ]
              },
              {
                cells: [{ value: this.label("account_withdrawn") }, { value: res.moneyWithdrawn.customer && res.moneyWithdrawn.customer.toLocaleString(), valueType: "number" }, { value: res.moneyWithdrawn.employer && res.moneyWithdrawn.employer.toLocaleString(), valueType: "number" }, { value: res.moneyWithdrawn.comp && res.moneyWithdrawn.comp.toLocaleString(), valueType: "number" }, { value: res.moneyWithdrawn.total && res.moneyWithdrawn.total.toLocaleString(), valueType: "number" }]
              },
              {
                cells: [{ value: this.label("account_profits") }, { value: res.profits.customer && res.profits.customer.toLocaleString(), valueType: "number" }, { value: res.profits.employer && res.profits.employer.toLocaleString(), valueType: "number" }, { value: res.profits.comp && res.profits.comp.toLocaleString(), valueType: "number" }, { value: res.profits.total && res.profits.total.toLocaleString(), valueType: "number" }]
              },
              {
                cells: [
                  { value: this.label("account_clearingFeeRefund") },
                  { value: res.clearingFeeRefund.customer && res.clearingFeeRefund.customer.toLocaleString(), valueType: "number" },
                  { value: res.clearingFeeRefund.employer && res.clearingFeeRefund.employer.toLocaleString(), valueType: "number" },
                  { value: res.clearingFeeRefund.comp && res.clearingFeeRefund.comp.toLocaleString(), valueType: "number" },
                  { value: res.clearingFeeRefund.total && res.clearingFeeRefund.total.toLocaleString(), valueType: "number" }
                ]
              },
              {
                cells: [{ value: this.label("account_fees") }, { value: res.fees.customer && res.fees.customer.toLocaleString(), valueType: "number" }, { value: res.fees.employer && res.fees.employer.toLocaleString(), valueType: "number" }, { value: res.fees.comp && res.fees.comp.toLocaleString(), valueType: "number" }, { value: res.fees.total && res.fees.total.toLocaleString(), valueType: "number" }]
              }
            ],
            footRows: [
              {
                cells: [{ value: this.label("account_total") }, { value: res.total.customer && res.total.customer.toLocaleString(), valueType: "number" }, { value: res.total.employer && res.total.employer.toLocaleString(), valueType: "number" }, { value: res.total.comp && res.total.comp.toLocaleString(), valueType: "number" }, { value: res.total.total && res.total.total.toLocaleString(), valueType: "number" }]
              }
            ],
            comment: this.label("account_statusdisc").replace("{date}", sessionStorage.getItem("updatedate"))
          }
        });
      } else {
        this.setState({
          isLoading: false,
          hasResults: false
        });
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<IStatusProps>) {
    if (this.props.accountId !== prevProps.accountId) {
      this.setState({
        isLoading: true
      });
      this.fetchData();
    }
  }

  getBalanceTable() {
    let  balanceTable  =JSON.parse( JSON.stringify(this.state.balanceTable));
      let sum = 0;
      if(!balanceTable.rows[6]) return balanceTable;
      balanceTable.rows[6].cells.forEach((c: { value: number; }, i: any) => {
        if (!i) return;
        sum += c.value;
      });
      if(!sum) balanceTable.rows.splice(6,1)
     
    return balanceTable;
  }

  render() {
    return (
      <div className="pane-content">
        <style>{"@media (min-width:992px) and  (-ms-high-contrast: none), (-ms-high-contrast: active) { .account-data__date-col {position:absolute; left:20px}  }"}</style>
        {!this.state || this.state.isLoading ? (
          <div style={{ textAlign: "center" }}>
            <img src="/img/loading.gif" alt="loading" style={{ height: "50px" }} />
          </div>
        ) : (
            <section className="data-section">
              <div className="data-section__header data-section__header--status">
                <h3 className="data-section__title data-section__title--margin">
                  {this.label("account_title")}
                  <span className="number" id="selectedyear">
                    {this.state.year}
                  </span>
                </h3>
                <div className="data-section__filter-wrapper">
                  <CustomSelect
                    buttonClassName="data-filter__input data-filter__input--small number"
                    options={this.state.years}
                    value={this.state.year}
                    onChange={value => {
                      this.firstUse = false;
                      this.setState({ year: value, isLoading: true });
                      this.fetchData(value);
                    }}
                    label={this.firstUse ? "בחר שנה" : ""}
                  />
                </div>
              </div>
              {this.state.hasResults ? (
                <>
                  <ResponsiveTable name="account" table={this.state.routesTable} />
                  <ResponsiveTable name="account" table={this.getBalanceTable()} />
                </>
              ) : (
                  "אין נתונים"
                )}
              <style> {".account-table-block__table-notice {display: block !important;}"} </style>
            </section>
          )}
      </div>
    );
  }
}
