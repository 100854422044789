import React from "react";
import { NavLink } from "react-router-dom";
import BaseComponent from "../BaseComponent";
import { Collapse } from "react-bootstrap";
import { UserDataService, CmsService } from "../../services";
import config from "../../config";
import { CreditoApi } from "../../api";
import desktopLogo from '../../img/yalin-logo-desktop_2.png'

interface IMenuItem {
  alias: string;
  url: string;
  class: string;
  isNew?: boolean;
  noRead?: number
}
const menuItemsDetails: IMenuItem[] = [];
menuItemsDetails.push({
  alias: "הודעות",
  url: "/customers/home/messages",
  class: "messages",
});
menuItemsDetails.push({
  alias: "פרטים אישיים",
  url: "/customers/home/personalDetails/update",
  class: "user",
});
//hr
menuItemsDetails.push({
  alias: "ראשי",
  url: "/customers/home/dashboard",
  class: "home",
});
menuItemsDetails.push({
  alias: "יתרות",
  url: "/customers/home/balance",
  class: "balance",
});
menuItemsDetails.push({
  alias: "דוחות תקופתיים",
  url: "/customers/home/reports",
  class: "reports",
});

menuItemsDetails.push({
  alias: "אישור שנתי למס הכנסה",
  url: "/customers/home/tax-reports",
  class: "tax",
});
menuItemsDetails.push({
  alias: "טפסים דיגיטליים",
  url: `${config.API_URL}/forms`,
  class: "forms",
});
menuItemsDetails.push({
  alias: "סטטוס בקשות",
  url: "/customers/home/requests",
  class: "status",
  isNew: true,
});
menuItemsDetails.push({
  alias: "פעולות אחרונות",
  url: "/customers/home/recent-actions",
  class: "actions",
});
menuItemsDetails.push({
  alias: "צור קשר",
  url: "/customers/home/contact",
  class: "contacts",
});
menuItemsDetails.push({
  alias: "עזרה",
  url: "/customers/home/help",
  class: "info",
});
menuItemsDetails.push({
  alias: "פירוט הלוואות",
  url: `${config.API_URL}/forms/loans`,
  class: "loans",
});
menuItemsDetails.push({
  alias: "מסמכים סרוקים",
  url: "/customers/home/scan",
  class: "reports",
  isNew: true,
});

menuItemsDetails.push({
  alias: "הדואר שלי",
  url: "/customers/home/customerLetter",
  class: "reports",
  noRead: 0,
  isNew: true,
});

interface IHeaderState {
  menuOpen: boolean;
  totalMessages: number;
}

export class Header extends BaseComponent<{}, IHeaderState> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      menuOpen: false,
      totalMessages: parseInt(sessionStorage.getItem('sumUnViewed'))} 
    
  }


  componentDidMount() {
    setInterval(()=>{
      this.setState({
        totalMessages : parseInt(sessionStorage.getItem('sumUnViewed'))})
    },7000)
    
  }




  onCredito() {
    //create link
    CreditoApi.get().then((url) => {
      window.open(url + "");
    });
  }

  onShowing() {
    document.querySelector("body").classList.add("modal-open");
  }

  onHiding() {
    document.querySelector("body").classList.remove("modal-open");
  }

  onHided() {
    //hacking..
    document.querySelector<HTMLElement>("#yalin-menu").style.height = "auto";
  }

  getMenus() {
    const noReadCount = parseInt(sessionStorage.getItem('sumUnViewed'));

    const items = CmsService.content.sideMenu.items;
    const hrs: number[] = [];
    CmsService.content.sideMenu.items.forEach((x, i) => {
      if (!menuItemsDetails.some((y) => y.alias === x)) {
        hrs.push(i);
      }
    });
    const result = [];
    let currentMenu: IMenuItem[] = [];
    if (UserDataService.data.userRole === 5) {
      currentMenu = [
        {
          alias: "דוחות תקופתיים",
          url: "/customers/home/reports",
          class: "reports",
        },
      ];
    } else {
      for (let i = 0; i < items.length; i++) {

        if (hrs.indexOf(i) !== -1) {
          result.push(currentMenu);
          currentMenu = [];
        } else {
          const alias = items[i];
          if (JSON.parse(sessionStorage.getItem("isProd"))) {
            if (sessionStorage.getItem("imp") === "1" && !JSON.parse(sessionStorage.getItem("isFormsAuthorized")) && alias === "טפסים דיגיטליים") {
              continue;
            } else {
              if (alias === "הדואר שלי") {
                const menuItem = menuItemsDetails.find((x) => x.alias === alias);
                menuItem.noRead = noReadCount;
              }

              currentMenu.push(menuItemsDetails.find((x) => x.alias === alias));
            }
          } else if (
            alias === "טפסים דיגיטליים" &&
            (JSON.parse(this.siteSetting("isformslinkhide")) ||
              (!UserDataService.data.isEnvironmentEnableRemoteForms &&
                sessionStorage.getItem("imp") === "1" &&
                !UserDataService.data.isTestMode))
          ) {
            continue;
          } else {
            if (alias === "הדואר שלי") {
              const menuItem = menuItemsDetails.find((x) => x.alias === alias);
              menuItem.noRead = noReadCount;
            }

            currentMenu.push(menuItemsDetails.find((x) => x.alias === alias));
          }
        }
      }
    }
    result.push(currentMenu);
    return result;
  }

  onMenuItemClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    this.setState({
      menuOpen: false,
    });
  }

  render() {
    const { menuOpen } = this.state;
    const user = UserDataService.data;
    sessionStorage.setItem(
      "maxRevaluationDate",
      user.maxRevaluationDate.toString()
    );
    const hasLoan = JSON.parse(sessionStorage.getItem("hasLoan"));
    return (
      <header className={"site-header" + (menuOpen ? " menu-open" : "")}>
        <style>{".mobile-header-btn{ visibility:hidden"}</style>

        <style>
          {
            "@media (max-width:991px) {.yalin-navbar-nav--account {order: 0;} .main-dashboard__welcome {display: none;}"
          }
        </style>
        <div className="site-header__top">
          <div className="container-fluid">
            <div className="row site-header__row">
              <button
                type="button"
                onClick={() => this.setState({ menuOpen: !menuOpen })}
                aria-controls="yalin-menu"
                aria-expanded={menuOpen}
                aria-label="Toggle navigation"
                className={
                  "navbar-toggler yalin-navbar-toggler" +
                  (!menuOpen ? " collapsed" : "")
                }
              >
                <span className="triple-bar" />
              </button>
              <NavLink
                to="/customers/home"
                title="Yalin Lapidot"
                className="navbar-brand yalin-brand"
                onClick={this.onMenuItemClick.bind(this)}
              >
                <img
                  src={desktopLogo}
                  alt="Yalin Lapidot"
                  width="239"
                  height="48"
                  className="d-none d-lg-block"
                />
                <img
                  src="img/yalin-logo-mobile.png"
                  alt="Yalin Lapidot"
                  width="74"
                  height="34"
                  className="d-lg-none"
                />
              </NavLink>

              <ul className="site-header__mobile-controls-list">
                <li className="site-header__mobile-control-item">
                  <button
                    type="button"
                    data-toggle="collapse"
                    data-target="#headerSearchBlock"
                    aria-expanded="false"
                    aria-controls="headerSearchBlock"
                    className="mobile-header-btn mobile-header-btn-search"
                  >
                    <svg width="16" height="16" className="icon-search-toggle">
                      <use xlinkHref="#icon-search-toggle"></use>
                    </svg>
                  </button>
                </li>
                <li className="site-header__mobile-control-item">
                  <a href="messages.html" className="mobile-header-btn">
                    <svg width="20" height="16" className="icon-messages">
                      <use xlinkHref="#icon-messages"></use>
                    </svg>
                    <span className="msg-count">
                      {this.state.totalMessages}
                    </span>
                  </a>
                </li>
              </ul>
              <div
                className="site-header__impersonation-block"
                style={{
                  margin: "10px 0px 0px auto",
                  display:
                    sessionStorage.getItem("imp") === "1" ? "block" : "none",
                  background: "red",
                  padding: "10px 30px 0px 30px",
                  color: "white",
                  borderRadius: "15px",
                  height: "40px",
                  fontSize: "20px",
                  width: "50%",
                  textAlign: "center",
                }}
              >
                שים לב: האתר במצב הדמייה
              </div>
              <div className="site-header__search-block collapse">
                <form action="/" className="header-search">
                  <input
                    id="header-search-input"
                    type="search"
                    name="header-search"
                    placeholder="חיפוש"
                    aria-label="חיפוש"
                    className="header-search__input"
                  />
                  <button
                    type="submit"
                    aria-label="חיפוש"
                    title="חיפוש"
                    className="header-search__submit"
                  >
                    <svg width="20" height="20" className="icon-search-btn">
                      <use xlinkHref="#icon-search-btn"></use>
                    </svg>
                  </button>
                </form>
              </div>
              <div className="site-header__account-block">
                <a
                  href="/customers/login"
                  onClick={() => {
                    this.onHiding();
                    UserDataService.logout();
                  }}
                  className="header-exit-btn"
                >
                  התנתק
                </a>
              </div>
            </div>
          </div>
        </div>
        <nav className="site-sidebar yalin-navbar">
          <Collapse
            in={menuOpen}
            onEntering={this.onShowing}
            onExiting={this.onHiding}
            onExited={this.onHided}
          >
            <div
              id="yalin-menu"
              className="navbar-collapse yalin-navbar-collapse"
            >
              <div className="yalin-navbar__content">
                <div className="yalin-navbar__content-main">
                  <div className="welcome-block">
                    <h3 className="welcome-block__person">
                      {user.firstname} {user.lastname}
                    </h3>
                    {user.lastLogin && (
                      <p
                        className="welcome-block__last-login"
                        style={{
                          display:
                            sessionStorage.getItem("imp") === "1"
                              ? "none"
                              : "block",
                        }}
                      >
                        כניסה אחרונה
                        <time dateTime={user.lastLogin}>{user.lastLogin}</time>
                      </p>
                    )}
                  </div>

                  {this.getMenus().map((menu, i) => (
                    <ul
                      key={i}
                      className={
                        "navbar-nav yalin-navbar-nav" +
                        (i === 0 ? " yalin-navbar-nav--account" : "")
                      }
                    >
                      {menu.map((item, menuIndex) => (
                        <li key={i + menuIndex} className="nav-item">
                          {item.isNew && (
                            <img
                              className="new"
                              src={require("../../img/new.svg")}
                            />
                          )}
                          {
                            item.noRead > 0 && (
                              <div className="noReadIcon">{this.state.totalMessages}</div>
                            )
                          }
                          {item.class === "forms" || item.class === "loans" ? (
                            <>
                              {item.class === "loans" &&
                                hasLoan === false ? null : (
                                <a
                                  href={item.url}
                                  className="nav-link"
                                  onClick={this.onMenuItemClick.bind(this)}
                                >
                                  {item.alias}
                                  <svg
                                    width="32"
                                    height="32"
                                    className={"icon-" + item.class}
                                  >
                                    <use
                                      xlinkHref={"#icon-" + item.class}
                                    ></use>
                                  </svg>
                                </a>
                              )}
                            </>
                          ) : (
                            <NavLink
                              to={item.url}
                              className="nav-link"
                              onClick={this.onMenuItemClick.bind(this)}
                            >
                              {item.alias}
                              <svg
                                width="32"
                                height="32"
                                className={"icon-" + item.class}
                              >
                                <use xlinkHref={"#icon-" + item.class}></use>
                              </svg>
                              {item.alias === "הודעות" && (
                                <span className="nav-link__msg-count">
                                  {this.state.totalMessages}
                                </span>
                              )}
                            </NavLink>
                          )}
                        </li>
                      ))}

                      {JSON.parse(this.siteSetting("iscreditoon")) &&
                        user.isLoan &&
                        Boolean(i) && (
                          <li onClick={this.onCredito} className="credit-item">
                            <div className="credit-item-container">
                              <img src={require("../../img/icons/loan.png")} />
                              <span className="text">בקשה להלוואה</span>
                            </div>
                          </li>
                        )}
                    </ul>
                  ))}
                </div>

                <div className="yalin-navbar__promo">
                  {CmsService.content &&
                    CmsService.content.sideMenu.banners &&
                    CmsService.content.sideMenu.banners.map((x, i) => {
                      const bannerContent = (
                        <div className="promo-card__content">
                          <img
                            src={x.image}
                            alt={x.label}
                            className="promo-card__img"
                          />
                          <p className="promo-card__text">{x.label}</p>
                        </div>
                      );
                      return x.link.toLowerCase().startsWith("http") ? (
                        <a href={x.link} className="promo-card">
                          {bannerContent}
                        </a>
                      ) : (
                        <NavLink to={x.link} className="promo-card">
                          {bannerContent}
                        </NavLink>
                      );
                    })}
                </div>
              </div>
            </div>
          </Collapse>
        </nav>
      </header>
    );
  }
}
