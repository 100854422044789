
import { BaseSecuredApi } from './BaseSecuredApi';

export interface IDocs {
    id: number;
    fundName: string;
    accountNumber: number;
    docTypeID: number
    fundId: number;
    dateScan: Date;
    docTypeName: string;
    isViewed: boolean;
}

export interface IShowDocs {

   id:number;
    fundName: string;
    accountNumber: number;
    dateScan: Date;
    docTypeName: string;
    isViewed: boolean;

}

export interface IShowMobileDocs {

  id:number;
  docTypeName: string;

   accountNumber: number;
   dateScan: Date;
   isViewed: boolean;


}



export interface IshowResult{
  totalCount: number,
  docs:IDocs[]
}



export interface IrequestGet {

    accountId?: number,
    fundType?: null,
    year?: number,
    docType?: number,
    pageSize: number,
    pageNo: number

}


class ScanDocs extends BaseSecuredApi {
    constructor() {
        super('ScanDocs')
    }


    get(data: IrequestGet){
        return this.POST<IshowResult>({
            action: '',
            data,
          });
    }


    Download(docId: number) {
        return this.DownloadBlob<any>({
          action: 'pdf',
          data: { docId: docId }
        }).catch((err) => {
          console.log(err);
    
          return err;
        });
      }

      delete(docId: number) {
        return this.GET<any>({
          action: `delete/${docId}`,
        });
      }

      deleteAll() {
        return this.GET<any>({
          action: 'delete/viewed'
        })
      }
    
}


export const ScanDocsApi = new ScanDocs();
