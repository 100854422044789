import React from 'react';
import { NavLink } from 'react-router-dom';
import { ICmsBanner } from '../../api/Cms';

export default class CmsBannersInner extends React.Component<{ banners: ICmsBanner[] }, {}>{

    render() {
        return !this.props.banners ? null :
            <>
                <div className="data-section--concentration-total"></div>
                <div className="data-section--balance-img">{this.props.banners.map((x, i) => {
                    const banner = <div className="img-container" key={i}><img src={x.image} alt={x.label} style={{ width: '100%' }} /></div>;
                    return !x.link ?
                        banner :
                        x.link.toLowerCase().startsWith('http') ?
                            <a href={x.link} key={i} target="_blank">{banner}</a> :
                            <NavLink to={x.link} key={i} target="_blank">{banner}</NavLink>;
                })}</div>
            </>;
    }

};