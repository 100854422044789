import React from 'react';
import { UserDataService } from '../../services';
import { ErrorType } from '../Macros/ErrorType';
import { LoginApi } from '../../api';
import { BaseComponent } from '..';
import { ILoginMethodLayoutProps } from '.';
import FormWithValidation from '../Macros/FormWithValidation';
import { Link } from 'react-router-dom';
import PopupBackground from '../Macros/Popup';

interface ILoginViaPasswordState {
  username: string;
  password: string;
  personalId: string;
  isLoading: boolean;
  locallables: { [key: string]: string };
  error: string;
}

export default class LoginViaPassword extends BaseComponent<ILoginMethodLayoutProps, ILoginViaPasswordState> {
  constructor(props: Readonly<ILoginMethodLayoutProps>) {
    super(props);
    this.state = {
      username: null,
      password: null,
      personalId: null,
      isLoading: false,
      locallables: null,
      error: '',
    };
  }

  rules = [
    {
      input: 'nl-name',
      validators: ['loginusername'],
    },
    {
      input: 'nl-personal',
      validators: ['personalId'],
    },
  ];

  login() {
    this.setState({
      isLoading: true,
    });
    this.setState({ error: '' });
    LoginApi.login(this.state.username, this.state.password, this.state.personalId)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.isRevaluationProcess) {
          this.setState({ error: this.label('err_revaluation') });
        } else {
          if (res.status) {
            try {
              // this.setState({ error: ErrorType.getErrorMsg(res.status) });
              if (res.status.toString().substring(0, 1) === '5') { // res.status start with 5
                this.setState({ error: this.label('http_error_5xx') });
              } else {
                if (this.label(`http_error_${res.status}`).includes('http_error_')) {
                  this.setState({ error: 'שגיאה כללית שלא נמצאה' });
                } else {
                  console.log(this.label(`http_error_${res.status}`))
                  this.setState({ error: this.label(`http_error_${res.status}`) });
                }
              }
            } catch (err) {
              console.log(err);
              this.setState({ error: 'השרת אינו זמין - שגיאה כללית' });
            }
          } else {
            UserDataService.setUser(this.state.username, res.token);
            sessionStorage.setItem('isBlockedToAction', JSON.stringify(res.isBlockedToAction));
            sessionStorage.setItem('customerId', JSON.stringify(res.customerId));
            sessionStorage.setItem('zoomAnaliticsData', res.jsonData);
            sessionStorage.setItem("isGemelShowed",JSON.stringify(true));

            sessionStorage.setItem('hasLoan', JSON.stringify(res.hasLoan));
            this.props.onLoginSuccess();
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.setState({ error: ErrorType.getErrorMsg(err.status) });
      });
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    this.login();
    event.preventDefault();
  }

  forgotPwdMessage() {
    $('#forgotPwdMessagetxt').text(this.label('forgotPwdAlert_message'));
    var n = this.label('forgotPwdAlert_Number');
    $('#forgotPwdMessageNum')
      .text(n)
      .attr('href', 'tel:' + n);

    $('.forgotPwdMsg').css('visibility', 'visible');
  }

  render() {
    return (
      <FormWithValidation rules={this.rules} onSubmit={this.handleSubmit.bind(this)} className="login-form">
        <PopupBackground />
        <div className="login-form__body">
          <div className="login-form__form-group">
            <label htmlFor="nl-name" className="login-form__label sr-only">
              שם משתמש
            </label>
            <input
              type="text"
              name="nl-name"
              id="nl-name"
              placeholder="שם משתמש"
              required
              className="login-form__field"
              onChange={(e) => this.setState({ username: e.target.value })}
              autoComplete="off"
            />
            <div className="col-12">
              <Link to="/customers/login/forgotUsername" style={{ float: 'left', fontSize: '14px' }}>
                שכחתי שם משתמש
              </Link>
            </div>
          </div>
          <div className="login-form__form-group">
            <label htmlFor="nl-personal" className="login-form__label sr-only">
              תעודת זהות
            </label>
            <input
              type="number"
              inputMode="numeric"
              pattern="\d*"
              name="nl-personal"
              id="nl-personal"
              placeholder="תעודת זהות"
              required
              className="login-form__field"
              onChange={(e) => this.setState({ personalId: e.target.value + '' })}
              autoComplete="new-password"
            />
          </div>

          <div className="login-form__form-group">
            <label htmlFor="nl-pass" className="login-form__label sr-only">
              סיסמה
            </label>
            <input
              type="password"
              name="nl-pass"
              id="nl-pass"
              placeholder="סיסמה"
              required
              className="login-form__field"
              onChange={(e) => this.setState({ password: e.target.value })}
              autoComplete="new-password"
            />
            <div className="col-12">
              <a
                href="\#"
                onClick={(e) => {
                  e.preventDefault();
                  this.forgotPwdMessage();
                }}
                style={{ float: 'left', fontSize: '14px' }}
              >
                שכחתי סיסמה
              </a>
            </div>
          </div>
          <div className="row login-form__form-group">
            <div style={{ margin: '20px 15px 0 0' }} className="forgotPwdMsg">
              <span id="forgotPwdMessagetxt"></span> <a href="\#" id="forgotPwdMessageNum"></a>
            </div>
          </div>
        </div>
        {this.state.error && <span className="error">{this.state.error}</span>}
        <div className="login-form__footer">
          {this.state.isLoading ? (
            <div style={{ textAlign: 'center', flex: '1' }}>
              <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
            </div>
          ) : (
            <>
              <div className="login-form__terms yalin-checkbox">
                <input type="checkbox" name="nl-confirm" required id="nl-confirm" className="yalin-checkbox__input" />
                <label htmlFor="nl-confirm" className="yalin-checkbox__label">
                  אני מאשר/ת את
                  <a target="_blank" id="pwdTerms" href="\#">
                    תנאי השימוש
                  </a>
                </label>
              </div>
              <div className="login-form__submit-container">
                <button type="submit" className="login-form__submit">
                  המשך
                </button>
              </div>
            </>
          )}
        </div>
      </FormWithValidation>
    );
  }
}
