import { BaseSecuredApi } from "./BaseSecuredApi";

export interface IGetBalanceResult {
    updateDate: string,
    total: number,
    products: IProductBalance[]
}

export interface IExportResult {
    downloadUrl: string
}

export interface IProductBalance {
    name: string,
    balance: number,
    accounts: IAccountBalance[],
    showSeniority: boolean
}

export interface IAccountBalance {
    accountNumber: number,
    state: string,
    employer: string,
    seniority: string,
    fundAndRoute: string,
    balance: number
}

class Balance extends BaseSecuredApi {

    constructor() {
        super("Balance");
    }

    get() {
        return this.POST<IGetBalanceResult>({
            cache: true
        });
    }

    exportExcel() {
        return this.POST<IExportResult>({
            action: 'exportExcel'
        }).then(res => {
            if (res.downloadUrl) {
                res.downloadUrl = BaseSecuredApi.baseApiUrl + res.downloadUrl;
                return res;
            }
        });
    }
    exportPdf() {
        return this.POST<IExportResult>({
            action: 'exportPdf'
        }).then(res => {
            if (res.downloadUrl) {
                res.downloadUrl = BaseSecuredApi.baseApiUrl + res.downloadUrl;
                return res;
            }
        });
    }

}

export const BalanceApi = new Balance();