import { BaseSecuredApi } from "./BaseSecuredApi";

export interface IGetRequest {
    pageNumber: number;
    period?: number;
    type?: number;
    accountNumber?: number;
}

export interface IExportResult {
    downloadUrl: string
}

export interface IGetResponse {
    totalPages: number;
    reports: IReport[];
}

export interface IReport {
    reportId: number;
    accountNumber: number;
    accountName: string;
    period: number;
    quarter: string;
    type: string;
}

class Reports extends BaseSecuredApi {

    constructor() {
        super("Reports");
    }

    get(data: IGetRequest) {
        return this.POST<IGetResponse>({
            data: data
        });
    }
    exportReportsPdf(reportId: string) {
        return this.POST<IExportResult>({
            action: 'downloadPdf',
            data: { reportId: reportId }
        }).then(res => {
            if (res.downloadUrl) {
                res.downloadUrl = BaseSecuredApi.baseApiUrl + res.downloadUrl;
                return res;
            }
        });
    }


}

export const ReportsApi = new Reports();