import { BaseSecuredApi } from './BaseSecuredApi';

export interface IGetCreditoResult {
  url: string;
}

class Credito extends BaseSecuredApi {
  constructor() {
    super('UserData/credit');
  }

  get() {
    return this.GET<IGetCreditoResult>({
      action: 'info',
    });
  }
}

export const CreditoApi = new Credito();
