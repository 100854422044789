import React from 'react';
import { BaseComponent } from '..';
import { Redirect } from 'react-router';
import { FormWithValidation } from '../Macros';
import { LoginApi } from '../../api';

interface IState {
    personalId: string,
    cellnumber: string,
    isSending: boolean,
    redirectTo: string,
    error:string
};

export default class ForgotPassword extends BaseComponent<{}, IState> {

    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            personalId: null,
            cellnumber: null,
            isSending: false,
            redirectTo: null,
            error:''
        };
    }

    onSuccess() {
        alert("בדוק את תיבת הדואר שלך");
        // this.setState({
        //     redirectTo: '/home'
        // });
    }


    rules = [
        {
            "input": "personal-id",
            "validators": ['personalId']
        },
        {
            "input": "tel",
            "validators": ['phone']
        }
    ];

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {

        this.setState({
            isSending: true
        });
        this.setState({ error: '' });

        LoginApi.sendResetPassword(this.state.personalId, this.state.cellnumber).then(res => {
            if (res && res.success) {
                this.onSuccess();
            } else {
                this.setState({ error: res.message != null ? res.message : "נסה שנית מאוחר יותר" });
            }
        }).catch(res => {
            this.setState({ error: "נסה שנית מאוחר יותר" });
        }).finally(() => {
            this.setState({
                isSending: false
            });
        });
        event.preventDefault();
    }

    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}></Redirect>;
        }

        return <div className="forgot-password-container">
            <h4 className="login-block__heading">שכחתי סיסמה</h4>
            <h5 className="login-block__subheading">יש להזין את הפרטים הבאים על מנת שנוכל לאפשר לך לאפס את סיסמתך</h5>

            <FormWithValidation rules={this.rules} onSubmit={this.handleSubmit.bind(this)} className="login-form">
                <div className="login-form__body">
                    <div className="login-form__form-group">
                        <label htmlFor="personal-id" className="login-form__label sr-only">תעודת זהות</label>
                        <input  type="number" inputMode="numeric" pattern="\d*" name="personal-id" id="personal-id" placeholder="תעודת זהות" required className="login-form__field"
                            onChange={(e) => this.setState({ personalId: e.target.value })}
                        />
                    </div>
                    <div className="login-form__form-group">
                        <label htmlFor="tel" className="login-form__label sr-only">טלפון נייד</label>
                        <input type="number" inputMode="numeric" pattern="\d*" name="tel" id="tel" placeholder="טלפון נייד" required className="login-form__field"
                            onChange={(e) => this.setState({ cellnumber: e.target.value })}
                        />
                    </div>
                </div>
                {this.state.error && <span className="error">{this.state.error}</span>}
                <div className="login-form__footer">
                    <div className="login-form__terms yalin-checkbox">
                    </div>
                    <div className="login-form__submit-container">
                        <button type="submit" className="login-form__submit" disabled={this.state.isSending}>המשך</button>
                    </div>
                </div>
            </FormWithValidation>
        </div>;
    }
};