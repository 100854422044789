import React from "react";
import { BaseComponent } from "..";
import { TopDucks, CmsBannersInner } from "../Macros";
import { UserDataService } from "../../services";
import CustomSelect, { ICustomSelectItem } from "../Macros/CustomSelect";
import { ReportsApi } from "../../api";
import { IGetResponse } from "../../api/Reports";
import { Pagination } from "react-bootstrap";

interface IRequestsState {
  currentPage: number;

  selectedYear?: string;
  years: ICustomSelectItem[];

  selectedType?: string;
  types: ICustomSelectItem[];

  selectedAccount?: string;
  accounts: ICustomSelectItem[];

  reports?: IGetResponse;
  isLoading: boolean;
}

export default class Reports extends BaseComponent<{}, IRequestsState> {
  cmsPageAlias = "reports";

  constructor(props: Readonly<{}>) {
    super(props);

    const accounts = UserDataService.data.accounts.map((x) => {
      return {
        label: x.id.toString(),
        value: x.id.toString(),
      };
    });
    accounts.splice(0, 0, { label: "כל החשבונות", value: "" });

    let years = this.siteSetting("reports_years_list").split(",");
    years.unshift("שנה");
    this.state = {
      currentPage: 1,
      selectedYear: "",
      selectedType: "",
      selectedAccount: "",

      years: years.map(
        (x, i) =>
          ({
            label: x.toString(),
            value: i === 0 ? "" : x.toString(),
          } as ICustomSelectItem)
      ),

      types: ["כל הסוגים", "רבעוני", "שנתי מקוצר", "שנתי מפורט"].map(
        (label, index) =>
          ({
            label: label,
            value: index === 0 ? "" : index.toString(),
          } as ICustomSelectItem)
      ),

      accounts: accounts,
      isLoading: false,
    };
  }

  getOptions() {

    var syb = this.siteSetting('lastyesrtoshow')
    var maxRevaluationDate = parseInt(sessionStorage.getItem("maxRevaluationDate"));
    var years = new Array();
    years.unshift("שנה");

    let i: number;
    const bottom = parseInt(syb);

    for (let year = maxRevaluationDate; year >= bottom; year--) {
      years.push(year);
    }

    // for (i = 0; i < parseInt(syb); i++) {
    //   years.push(parseInt(maxRevaluationDate) - i);
    // }
    return years.map(
      (x, i) =>
      ({
        label: x.toString(),
        value: i === 0 ? "" : x.toString(),
      } as ICustomSelectItem)
    )

  }
  componentDidMount() {
    this.fetchData();
  }

  resetForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState(
      {
        selectedType: "",
        selectedYear: "",
        selectedAccount: "",
      },
      this.fetchData
    );

    event.preventDefault();
  }

  submitForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({ currentPage: 1 }, this.fetchData);

    event.preventDefault();
  }

  fetchData(page?: number) {
    this.setState({
      isLoading: true,
    });
    ReportsApi.get({
      pageNumber: page || this.state.currentPage,
      accountNumber: this.state.selectedAccount ? parseInt(this.state.selectedAccount) : null,
      period: this.state.selectedYear ? parseInt(this.state.selectedYear) : null,
      type: this.state.selectedType ? parseInt(this.state.selectedType) : null,
    }).then((res) =>
      this.setState({
        isLoading: false,
        reports: res,
      })
    );
  }

  getPages() {
    const result: number[] = [];
    if (this.state.reports) {
      for (let i = 1; i <= this.state.reports.totalPages; i++) {
        result.push(i);
      }
    }
    return result;
  }

  setPage(page: number) {
    if (page !== this.state.currentPage) {
      this.setState({
        currentPage: page,
      });
      this.fetchData(page);
    }
  }

  exportReportsPdf(reportId: string) {
    ReportsApi.exportReportsPdf(reportId).then((res) => {
      if (res.downloadUrl) {
        document.location.href = res.downloadUrl;
      }
    });
  }

  render() {
    return (
      <>
        <TopDucks small={true} />

        <div className="reports-intro">
          <div className="content-wrapper">
            <h1 className="reports-intro__heading">
              {this.cmsContent && this.cmsContent.title}
            </h1>
          </div>
        </div>

        <div className="content-wrapper content-wrapper--sheet">
          <div className="content-sheet">
            <div className="reports-data">
              <div className="pane-content pane-content--reports">
                <section className="data-section">
                  <div className="filter-block-wrapper">
                    <div className="filter-block">
                      <h3 className="filter-block__heading">סנן לפי</h3>

                      <button
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFilter"
                        aria-expanded="false"
                        aria-controls="collapseFilter"
                        aria-label="toggle controls"
                        className="filter-block__toggle collapsed"
                      >
                        <span className="caption">סנן לפי</span>
                      </button>

                      <div
                        id="collapseFilter"
                        className="collapse filter-block__collapse show"
                      >
                        <form
                          id="reports-filter-form"
                          className="data-filter filter-block__form"
                        >
                          <div className="data-filter__row">
                            <div className="data-filter__inputs">
                              <div className="data-filter__inputs-row">
                                <div className="col-auto data-filter__col">
                                  <label
                                    htmlFor="df-accounts"
                                    className="sr-only"
                                  >
                                    כל החשבונות
                                  </label>
                                  <CustomSelect
                                    id="df-accounts"
                                    buttonClassName="data-filter__input"
                                    options={this.state.accounts}
                                    value={this.state.selectedAccount}
                                    onChange={(value) => {
                                      this.setState({ selectedAccount: value });
                                    }}
                                  />
                                </div>
                                <div className="col-auto data-filter__col">
                                  <label htmlFor="df-date" className="sr-only">
                                    מתאריך
                                  </label>
                                  <CustomSelect
                                    id="df-date"
                                    buttonClassName="data-filter__input"
                                    options={this.getOptions()}
                                    value={this.state.selectedYear}
                                    onChange={(value) => {
                                      this.setState({ selectedYear: value });
                                    }}
                                  />
                                </div>
                                <div className="col-auto data-filter__col">
                                  <label htmlFor="df-type" className="sr-only">
                                    כל הסוגים
                                  </label>
                                  <CustomSelect
                                    id="df-type"
                                    buttonClassName="data-filter__input"
                                    options={this.state.types}
                                    value={this.state.selectedType}
                                    onChange={(value) => {
                                      this.setState({ selectedType: value });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="data-filter__buttons">
                              <div className="data-filter__buttons-row">
                                <div className="col-auto data-filter__buttons-col">
                                  <button
                                    type="button"
                                    className="data-filter__btn data-filter__btn--reset"
                                    onClick={this.resetForm.bind(this)}
                                  >
                                    נקה
                                  </button>
                                </div>
                                <div className="col-auto data-filter__buttons-col">
                                  <button
                                    type="button"
                                    className="data-filter__btn"
                                    onClick={this.submitForm.bind(this)}
                                  >
                                    הצג
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="reports-table-block dskView hidebelow992">
                  {!this.state ? (
                    ""
                  ) : this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="/img/loading.gif"
                        alt="loading"
                        style={{ height: "50px" }}
                      />
                    </div>
                  ) : (
                    <div className="reports-table">
                      {this.state.reports &&
                      this.state.reports.totalPages > 0 ? (
                        <div className="thead">
                          <div className="tr">
                            <div className="th">
                              {this.label("reports_accountNo")}
                            </div>
                            <div className="th" style={{ display: "none" }}>
                              {this.label("reports_accountName")}
                            </div>
                            <div className="th">
                              {this.label("reports_period")}
                            </div>
                            <div className="th">
                              {this.label("reports_quarter")}
                            </div>
                            <div className="th">
                              {this.label("reports_type")}
                            </div>
                            <div className="th">
                              {this.label("reports_view")}
                            </div>
                          </div>
                        </div>
                      ) : (
                        this.label("no_data")
                      )}

                      <div className="tbody">
                        {this.state.reports &&
                          this.state.reports.reports &&
                          this.state.reports.reports.map((report, i) => (
                            <div className="tr" key={i}>
                              <div className="td number">
                                {report.type.includes("מקוצר") ||
                                report.type.includes("מפורט")
                                  ? ""
                                  : report.accountNumber}
                              </div>
                              <div className="td" style={{ display: "none" }}>
                                {report.accountName}
                              </div>
                              <div className="td number">{report.period}</div>
                              <div className="td">{report.quarter}</div>
                              <div className="td">{report.type}</div>
                              <div
                                className="td td-button"
                                style={{ textAlign: "right" }}
                              >
                                <a
                                  onClick={() =>
                                    this.exportReportsPdf(
                                      report.reportId.toString()
                                    )
                                  }
                                  className="save-pdf-btn"
                                  style={{ cursor: "pointer" }}
                                >
                                  יצא <span>PDF</span>
                                </a>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="reports-table-block mobView hideabove992">
                  {!this.state ? (
                    ""
                  ) : this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="/img/loading.gif"
                        alt="loading"
                        style={{ height: "50px" }}
                      />
                    </div>
                  ) : (
                    <div className="reports-table">
                      <div className="thead">
                        <div className="tr">
                          <div className="th">
                            {this.label("reports_accountNo")}
                          </div>
                          <div className="th" style={{ display: "block" }}>
                            {this.label("reports_period")}
                          </div>
                          <div className="th" style={{ display: "block" }}>
                            {this.label("reports_type")}
                          </div>
                          <div className="th">
                            {this.label("reports_quarter")}
                          </div>
                        </div>
                      </div>
                      <div className="tbody">
                        {this.state.reports &&
                          this.state.reports.reports &&
                          this.state.reports.reports.map((report, i) => (
                            <div className="tr" key={i}>
                              <div
                                className={
                                  "closedView tr cv_" + report.accountNumber
                                }
                              >
                                <span className="td number">
                                  {report.type.includes("מקוצר") ||
                                  report.type.includes("מפורט")
                                    ? ""
                                    : report.accountNumber}
                                </span>
                                <span className="td number">
                                  {report.period}
                                </span>
                                <span className="td">{report.type}</span>
                                <span className="td">{report.quarter}</span>
                                <div
                                  className="td-button"
                                  style={{ textAlign: "right" }}
                                >
                                  <a
                                    onClick={() =>
                                      this.exportReportsPdf(
                                        report.reportId.toString()
                                      )
                                    }
                                    className="save-pdf-btn"
                                    style={{ cursor: "pointer" }}
                                  >
                                    יצא <span>PDF</span>
                                  </a>
                                </div>
                              </div>

                              <div
                                className={
                                  "openView collapse tr hideView ov_" +
                                  report.accountNumber
                                }
                              >
                                <span className="td">
                                  {this.label("reports_accountNo")}
                                </span>
                                <span className="td number">
                                  {report.type.includes("מקוצר") ||
                                  report.type.includes("מפורט")
                                    ? ""
                                    : report.accountNumber}
                                </span>
                                <span className="td">
                                  {this.label("reports_period")}
                                </span>
                                <span className="td number">
                                  {report.period}
                                </span>
                                <span className="td">
                                  {this.label("reports_quarter")}
                                </span>
                                <span className="td">{report.quarter}</span>
                                <span className="td">
                                  {this.label("reports_type")}
                                </span>
                                <span className="td">{report.type}</span>
                                <div
                                  className="td td-button"
                                  style={{ textAlign: "right" }}
                                >
                                  <a
                                    onClick={() =>
                                      this.exportReportsPdf(
                                        report.reportId.toString()
                                      )
                                    }
                                    className="save-pdf-btn"
                                    style={{ cursor: "pointer" }}
                                  >
                                    יצא <span>PDF</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <style>
                  {" "}
                  {
                    "@media (max-width:991px) { .hidebelow992{display:none !important} .reports-table .th{ width:25%; } .hideView{ display:none !important; } .openView { display:none; }  .closedView .td {max-width:25%;} }"
                  }{" "}
                </style>
                <style>
                  {" "}
                  {
                    "@media (min-width:991px) { .hideabove992{display:none !important} }"
                  }{" "}
                </style>
                {this.state && this.state.reports && !this.state.isLoading && (
                  <Pagination
                    size="sm"
                    className="d-flex justify-content-center flex-row-reverse p-4"
                  >
                    {this.getPages().map((page) => (
                      <Pagination.Item
                        key={page}
                        active={page === this.state.currentPage}
                        onClick={() => this.setPage.call(this, page)}
                      >
                        {page}
                      </Pagination.Item>
                    ))}
                  </Pagination>
                )}

                <CmsBannersInner
                  banners={this.cmsContent && this.cmsContent.banners}
                />
                <style>
                  {" "}
                  {
                    ".page-link{color:#282828; font-size:16px !important;} .dropdown-close{display:none}"
                  }{" "}
                </style>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
