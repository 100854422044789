import React from "react";
import { BaseComponent } from "..";
import { TopDucks, CmsBannersInner } from "../Macros";
import CustomSelect, { ICustomSelectItem } from "../Macros/CustomSelect";
import { TaxReportsApi } from "../../api";
import { IReport } from "../../api/TaxReports";

interface IRequestsState {
  currentPage: number;

  selectedYear?: string;
  years: ICustomSelectItem[];

  reports?: IReport[];
  isLoading: boolean;
}

export default class TaxReports extends BaseComponent<{}, IRequestsState> {
  cmsPageAlias = "taxreports";

  constructor(props: Readonly<{}>) {
    super(props);
    let years = this.siteSetting("tax_years_list").split(",");
    years.unshift("שנה");

    this.state = {
      currentPage: 1,
      selectedYear: "",
      years: years.map(
        (x, i) =>
          ({
            label: x.toString(),
            value: i === 0 ? "" : x.toString(),
          } as ICustomSelectItem)
      ),
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  resetForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({ selectedYear: "" }, this.fetchData);

    event.preventDefault();
  }

  submitForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({ currentPage: 1 }, this.fetchData);

    event.preventDefault();
  }


  getOptions() {

    var syb = this.siteSetting('lastyeartoshowtaxreports')
    var maxRevaluationDate = parseInt(sessionStorage.getItem("maxRevaluationDate"));
    var years = new Array();
    years.unshift("שנה");

    let i: number;
    const bottom = parseInt(syb);

    for (let year = maxRevaluationDate; year >= bottom; year--) {
      years.push(year);
    }

    return years.map(
      (x, i) =>
      ({
        label: x.toString(),
        value: i === 0 ? "" : x.toString(),
      } as ICustomSelectItem)
    )

  }
  fetchData(page?: number) {
    this.setState({
      isLoading: true,
    });
    TaxReportsApi.get({
      pageNo: page || this.state.currentPage,
      year: parseInt(this.state.selectedYear) || null,
      fundID: null,
      pageSize: 100,
    })
      .then((res) =>
        this.setState({
          isLoading: false,
          reports: res,
        })
      )
      .catch(() =>
        this.setState({
          isLoading: false,
          reports: null,
        })
      );
  }

  exportReportsPdf(reportId: string) {
    TaxReportsApi.exportReportsPdf(reportId).then((res) => {
      if (res.downloadUrl) {
        document.location.href = res.downloadUrl;
      }
    });
  }

  render() {
    return (
      <>
        <TopDucks small={true} />

        <div className="tax-reports-intro">
          <div className="content-wrapper">
            <h1 className="tax-reports-intro__heading">
              {this.cmsContent && this.cmsContent.title}
            </h1>
          </div>
        </div>

        <div className="content-wrapper content-wrapper--sheet">
          <div className="content-sheet">
            <div className="tax-reports-data">
              <div className="pane-content pane-content--tax-reports">
                <section className="data-section">
                  <div className="filter-block-wrapper">
                    <div className="filter-block">
                      <h3 className="filter-block__heading">סנן לפי</h3>

                      <button
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFilter"
                        aria-expanded="false"
                        aria-controls="collapseFilter"
                        aria-label="toggle controls"
                        className="filter-block__toggle collapsed"
                      >
                        <span className="caption">סנן לפי</span>
                      </button>

                      <div
                        id="collapseFilter"
                        className="collapse filter-block__collapse show"
                      >
                        <form
                          id="tax-reports-filter-form"
                          className="data-filter filter-block__form"
                        >
                          <div className="data-filter__row">
                            <div className="data-filter__inputs">
                              <div className="data-filter__inputs-row">
                                <div className="col-auto data-filter__col">
                                  <label htmlFor="df-date" className="sr-only">
                                    מתאריך
                                  </label>
                                  <CustomSelect
                                    id="df-date"
                                    buttonClassName="data-filter__input"
                                    options={this.getOptions()}
                                    value={this.state.selectedYear}
                                    onChange={(value) => {
                                      this.setState({ selectedYear: value });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="data-filter__buttons">
                              <div className="data-filter__buttons-row">
                                <div className="col-auto data-filter__buttons-col">
                                  <button
                                    type="button"
                                    className="data-filter__btn data-filter__btn--reset"
                                    onClick={this.resetForm.bind(this)}
                                  >
                                    נקה
                                  </button>
                                </div>
                                <div className="col-auto data-filter__buttons-col">
                                  <button
                                    type="button"
                                    className="data-filter__btn"
                                    onClick={this.submitForm.bind(this)}
                                  >
                                    הצג
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="tax-reports-table-block dskView hidebelow992">
                  {!this.state ? (
                    ""
                  ) : this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="/img/loading.gif"
                        alt="loading"
                        style={{ height: "50px" }}
                      />
                    </div>
                  ) : (
                    <div className="tax-reports-table">
                      {this.state.reports && !!this.state.reports.length ? (
                        <div className="thead">
                          <div className="tr">
                            <div className="th">
                              {this.label("tax_reports_type")}
                            </div>
                            <div className="th">
                              {this.label("tax_reports_period")}
                            </div>
                            <div className="th">
                              {this.label("tax_reports_fundName")}
                            </div>
                            <div className="th">
                              {this.label("reports_view")}
                            </div>
                          </div>
                        </div>
                      ) : (
                        this.label("no_data")
                      )}

                      <div className="tbody">
                        {this.state.reports &&
                          this.state.reports.map((report, i) => (
                            <div className="tr" key={i}>
                              <div className="td number">{report.type}</div>
                              <div className="td number">{report.period}</div>
                              <div className="td">{report.fundName}</div>
                              <div
                                className="td td-button"
                                style={{ textAlign: "right" }}
                              >
                                <a
                                  onClick={() =>
                                    this.exportReportsPdf(
                                      report.reportID.toString()
                                    )
                                  }
                                  className="save-pdf-btn"
                                  style={{ cursor: "pointer" }}
                                >
                                  יצא <span>PDF</span>
                                </a>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="tax-reports-table-block mobView hideabove992">
                  {!this.state ? (
                    ""
                  ) : this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="/img/loading.gif"
                        alt="loading"
                        style={{ height: "50px" }}
                      />
                    </div>
                  ) : (
                    <div className="tax-reports-table">
                      <div className="thead">
                        <div className="tr">
                          <div className="th">
                            {this.label("tax_reports_type")}
                          </div>
                          <div className="th" style={{ display: "block" }}>
                            {this.label("tax_reports_period")}
                          </div>
                          <div className="th" style={{ display: "block" }}>
                            {this.label("tax_reports_fundName")}
                          </div>
                          <div className="th">{this.label("reports_view")}</div>
                        </div>
                      </div>
                      <div className="tbody">
                        {this.state.reports &&
                          this.state.reports.map((report, i) => (
                            <div className="tr" key={i}>
                              <div
                                className={
                                  "closedView tr cv_" + report.reportID
                                }
                              >
                                <span className="td number">{report.type}</span>
                                <span className="td number">
                                  {report.period}
                                </span>
                                <span className="td">{report.fundName}</span>
                                <div
                                  className="td-button"
                                  style={{ textAlign: "right" }}
                                >
                                  <a
                                    onClick={() =>
                                      this.exportReportsPdf(
                                        report.reportID.toString()
                                      )
                                    }
                                    className="save-pdf-btn"
                                    style={{ cursor: "pointer" }}
                                  >
                                    יצא <span>PDF</span>
                                  </a>
                                </div>
                              </div>

                              <div
                                className={
                                  "openView collapse tr hideView ov_" +
                                  report.reportID
                                }
                              >
                                <span className="td">
                                  {this.label("tax_reports_type")}
                                </span>
                                <span className="td number">{report.type}</span>
                                <span className="td">
                                  {this.label("tax_reports_period")}
                                </span>
                                <span className="td number">
                                  {report.period}
                                </span>
                                <span className="td">
                                  {this.label("tax_reports_fundName")}
                                </span>
                                <span className="td">{report.fundName}</span>
                                <div
                                  className="td td-button"
                                  style={{ textAlign: "right" }}
                                >
                                  <a
                                    onClick={() =>
                                      this.exportReportsPdf(
                                        report.reportID.toString()
                                      )
                                    }
                                    className="save-pdf-btn"
                                    style={{ cursor: "pointer" }}
                                  >
                                    יצא <span>PDF</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <style>
                  {
                    "@media (max-width:991px) { .hidebelow992{display:none !important} .tax-reports-table .th{ width:25%; } .hideView{ display:none !important; } .openView { display:none; }  .closedView .td {max-width:25%;} }"
                  }
                </style>
                <style>
                  {" "}
                  {
                    "@media (min-width:991px) { .hideabove992{display:none !important} }"
                  }{" "}
                </style>

                <CmsBannersInner
                  banners={this.cmsContent && this.cmsContent.banners}
                />
                <style>
                  {" "}
                  {
                    ".page-link{color:#282828; font-size:16px !important;} .dropdown-close{display:none}"
                  }{" "}
                </style>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
