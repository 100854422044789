import React from 'react';
import BaseComponent from '../BaseComponent';
import moment from 'moment';

interface IProps {
  date: string;
  handleInput: (event: { target: { name: any; value: any } }) => void;
  isDisable:boolean;
}

interface IState {
  Y: string;
  M: string;
  D: string;
}

export default class CustomDate extends BaseComponent<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
      Y: '',
      M: '',
      D: ''
    };
  }

  componentDidMount() {
    //block - + e from input number
    const EldateInputs = document.querySelectorAll(".date__field")
    for (let i: number = 0; i < EldateInputs.length; i++) {
      EldateInputs[i].addEventListener("keypress", function (event: any) {
        if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57) {
          event.preventDefault();
        }
      })
    }

    if (this.props.date) {
      let date = this.props.date.split('/')
      this.setState({
        Y: date[2] + '',
        M: date[1] + '',
        D: date[0] + ''
      });
    }
  }


  handleDate = (target: { name: any; value: any }) => {
    const { name, value } = target;
    const secState: { [key: string]: any } = this.state;
    secState[name] = value;
    const { Y, M, D } = secState;
    let obj = { [name]: value } as Pick<IState, keyof IState>;
    this.setState(obj);
    this.props.handleInput({ target: { name: 'birthDate', value: `${D}/${M}/${Y}` } });
  };

  componentDidUpdate() {
    const { Y, M, D } = this.state;
    if (Y.length === 4 && M.length === 2 && D.length === 2) {
      const elInput = document.querySelector('.bd-input') as HTMLElement
      elInput.focus()
      elInput.blur()
    }
  }



  handleBlur = (target: { name: any; value: any }) => {
    let { name, value } = target;
    if (value && parseInt(value) < 9 && !value.includes('0')) {
      value = '0' + value;
    }
    let obj = { [name]: value } as Pick<IState, keyof IState>;
    this.setState(obj);
  };
  render() {
    return (
      <div className="update-form__field  move-top">
        <input
          onChange={() => { }}
          style={{ visibility: 'hidden', position: 'absolute' }}
          name="birthDate"
          value={this.props.date}
          required
          className="bd-input"
          disabled={this.props.isDisable}
        />
        <div className="date__container ">
          <input
            tabIndex={3}
            value={this.state.Y}
            placeholder="YYYY"
            name="Y"
            inputMode="numeric"
            disabled={this.props.isDisable}
            pattern="\d*"
            className={`date__field ${this.props.isDisable ? 'disable-date':''} `}
            onChange={ev => {
              if ((ev.target.value + '').length < 5) {
                this.handleDate(ev.target);
              }
            }}
            onBlur={ev => this.handleBlur(ev.target)}
            type="number"
          />
          <span style={{ fontSize: '17px' }}>/</span>
          <input
            tabIndex={2}
            value={this.state.M}
            placeholder="MM"
            inputMode="numeric"
            pattern="\d*"
            name="M"
            className={`date__field ${this.props.isDisable ? 'disable-date':''} `}
            disabled={this.props.isDisable}

            onChange={ev => {
              if (!ev.target.value || parseInt(ev.target.value) < 13) {
                this.handleDate(ev.target);
              }
            }}
            onBlur={ev => this.handleBlur(ev.target)}
            type="number"
          />
          <span style={{ fontSize: '17px' }}>/</span>
          <input
            name="D"
            tabIndex={1}
            value={this.state.D}
            placeholder="DD"
            inputMode="numeric"
            pattern="\d*"
            className={`date__field ${this.props.isDisable ? 'disable-date':''} `}
            disabled={this.props.isDisable}

            onChange={ev => {
              if (!ev.target.value || parseInt(ev.target.value) < 32) {
                this.handleDate(ev.target);
              }
            }}
            onBlur={ev => this.handleBlur(ev.target)}
            type="number"
          />
        </div>
      </div>
    );
  }
}
