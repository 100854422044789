import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import AfterLoginLayout from './components/AfterLoginLayout';
import Login from './components/Login';
import AccountStatus from './components/AccountStatus';

import { UserDataService } from './services';

// @TODO pass baseRoute to the component and use inside <Route path='/home' render={() => <AfterLoginLayout baseRoute='/home' />} />

class App extends React.Component {
    render() {
        return (
            <Switch>
                <Route path='/customers/home' component={AfterLoginLayout} />
                <Route path='/customers/login' component={Login} />
                <Route exact render={() => UserDataService.isLoggedIn ? <Redirect to="/customers/home" /> : window.location.href.toLowerCase().indexOf('login') === -1 ? window.location.href = '/customers/login' : <Redirect to="/customers/login" />} />
                <Route path='/customers/AccountStatus:id' component={AccountStatus} />
            </Switch>
        );
    }
}

export default App;