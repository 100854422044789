export default class SessionTimeout {
  constructor(warningTimeout?: number, logoutTimeout?: number) {
    this.warningTimeout = warningTimeout || 300000; //5 minutes
    this.logoutTimeout = logoutTimeout || 60000; //60 seconds

    this.resetTimer = this.resetTimer.bind(this);
  }

  protected warningTimeout: number;
  protected logoutTimeout: number;
  protected warningTimerID?: number;
  protected timeoutTimerID?: number;

  public OnWarning: () => void;
  public OnLogout: () => void;

  protected startTimer() {
    this.warningTimerID = window.setTimeout(this.warningInactive.bind(this), this.warningTimeout);
  }

  protected warningInactive() {
    window.clearTimeout(this.warningTimerID);

    if (this.OnWarning) {
      this.OnWarning();

      if (this.OnLogout) {
        this.Stop();
        this.timeoutTimerID = window.setTimeout(this.OnLogout, this.logoutTimeout);
      }
    }
  }

  protected resetTimer() {
    if (this.timeoutTimerID) window.clearTimeout(this.timeoutTimerID);

    if (this.warningTimerID) window.clearTimeout(this.warningTimerID);

    this.startTimer();
  }

  CancelWarning() {
    this.Start();
  }

  Start() {
    document.addEventListener('mousemove', this.resetTimer);
    document.addEventListener('keypress', this.resetTimer);
    document.addEventListener('touchmove', this.resetTimer);
    document.addEventListener('onscroll', this.resetTimer);

    this.startTimer();
  }

  Stop() {
    if (this.timeoutTimerID) window.clearTimeout(this.timeoutTimerID);

    if (this.warningTimerID) window.clearTimeout(this.warningTimerID);

    document.removeEventListener('mousemove', this.resetTimer);
    document.removeEventListener('keypress', this.resetTimer);
    document.removeEventListener('touchmove', this.resetTimer);
    document.removeEventListener('onscroll', this.resetTimer);
  }
}
