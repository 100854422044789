import React from 'react';
import { BaseComponent } from '..';
import { TopDucks, CmsBanners, FormTitle, DisplayPdf } from '../Macros';
import { UserDataApi, CmsApi } from '../../api';
import { UserDataService } from '../../services';
import { IGetPersonalDetailsResult } from '../../api/UserData';
import UpdatePersonalForm from '../UpdateDetailsForm/UpdatePersonalForm';
import UpdateContactForm from '../UpdateDetailsForm/UpdateContactForm';
import FormWithValidation from '../Macros/FormWithValidation';
import { PopupService } from "../Macros/Popup";


interface IState {
  data: {
    firstName: string;
    lastName: string;
    personalId: number;
    gender: number;
    birthDate: string;
    maritalStatus: number;
    email: string;
    phone: string;
    mobile: string;
    city: { name: string; id: number };
    street: string;
    houseNumber: string;
    apartment: string;
    houseEntrance: string;
    zip: string;
    mailbox: string;
    isBiometricId: boolean;
    isVerifyEmail: boolean;
    getDepositConfirmation: boolean;
    mothlyRevNotice: boolean;
    statementDeliveryType: number;
    lettrerApproved: boolean;



  };
  isSubmitLoading: boolean;
  isDisable: boolean;
  submitRes: { isRes: boolean; text: string };
  isSubmit: boolean;
  metadata?: { maritalStatusOptions: maritalStatusOptions[]; genderOptions: genderOptions[] };
  files: { regular: File; biometric: { tz: File; backTz: File } };
  prevState: any;
  isFormChange: boolean;
  nameToCheck: string[];
  isDataLoding: boolean;
  cityList: cityOptions[];
  isShowPdf: boolean;
  pdfUrl: string;
  htmlStream: string;

}

interface maritalStatusOptions {
  text: string;
  val: number;
}

interface genderOptions {
  text: string;
  val: number;
}

interface cityOptions {
  id: number;
  name: string;
}

export default class UpdatePersonalDetails extends BaseComponent<IGetPersonalDetailsResult, IState> {
  constructor(props: Readonly<IGetPersonalDetailsResult>) {
    super(props);
    this.state = {
      data: {
        firstName: '',
        lastName: '',
        personalId: 0,
        gender: null,
        birthDate: '',
        maritalStatus: null,
        email: '',
        phone: '',
        mobile: '',
        city: null,
        street: '',
        houseNumber: '',
        apartment: '',
        houseEntrance: '',
        zip: '',
        mailbox: '',
        isBiometricId: false,
        isVerifyEmail: true,
        getDepositConfirmation: false,
        mothlyRevNotice: false,
        statementDeliveryType: 1,
        lettrerApproved: false

      },
      isSubmitLoading: false,
      isDisable: true,
      submitRes: { isRes: true, text: '' },
      isSubmit: false,
      metadata: { maritalStatusOptions: [], genderOptions: [] },
      files: { regular: null, biometric: { tz: null, backTz: null } },
      prevState: null,
      isFormChange: false,
      nameToCheck: ['firstName', 'personalId', 'lastName', 'gender', 'birthDate', 'maritalStatus'],
      isDataLoding: false,
      cityList: [],
      isShowPdf: false,
      pdfUrl: '',
      htmlStream: '',
    };
  }

  componentDidMount() {
    this.setState({ isDataLoding: true });
    CmsApi.content().then((res) => {
      const resourceslist = res.pages.resources.resourceslist;
      for (let i = 0; i < resourceslist.length; i++) {
        const curr = resourceslist[i];
        if (curr.keytext === 'termsOfUseUrl') {
          const elLink = document.querySelector('#formTerm') as any;
          if (elLink) {
            elLink.href = curr.valuetext;
          }
        }
      }
    });
    UserDataApi.getPersonalDetails()
      .then((res) => {
        res.maritalStatus = res.maritalStatus === null ? -1 : res.maritalStatus;
        this.setState({ metadata: { ...res.metadata } });
        if (!res.city.id) {
          res.city = null;
        }
        if (!res.isVerifyEmail) {
          res.email = '';
        }
        if (!res.statementDeliveryType) {
          res.statementDeliveryType = 1
        }
        this.setState({ data: res, prevState: { ...res }, isDataLoding: false });
      })
      .catch(() => {
        this.setState({
          isDataLoding: false,
          isSubmitLoading: false,
          submitRes: { isRes: false, text: 'update_personal_faild' },
          isSubmit: true,
        });
      });
  }

  onSearchCity = (value: any) => {
    if (!value.length || value.length < 2) {
      this.setState({ cityList: [] });
    } else {
      return UserDataApi.getCity({ startWith: value }).then((res) => {
        this.setState({ cityList: res });
      });
    }
  };

  onchangeFile = (file: File, fileName: string) => {
    if (this.state.data.isBiometricId) {
      let { biometric } = this.state.files;
      let files = { ...this.state.files, biometric: { ...biometric, [fileName]: file } };
      this.setState({ files });
    } else {
      this.setState({ files: { ...this.state.files, regular: file } });
    }
  };

  handleIDRadio = (event: { target: { name: any; checked: boolean } }) => {
    let obj = { [event.target.name]: !this.state.data.isBiometricId } as Pick<IState, keyof IState>;
    this.setState({ data: { ...this.state.data, ...obj } });
    this.formChange(event.target.name, !this.state.data.isBiometricId);
  };

  handleInput = (event: { target: { name: any; value: any } }) => {
    console.log("events", event);

    //reset the terms checkbox
    const elChk = document.querySelector('#nl-confirm') as HTMLInputElement;
    elChk.checked = false;

    let value = event.target.value;
    if (event.target.name === 'gender') {
      value = parseInt(value);
    }
    value = typeof value === 'number' ? value : event.target.value;
    let obj = { [event.target.name]: value } as Pick<IState, keyof IState>;
    this.setState({ data: { ...this.state.data, ...obj } });
    if (this.state.nameToCheck.includes(event.target.name)) {
      this.formChange(event.target.name, value);
    }
  };

  onBlur = (event: { target: { name: any; value: any } }) => {
    let value = event.target.value;
    if (typeof value !== 'number') {
      value = value.trim();
      let obj = { [event.target.name]: value } as Pick<IState, keyof IState>;
      this.setState({ data: { ...this.state.data, ...obj } });
    }
  };

  formChange = (name: any, value: any) => {
    const secState: { [key: string]: any } = this.state.data;
    secState[name] = value;
    let isChange = true;
    for (const i in this.state.nameToCheck) {
      const curr = this.state.nameToCheck[i];
      isChange = isChange && this.state.prevState[curr] === secState[curr];
    }
    this.setState({ isFormChange: !isChange });
  };

  mobileAndTabletCheck = function () {
    if (window.navigator.msSaveOrOpenBlob) return true;
    let isCheck = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        isCheck = true;
    })(navigator.userAgent || navigator.vendor);
    return isCheck;
  };

  handleSubmit = (ev: any, isHtml: boolean = null) => {


    ev && ev.preventDefault();
    if (!this.state.isFormChange) {
      const secState: { [key: string]: any } = this.state.data;
      let isChange = true;
      for (const key in this.state.data) {
        isChange = isChange && this.state.prevState[key] === secState[key];
      }
      if (isChange) return;
    }
    this.setState({
      isSubmitLoading: true,
      prevState: { ...this.state.data },
    });
    let data = this.getDataForm(this.mobileAndTabletCheck());

    UserDataApi.submitPersonalDetails(data, isHtml)
      .then((res) => {
        if (res.status) {
          this.setState({
            isSubmitLoading: false,
            submitRes: { isRes: false, text: 'update_personal_faild' },
            isSubmit: true,
          });
        } else {
          if (UserDataService.isBlockedToAction) {
            this.getPersonalPdf(res);
          } else {
            this.setState({
              isSubmitLoading: false,
              submitRes: { isRes: true, text: 'update_personal_success' },
              isSubmit: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitLoading: false,
          submitRes: { isRes: false, text: 'update_personal_faild' },
          isSubmit: true,
        });
      });
  };

  getPersonalPdf = (blob: any) => {
    window.scroll(0, 0);
    if (this.mobileAndTabletCheck()) {
      let data = this.getDataForm(false);
      this.setState({
        isSubmitLoading: false,
        isShowPdf: true,
        htmlStream: blob,
      });
      UserDataApi.submitPersonalDetails(data, false).then((res) => {
        if (window.navigator.msSaveOrOpenBlob) {
          this.setState({
            pdfUrl: res,
          });
        } else {
          let file = new Blob([res], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          this.setState({
            pdfUrl: fileURL,
          });
        }
      });
    } else {
      let file = new Blob([blob], { type: 'application/pdf' });
      let fileURL = URL.createObjectURL(file);
      this.setState({
        isSubmitLoading: false,
        isShowPdf: true,
        pdfUrl: fileURL,
      });
    }
  };

  getDataForm = (isHtml: boolean = false) => {
    let data = new FormData();
    const dataState: { [key: string]: any } = { ...this.state.data };
    delete dataState.metadata;
    for (const key in dataState) {
      if (dataState[key] === null) data.append(key, '');
      else {
        if (key === 'city') {
          data.append('cityId', dataState[key].id);
        } else {
          if (key === 'apartment' && dataState[key] === '') {
            data.append(key, '-1');
          }
          if ((key === 'firstName' || key === 'lastName' || key === 'street') && dataState[key].includes("'")) {
            data.append(key, dataState[key].split("'").join('&#39;'));
          } else {
            data.append(key, dataState[key]);
          }
        }
      }
    }
    data.append('isConfirmTerms', 'true');
    if (!UserDataService.isBlockedToAction) {
      if (dataState.isBiometricId) {
        data.append('PersonalIdImage', this.state.files.biometric.tz);
        data.append('BiometricIdImageBack', this.state.files.biometric.backTz);
      } else {
        data.append('PersonalIdImage', this.state.files.regular);
      }
    } else {
      data.append('isHtml', JSON.stringify(isHtml));
    }
    return data;
  };

  backPopup = () => {
    PopupService.show(
      `<div style='text-align: center'>  </div>`,
      `${ this.label('back_popup_text')}`,
      [
        {
          caption: "לחזרה",
          onClick: () => {

            this.setState({ isDisable: true })

            PopupService.hide();

          },
        },
        {
          caption: " להמשך עריכה ",
          onClick: () => {

            this.setState({ isDisable: false })
            PopupService.hide();

          },
        },
      ]
  
    );

  }

  rules = [
    {
      input: 'firstName',
      validators: ['hebrewonly'],
      error: this.label('validation_err_hebrewonly'),
    },
    {
      input: 'lastName',
      validators: ['hebrewonly'],
      error: this.label('validation_err_hebrewonly'),
    },
    {
      input: 'personalId',
      validators: ['personalId'],
      error: this.label('validation_err_personalId'),
    },
    {
      input: 'birthDate',
      validators: ['date'],
      error: this.label('validation_err_date'),
    },
    {
      input: 'regular-tz',
      validators: ['file'],
      error: this.label('validation_err_file'),
    },
    {
      input: 'biometric-tz',
      validators: ['file'],
      error: this.label('validation_err_file'),
    },
    {
      input: 'biometric-backTz',
      validators: ['file'],
      error: this.label('validation_err_file'),
    },
    {
      input: 'maritalStatus',
      validators: ['maritalStatus'],
    },
    {
      input: 'email',
      validators: ['email'],
      error:
        document.documentElement.clientWidth < 992
          ? this.label('validation_err_email_short')
          : this.label('validation_err_email_long'),
    },
    {
      input: 'phone',
      validators: ['phone'],
      error: this.label('validation_err_phone'),
    },
    {
      input: 'mobile',
      validators: ['mobile'],
      error: this.label('validation_err_mobile'),
    },
    {
      input: 'city',
      validators: ['letterswithbasicpunc'],
      error: this.label('validation_err_letterswithbasicpunc'),
    },
    {
      input: 'street',
      validators: ['hebletterswithbasicpunc'],
      error: this.label('validation_err_hebletterswithbasicpunc'),
    },
    {
      input: 'houseNumber',
      validators: ['numericonly'],
      error: this.label('validation_err_numericonly'),
    },
    {
      input: 'apartment',
      validators: ['numericonly'],
      error: this.label('validation_err_numericonly'),
    },
    {
      input: 'houseEntrance',
      validators: ['lettersonly'],
      error: this.label('validation_err_lettersonly'),
    },
    {
      input: 'zip',
      validators: ['zip'],
      error: this.label('validation_err_zip'),
    },
    {
      input: 'mailbox',
      validators: ['numericonly'],
      error: this.label('validation_err_numericonly'),
    },
  ];

  render() {

    return (
      <>
        <TopDucks small={true} />
        <div className="personal-intro">
          <div className="content-wrapper">
            <h1 className="personal-intro__heading">פרטים אישיים</h1>
          </div>
        </div>
        <div className="content-wrapper content-wrapper--sheet">
          {this.state.isSubmit && (
            <h3 style={{ padding: '15px' }} className={this.state.submitRes.isRes ? 'text-success' : 'text-error'}>
              {this.label(this.state.submitRes.text)}
            </h3>
          )}

          <div className={!this.state.isSubmit || this.state.isShowPdf ? 'content-sheet' : 'd-none'}>

            {this.state.isShowPdf ? (
              <div style={{ padding: '25px 36px 32px 36px' }}>
                <DisplayPdf htmlStream={this.state.htmlStream} pdf={this.state.pdfUrl} />
                <div style={{ marginTop: '10px' }}>
                  <button
                    onClick={() => {
                      window.scroll(0, 0);
                      this.setState({ isShowPdf: false, pdfUrl: null });
                    }}
                    className="update-form__submit back-btn"
                  >
                    {this.label('back_minor_button')}
                  </button>
                </div>
              </div>
            ) : (


              <FormWithValidation
                rules={this.rules}
                onSubmit={(ev) => {
                  ev.preventDefault();
                  this.handleSubmit(ev, this.mobileAndTabletCheck());
                }}
              >

                {

                  this.state.isDisable &&
                  <button
                    className="data-filter__btn update-button-edit"
                    onClick={() => {
                      this.setState({ isDisable: false })
                    }}
                  >לשינוי הפרטים לחץ כאן</button>
                }

                <div className="personal-update">

                  <div className="personal-data__block">

                    <FormTitle
                      title={'update_personal_title'}
                      class="personal"
                      disc={UserDataService.isBlockedToAction ? 'update_personal_minor_disc' : 'update_personal_disc'}
                      isReplace={UserDataService.isBlockedToAction}
                      replaceObj={{
                        name: '{email}',
                        value: (
                          <a href={`mailto:${this.label('mail_to_minor_disc')}`}>{this.label('mail_to_minor_disc')}</a>
                        ),
                      }}
                    />
                    <br />


                    {this.state.isDataLoding ? (
                      <div style={{ textAlign: 'center' }}>
                        <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                      </div>
                    ) : (
                      <div className="personal-data__details">
                        {this.state.metadata && (

                          <UpdatePersonalForm
                            onchangeFile={this.onchangeFile}
                            handleIDRadio={this.handleIDRadio}
                            handleInput={this.handleInput}
                            onBlur={this.onBlur}
                            firstName={this.state.data.firstName}
                            lastName={this.state.data.lastName}
                            personalId={this.state.data.personalId}
                            maritalStatus={this.state.data.maritalStatus}
                            gender={this.state.data.gender}
                            birthDate={this.state.data.birthDate}
                            isBiometricId={this.state.data.isBiometricId}
                            metadata={this.state.metadata}
                            files={this.state.files}
                            isFormChange={
                              !(UserDataService.data && UserDataService.isBlockedToAction) && this.state.isFormChange
                            }
                            isDisable={this.state.isDisable}
                          />
                        )}
                      </div>
                    )}
                  </div>

                  <div className="personal-data__block">
                    <FormTitle
                      title={'update_contact_title'}
                      disc={UserDataService.isBlockedToAction ? '' : 'update_contact_disc'}
                    />
                    {this.state.isDataLoding ? (
                      <div style={{ textAlign: 'center' }}>
                        <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                      </div>
                    ) : (
                      <div className="personal-data__details">
                        <UpdateContactForm
                          onSearchCity={this.onSearchCity}
                          handleInput={this.handleInput}
                          onBlur={this.onBlur}
                          email={this.state.data.email}
                          phone={this.state.data.phone}
                          mobile={this.state.data.mobile}
                          city={this.state.data.city}
                          cityList={this.state.cityList}
                          street={this.state.data.street}
                          houseNumber={this.state.data.houseNumber}
                          apartment={this.state.data.apartment}
                          houseEntrance={this.state.data.houseEntrance}
                          zip={this.state.data.zip}
                          mailbox={this.state.data.mailbox}
                          isVerifyEmail={this.state.data.isVerifyEmail}
                          getDepositConfirmation={this.state.data.getDepositConfirmation}
                          mothlyRevNotice={this.state.data.mothlyRevNotice}
                          isDisable={this.state.isDisable}

                        />
                      </div>
                    )}
                  </div>
                  {
                    this.state.isDisable ?
                      <div className="personal-data__block" style={{ paddingTop: '15px' }}>


                        <FormTitle
                          title={'שרותים פעילים'}
                        />
                        <br />
                        <div style={{ padding: '3px' }}> עדכון אופן משלוח דוחות תקופתיים : {this.state?.data.statementDeliveryType == 1 ?
                          'קישור לנייד'
                          : this.state?.data.statementDeliveryType == 2 ? 'דןאר ישראל' :
                            this.state?.data.statementDeliveryType == 3 ? 'מייל' : ''}
                        </div>

                        <div
                          className="checkbox_style_footer_update_details"
                          style={{ padding: '3px' }}

                        >
                          <input
                            type="checkbox"
                            name="nl-confirm"
                            required
                            id="nl-confirm"
                            className="yalin-checkbox__input"
                            disabled
                            checked={this.state.data.lettrerApproved}
                          />
                          <label htmlFor="nl-confirm" className="yalin-checkbox__label" style={{
                            color: '#464646',
                            fontWeight: 'normal', background: '#d3d4d685 0% 0% no-repeat padding-box'
                          }}
                          >
                            דיוור דיגיטלי
                          </label>
                        </div>

                        <div
                          className="checkbox_style_footer_update_details"
                          style={{ padding: '3px' }}

                        >
                          <input
                            type="checkbox"
                            name="nl-confirm"
                            required
                            id="nl-confirm"
                            className="yalin-checkbox__input"
                            disabled
                            checked={this.state.data.mothlyRevNotice}
                          />
                          <label htmlFor="nl-confirm" className="yalin-checkbox__label" style={{
                            color: '#464646',
                            fontWeight: 'normal', background: '#d3d4d685 0% 0% no-repeat padding-box'
                          }}
                          >
                            הודעת SMS עדכון יתרות חודשי
                          </label>
                        </div>

                        <div
                          className="checkbox_style_footer_update_details"
                          style={{ padding: '3px' }}

                        >
                          <input
                            type="checkbox"
                            name="nl-confirm"
                            required
                            id="nl-confirm"
                            className="yalin-checkbox__input"
                            disabled
                            checked={this.state.data.getDepositConfirmation}
                          />
                          <label htmlFor="nl-confirm" className="yalin-checkbox__label" style={{
                            color: '#464646', fontWeight: 'normal',
                            background: '#d3d4d685 0% 0% no-repeat padding-box'
                          }}
                          >
                            הודעת SMS עדכון הפקדות מעסיק
                          </label>
                        </div>


                        <br />
                      </div>

                      : <div className={`${this.state.isDisable === true ? 'read-mode' : 'edit-mode'}`}>
                        <div className="personal-data__block">
                          <FormTitle
                            title={'update_statement_delivery_type_title'}
                          />
                          <br />
                          <div className='radio-group' style={{ padding: "7px" }}>
                            <label className="update-form__radio-label">
                              <span style={{ marginRight: '25px' }}>
                                {this.label('update_statement_delivery_type_sms')} </span>
                              <input
                                type="radio"
                                value={1}
                                name="statementDeliveryType"
                                required
                                onChange={(ev) => {
                                  this.handleInput(ev);
                                }}
                                checked={this.state?.data.statementDeliveryType == 1}
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="update-form__radio-label">
                              <span style={{ marginRight: '25px' }}>    {this.label('update_statement_delivery_type_post')}
                              </span>
                              <input
                                type="radio"
                                value={2}
                                name="statementDeliveryType"
                                required
                                onChange={(ev) => {
                                  console.log("ev2", ev);

                                  this.handleInput(ev);
                                }}
                                checked={this.state?.data.statementDeliveryType == 2}
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="update-form__radio-label">
                              <span style={{ marginRight: '25px' }}>
                                {this.label('update_statement_delivery_type_email')}
                              </span>
                              <input
                                type="radio"
                                value={3}
                                name="statementDeliveryType"
                                required
                                onChange={(ev) => {
                                  console.log("ev3", ev);

                                  this.handleInput(ev);
                                }}
                                checked={this.state?.data.statementDeliveryType == 3}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>

                        <div className="personal-data__block" style={{ paddingTop: '20px' }}>

                          <FormTitle
                            title={'שומרים על הסביבה'}
                            disc='שירות "דיוור ירוק" מאפשר לקבל מכתבים והודעות באמצעי דיגיטלי מאובטח ומוצפן ללא עלות.'
                          />
                          <br />

                          <div style={{ padding: '10px' }}>
                            <div className="checkbox_style_footer_update_details"
                              style={{ marginRight: "-10px" }}
                            >
                              <input
                                checked={this.state?.data?.lettrerApproved || false}
                                type="checkbox"
                                name="lettrerApproved"
                                id="lettrerApproved"
                                className="yalin-checkbox__input"
                                onChange={(ev) => {
                                  this.handleInput({
                                    target: { name: 'lettrerApproved', value: ev.target.checked },
                                  });
                                }}

                              />
                              <label htmlFor="lettrerApproved" className="yalin-checkbox__label">
                                צרפו אותי לדיוור ירוק
                              </label>
                            </div>
                            <label className="messageLabel" style={{ marginTop: '10px' }}>
                              *שירות דיוור דיגיטלי נמצא בהקמה ולפיכך יתכן שחלק מהמכתבים ימשיכו להישלח באמצעות הדואר. השירות לא יחול על הודעות שמחויבות בשליחה בדואר ע"פ הוראות הדין.
                            </label>
                          </div>
                        </div>


                        <div className="personal-data__block" style={{ paddingTop: '20px' }}>
                          <FormTitle
                            title={'רישום לשירותים'}
                          />
                          <br />
                          <div className="checkbox_style_footer_update_details"
                            style={{ marginRight: "-10px", marginTop: '2px' }}
                          >
                            <input
                              checked={this.state.data.mothlyRevNotice || false}
                              type="checkbox"
                              name="date"
                              id="date"
                              className="yalin-checkbox__input"
                              onChange={(ev) => {
                                console.log({ ev });
                                this.handleInput({
                                  target: { name: 'mothlyRevNotice', value: ev.target.checked },
                                });
                              }}
                            />
                            <label htmlFor="date" className="yalin-checkbox__label"
                            >
                              הודעת SMS עדכון יתרות חודשי
                            </label>
                          </div>

                          <div className="checkbox_style_footer_update_details"
                            style={{ marginRight: "-10px", marginTop: '2px' }}>
                            <input
                              type="checkbox"
                              name="getDepositConfirmation"
                              id="getDepositConfirmation"
                              className="yalin-checkbox__input"
                              checked={this.state.data.getDepositConfirmation || false}
                              onChange={(ev) => {
                                console.log({ ev });
                                this.handleInput({
                                  target: { name: 'getDepositConfirmation', value: ev.target.checked },
                                });
                              }} />
                            <label htmlFor="getDepositConfirmation" className="yalin-checkbox__label">
                              הודעת SMS עדכון הפקדות מעסיק

                            </label>
                          </div>

                        </div>
                        <br />
                      </div>
                  }

                  <div className="update-form__footer">
                    {this.state.isSubmitLoading ? (
                      <div style={{ textAlign: 'right', flex: '1', width: '100%' }}>
                        <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                      </div>
                    ) : (
                      <>
                        {
                          !this.state.isDisable &&

                          <div className="update-form__terms yalin-checkbox">

                            <div
                              className="checkbox_style_footer_update_details"

                            >
                              <input
                                type="checkbox"
                                name="nl-confirm"
                                required
                                id="nl-confirm"
                                className="yalin-checkbox__input"
                              />
                              <label htmlFor="nl-confirm" className="yalin-checkbox__label">
                                {this.label('update_personal_confirm')}
                                <a target="_blank" id="formTerm" href="#">
                                  תנאי השימוש
                                </a>
                              </label>
                            </div>

                            <div>
                            </div>
                          </div>
                        }


                        {
                          !this.state.isDisable &&
                          <div className='back_button_style'  >

                           

                            <div className="update-form__submit-container">
                              <button className="update-form__submit">
                                {UserDataService.isBlockedToAction
                                  ? this.label('update_personal_minor_button')
                                  : this.label('update_personal_button')}
                              </button>


                            </div>

                            <div className="update-form__submit-container back_container" >
                              <button className="update-form__submit back_button" onClick={this.backPopup}>
                                חזרה
                              </button>


                            </div>


                          </div>




                        }


                      </>
                    )}
                  </div>
                </div>
              </FormWithValidation>
            )}
          </div>
        </div>

        <CmsBanners banners={this.cmsContent && this.cmsContent.banners} />
        <style> {'.account-table-block__table-notice {display: block !important;}'} </style>
        <style> {'.discFont{ font-size:14px; font-family:"Almoni DL AAA", sans-serif; white-space:pre-wrap }'} </style>

        <style>
          {
            '@media (max-width:991px) { .dispbelow992{display:block !important; margin-top:30px; max-width:100%;font-size:10px} time{font-size:10px !important} }'
          }
        </style>
        <style>
          {
            '@media (min-width:991px) { .dispbelow992{display:none !important} .updatetxt{ margin-left:36px} } .account-table-block__table-notice{margin-top:0px} '
          }
        </style>
        {this.label('dashboard_bottomdisc') ? (
          <pre style={{ fontSize: '14px' }} className={'account-table-block__table-notice content-wrapper discFont'}>
            {this.label('dashboard_bottomdisc').replace('{date}', sessionStorage.getItem('updatedate'))}
          </pre>
        ) : null}
      </>
    );
  }
}
