import { BehaviorSubject, Subscription } from "rxjs";
import { LoginApi, UserDataApi } from "../api";
import { IGetUserDataResult } from "../api/UserData";
const SESSION_KEY = "user";

export interface UserModel {
  username: string;
  token: string;
}

export interface ReCAPTCHAResponse {
  token: string;
}

declare const grecaptcha: {
  ready: (onReady: () => void) => void;
  execute: (
    key: string,
    options: { action: string }
  ) => {
    then: (onSuccess: (token: string) => void) => void;
  };
};

const currentUserSubject = new BehaviorSubject<UserModel>(null);
let currentData: IGetUserDataResult = null;

const UserDataService = {
  login: async (username: string, password: string, personalId: string) => {
    const { token: gToken } = await UserDataService.getReCaptchaToken();

    const res = await LoginApi.login(username, password, personalId);
    return res;
    // success && UserDataService.setUser(username, token);

    // return { success, message };
  },

  remoteLogin: (token: string) => {
    return LoginApi.remoteLogin(token).then((res) => {
      if (res && res.success) {
        UserDataService.setUser(res.username, res.token);
        sessionStorage.setItem("zoomAnaliticsData", res.jsonData);
        sessionStorage.setItem("isGemelShowed",JSON.stringify(true));
        sessionStorage.setItem("hasLoan", JSON.stringify(res.hasLoan));
        sessionStorage.setItem("isFormsAuthorized", JSON.stringify(res.isFormsAuthorized));
        sessionStorage.setItem("isProd", JSON.stringify(res.isProd));
        sessionStorage.setItem('isDigitalLoanAuthorized',JSON.stringify(res.isDigitalLoanAuthorized))

      }
      return { success: res ? res.success : false };
    });
  },

  logout: () => {
    sessionStorage.removeItem(SESSION_KEY);
    sessionStorage.clear();
    currentUserSubject.next(null);
  },

  renew: () => {
    if (UserDataService.isLoggedIn) {
      UserDataApi.renew()
        .then((res) => {
          if (res.success) {
            UserDataService.setUser(
              UserDataService.currentUser.username,
              res.token
            );
          }
        })
        .catch((reason) => UserDataService.logout());
      return true;
    }
    return false;
  },

  setUser: (username: string, token: string) => {
    const user: UserModel = {
      username: username,
      token: token,
    };
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(user));
    currentUserSubject.next(user);
  },

  get isLoggedIn() {
    return sessionStorage.getItem(SESSION_KEY) != null;
  },

  isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },

  get currentUser(): UserModel {
    if (this.isJsonString(sessionStorage.getItem(SESSION_KEY))) {
      return sessionStorage.getItem(SESSION_KEY) != null
        ? JSON.parse(sessionStorage.getItem(SESSION_KEY))
        : null;
    } else {
      UserDataService.logout();
    }
  },

  get isUserMailingPopupNeeded() {
    if (
      sessionStorage.getItem("isUserMailingPopupNeeded") === undefined ||
      sessionStorage.getItem("isUserMailingPopupNeeded") === "undefined"
    )
      return null;
    return (
      JSON.parse(sessionStorage.getItem("isUserMailingPopupNeeded")) || null
    );
  },

  get isBlockedToAction() {
    if (
      sessionStorage.getItem("isBlockedToAction") === undefined ||
      sessionStorage.getItem("isBlockedToAction") === "undefined"
    )
      return null;
    return JSON.parse(sessionStorage.getItem("isBlockedToAction")) || null;
  },

  listen: (next: (value: UserModel) => void) =>
    currentUserSubject.subscribe(next),

  stopListen: (listener: Subscription) => {
    listener.unsubscribe();
  },

  updateData: () => {
    return UserDataApi.get().then((x) => {
      if (x === null || x.status === 401) {
        UserDataService.logout();
        sessionStorage.removeItem("returnUrl");
      }
      const isMailingPopupOn = JSON.parse(
        sessionStorage.getItem("isMailingPopupOn")
      );
      const isUserMailingPopupNeeded =
        x.isUserMailingPopupNeeded && isMailingPopupOn;
      sessionStorage.setItem(
        "isUserMailingPopupNeeded",
        JSON.stringify(isUserMailingPopupNeeded)
      );
      sessionStorage.setItem(
        "isBlockedToAction",
        JSON.stringify(x.isBlockedToAction)
      );
      currentData = x;
    });
  },

  get data() {
    return currentData;
  },

  getReCaptchaToken: () =>
    new Promise<ReCAPTCHAResponse>((resolve, reject) => {
      grecaptcha.ready(function () {
        grecaptcha
          .execute((window as any).recaptchaKey as string, {
            action: "homepage",
          })
          .then((res) => resolve({ token: res } as ReCAPTCHAResponse));
      });
    }),
};

currentUserSubject.next(UserDataService.currentUser);

export default UserDataService;
