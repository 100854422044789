import React from 'react';
import { BaseComponent } from '..';
import { AccountStatusApi } from '../../api';
import { IManagementFeesResult } from '../../api/AccountStatus';

export default class ManagementFees extends BaseComponent<{ accountId: string }, { items?: IManagementFeesResult[], isLoading?: boolean }> {

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps: Readonly<{ accountId: string }>) {
        if (this.props.accountId !== prevProps.accountId) {
            this.fetchData();
        }
    }

    fetchData() {

        this.setState({
            isLoading: true
        })

        AccountStatusApi.getManagementFees(this.props.accountId).then(res =>
            this.setState({
                isLoading: false,
                items: res
            }));
    }

    render() {
        return <div className="pane-content pane-content--management">

            {!this.state ? '' : this.state.isLoading
                ?
                <div style={{ textAlign: 'center' }}>
                    <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                </div>
                :
                <section className="data-section data-section--management">
                    <div className="data-section__header data-section__header--management">
                        <h3 className="data-section__title">דמי ניהול</h3>
                    </div>
                    <div className="management-table-block">
                        <div className="management-table">
                            <div className="thead">
                                <div className="tr">
                                    <div className="th">סוג</div>
                                    <div className="th">אחוז דמי ניהול</div>
                                    <div className="th">תאריך תחילת תוקף</div>
                                    <div className="th">תאריך סיום תוקף</div>
                                </div>
                            </div>
                            <div className="tbody">
                                {this.state && this.state.items && this.state.items.map((row, index) => <div className="tr" key={this.props.accountId + '_' + index}>
                                    <div className="td">{row.type}</div>
                                    <div className="td number">{row.percentage}</div>
                                    <div className="td number">{row.dateStart}</div>
                                    <div className="td number">{row.dateEnd}</div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </section>
            }
        </div>;
    }
}
