import { BaseSecuredApi } from "./BaseSecuredApi";

export interface IManagementFeesResult {
    type: string,
    percentage: string,
    dateStart: string
    dateEnd: string
}

export interface IAccountStatus {
    courseBalance: CourseBalance[];
    remainingAssets: Fees;
    moneyDeposited: Fees;
    moneyTransferedTo: Fees;
    moneyWithdrawn: Fees;
    moneyTransferedFrom: Fees;
    profits: Fees;
    fees: Fees;
    clearingFeeRefund: Fees;
    total: Fees;
}

interface CourseBalance {
    courseName: string;
    accStatus: Fees;
}

interface Fees {
    customer?: number;
    employer?: number;
    comp?: number;
    total?: number;
}


export interface IFundDetails {
    joinDate: string;
    seniority: string;
    status: string;
    employer: string;
    employerId: string;
    agent: string;
    agentDetails: string;
    balanceFee?: number;
    depositFee?: number;
    singleWithdraw?: number;
    saveFee?: number;
    averageDepositFee?: number;
    averageAssetFee?: number;
    allowance?: number;
    risk?: number;
    workLoss?: number;
    returnDate?: string;
    treasureNumbers: number[];
    courseReturn: CourseReturn[];
}

export interface CourseReturn {
    courseName: string;
    treasureNo: number;
    return?: number;
}

export interface IGetTransactionsRequest {
    pageNumber: number;
    year: string;
    type: string;
    fromDate?: string;
    toDate?: string;
    revaluation: boolean;
    accountNumber: string;
}
export interface IGetTransactionsResponse {
    totalPages: number;
    transactions: ITransaction[];
}

interface ITransaction {
    type: string;
    date: string;
    month: number;
    salary: number;
    customer: number;
    employer: number;
    comp: number;
    total: number;
    revaluation: boolean;
}

class AccountStatus extends BaseSecuredApi {

    constructor() {
        super("AccountStatus");
    }

    getManagementFees(accountId: string) {
        return this.POST<IManagementFeesResult[]>({
            action: "getManagementFees",
            data: { 
                accountId
            },
            cache: true, 
            cacheKey: accountId
        });
    }
    getStatus(accountId: string, year: string) {
        return this.POST<IAccountStatus>({
            action: "getStatus", 
            data: { 
                accountId, 
                year: year 
            },
            cache: true,
            cacheKey: (accountId + '_' + year)
        });
    }
    getFundDetails(accountId: string) {
        return this.POST<IFundDetails>({
            action: "getFundDetails", 
            data: { 
                accountId
            },
            cache: true,
            cacheKey: accountId
        });
    }
    getTransactions(data: IGetTransactionsRequest) {
        return this.POST<IGetTransactionsResponse>({
            action: "getTransactions", 
            data
        });
    }



}

export const AccountStatusApi = new AccountStatus();