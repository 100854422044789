import React from 'react';
import { BaseComponent } from '..';
import Header from './Header';
import Status from './Status'
import FundDetails from './FundDetails';
import Liquidity from './Liquidity';
import ManagementFees from './ManagementFees';
import { Tab, Nav } from 'react-bootstrap';
import Transactions from './Transactions';
import { CmsBanners } from '../Macros';
import { UserDataService } from '../../services';

export default class AccountStatus extends BaseComponent<{ match: any }, { routes: Array<string>, accountId: string}> {

    cmsPageAlias = 'accountstatus';

    constructor(props: Readonly<{ match: any }>) {
        super(props);
        const accountId = this.props.match.params.id
        this.state = {
            routes: this.getRoutes(accountId),
            accountId: accountId
 
        };
    }

    onAccountSelected(newAccount: string) {
        
        this.setState({
            routes: this.getRoutes(newAccount),
            accountId: newAccount
        });

    }

    getRoutes(accountId: string) {
        const id = parseInt(accountId);
        return UserDataService.data.accounts.find(x => x.id === id).courses;
    }

    render() {
        return <>

            <Header accountId={this.state.accountId} routes={this.state.routes} onAccountSelected={this.onAccountSelected.bind(this)} />

            <div className="content-wrapper content-wrapper--sheet">
                <div className="content-sheet">
                    <div className="account-data">

                        <Tab.Container id="account-tab" defaultActiveKey="status">

                            <div className="account-data__header">
                                <div className="account-data__date-col">
                                    {this.label("balances_date")} <time dateTime="2019-03-11" className="number">{sessionStorage.getItem('updatedate')}</time>
                                </div>


                                <Nav variant={null} className="nav nav-tabs account-data__nav-tabs">
                                    <Nav.Item className="nav-item account-data__nav-item">
                                        <Nav.Link eventKey="status" className="nav-link account-data__nav-link">{this.label("account_accStatus")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item account-data__nav-item">
                                        <Nav.Link eventKey="fundDetails" className="nav-link account-data__nav-link">{this.label("account_details")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item account-data__nav-item">
                                        <Nav.Link eventKey="transactions" className="nav-link account-data__nav-link">{this.label("account_movements")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item account-data__nav-item">
                                        <Nav.Link eventKey="liquidity" className="nav-link account-data__nav-link d-none">{this.label("account_liquid")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item account-data__nav-item">
                                        <Nav.Link eventKey="management" className="nav-link account-data__nav-link d-none">{this.label("account_fee")}</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                            </div>

                            <Tab.Content className="tab-content account-data__tabs-content">
                                <Tab.Pane eventKey="status">
                                    <Status accountId={this.state.accountId} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="fundDetails">
                                    <FundDetails accountId={this.state.accountId} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="transactions">
                                    <Transactions accountId={this.state.accountId} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="liquidity">
                                    <Liquidity />
                                </Tab.Pane>
                                <Tab.Pane eventKey="management">
                                    <ManagementFees accountId={this.state.accountId} />
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>
                    </div>
                </div>
            </div>

            <CmsBanners banners={this.cmsContent && this.cmsContent.banners} />
        </>;
    }
}
