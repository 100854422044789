import config from "../config";

export interface ICmsBanner {
  label: string;
  image: string;
  link: string;
}

export interface ICmsPage {
  title: string;
  subtitle: string;
  intro: string;
  banners: ICmsBanner[];
  resourceslist?: { [key: string]: string }[];
}

export interface ICmsContent {
  backgroundImage: string;
  sideMenu: { banners: ICmsBanner[]; items: string[] };
  pages: { [id: string]: ICmsPage } | any;
  labels: { [key: string]: string };
  resources: { [key: string]: string };
}

class Cms {
  protected static getUrl = (controller: string, action: string) =>
    `${config.API_URL}/api/${controller}/${action}`;

  protected async GET<TRequest, TResponse>(action: string, data: TRequest) {
    const response = await fetch(
      Cms.getUrl("Cms", action) +
        "?" +
        Object.keys(data)
          .map((key) => {
            return (
              encodeURIComponent(key) +
              "=" +
              encodeURIComponent((data as any)[key])
            );
          })
          .join("&"),
      {}
    );
    return (await response.json()) as Promise<TResponse>;
  }

  content() {
    return this.GET<{}, ICmsContent>("content", {});
  }
}

export const CmsApi = new Cms();
