import React from 'react';
import { BaseComponent } from '..';
import { Redirect } from 'react-router';
import { FormWithValidation } from '../Macros';
import { Link } from 'react-router-dom';
import { LoginApi } from '../../api';
import { ErrorType } from '../Macros/ErrorType';
import { CmsService } from '../../services';

interface IState {
  personalId: string;
  dateofbirth: string;
  accnum: string;
  isSending: boolean;
  redirectTo: string;
  username: string;
  error: string;
}

export default class ForgotUsername extends BaseComponent<{}, IState> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      personalId: null,
      dateofbirth: null,
      accnum: null,
      isSending: false,
      redirectTo: null,
      username: null,
      error: '',
    };
  }

  componentDidMount() {
    CmsService.updateContent();
    let addSlash = true;
    $('#dateofbirth').on('keyup', function (e) {
      let val = $(this).val().toString();
      //if (val == 0)
      addSlash = true;
      if (e.which == 111) addSlash = false;
      if (addSlash) {
        if (e.which != 8 && e.which != 46 && (val.length == 2 || val.length == 5)) {
          val += '/';
          $(this).val(val);
        }
      }
    });
  }


  rules = [
    {
      input: 'personal-id',
      validators: ['personalId'],
    },
    {
      input: 'dateofbirth',
      validators: ['date'],
    },
    {
      input: 'accnum',
      validators: ['numericonly'],
    },
  ];

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    this.setState({
      isSending: true,
    });
    this.setState({ error: '' });
    const dob = this.state.dateofbirth.split('/');
    const d = dob[2] + '-' + dob[1] + '-' + dob[0];
    LoginApi.sendGetUsername(this.state.personalId, d, this.state.accnum)
      .then((res) => {
        if (res && res.data) {
          this.setState({
            username: res.data,
          });
        } else {
          this.setState({ error: ErrorType.getErrorMsg(res.statusCode) });
        }
      })
      .catch((err) => {
        this.setState({ error: ErrorType.getErrorMsg(err.status) });
      })
      .finally(() => {
        this.setState({
          isSending: false,
        });
      });
    event.preventDefault();
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}></Redirect>;
    }

    return (
      <div className="forgot-password-container">
        <style>
          {
            '@media (max-width:991px) { .login-form__submit-container{margin-top:0px !important} .login-wrapper{padding-top:0px} .login-form__field {font-size:16px} } .login-form__submit{margin: 0 0 15px 0; text-align:center}  '
          }
        </style>

        <h4 className="login-block__heading">שכחתי שם משתמש</h4>
        <h5 className="login-block__subheading">יש להזין את הפרטים הבאים על מנת שנוכל להציג לך את שם המשתמש</h5>

        {this.state.username ? (
          <div style={{ textAlign: 'center', height: '255px' }}>
            <span className="login-block__subheading" style={{ fontSize: '16px' }}>
              שם המשתמש שלך הוא:
            </span>
            <span className="login-block__subheading" style={{ fontSize: '20px' }}>
              {this.state.username}
            </span>
            <div className="login-form__footer" style={{ position: 'relative', top: '175px' }}>
              <div className="login-form__submit-container">
                <Link to="/customers/login" style={{ float: 'left' }} className="login-form__submit">
                  חזור למסך כניסה
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <FormWithValidation rules={this.rules} onSubmit={this.handleSubmit.bind(this)} className="login-form">
            <div className="login-form__body">
              <div className="login-form__form-group">
                <label htmlFor="personal-id" className="login-form__label sr-only">
                  תעודת זהות
                </label>
                <input
                  type="number"
                  inputMode="numeric"
                  pattern="\d*"
                  name="personal-id"
                  id="personal-id"
                  placeholder="תעודת זהות"
                  required
                  className="login-form__field"
                  onChange={(e) => this.setState({ personalId: e.target.value })}
                />
              </div>

              <div className="login-form__form-group">
                <label htmlFor="dateofbirth" className="login-form__label sr-only">
                  תאריך לידה
                </label>
                <input
                  type="text"
                  name="dateofbirth"
                  id="dateofbirth"
                  placeholder="תאריך לידה"
                  required
                  className="login-form__field"
                  onChange={(e) => this.setState({ dateofbirth: e.target.value })}
                />
              </div>

              <div className="login-form__form-group">
                <label htmlFor="accnum" className="login-form__label sr-only">
                  מספר חשבון
                </label>
                <input
                  type="number"
                  inputMode="numeric"
                  pattern="\d*"
                  name="accnum"
                  id="accnum"
                  placeholder="מספר חשבון"
                  required
                  className="login-form__field"
                  onChange={(e) => this.setState({ accnum: e.target.value })}
                />
              </div>
            </div>
            {this.state.error && <span className="error">{this.state.error}</span>}
            <div className="login-form__footer">
              {this.state.isSending ? (
                <div style={{ textAlign: 'center', flex: '1' }}>
                  <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                </div>
              ) : (
                <div className="login-form__submit-container">
                  <button
                    type="submit"
                    style={{ float: 'left' }}
                    className="login-form__submit"
                    disabled={this.state.isSending}
                  >
                    המשך
                  </button>
                  <Link
                    to="/customers/login"
                    style={{ float: 'right', marginLeft: '67px' }}
                    className="login-form__submit"
                  >
                    חזור למסך כניסה
                  </Link>
                </div>
              )}
            </div>
          </FormWithValidation>
        )}
      </div>
    );
  }
}
