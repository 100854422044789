import { BaseSecuredApi } from './BaseSecuredApi';

export interface IGetRequest {
  year: number;
  fundID: number;
  pageSize: number;
  pageNo: number;
}

export interface IExportResult {
  downloadUrl: string;
}

export interface IGetResponse {
  totalPages?: number;
  reports: IReport[];
}

export interface IReport {
  reportID: number;
  fundName: string;
  period: number;
  type: string;
}

class TaxReports extends BaseSecuredApi {
  constructor() {
    super('Reports');
  }

  get(data: IGetRequest) {
    return this.POST<IReport[]>({
      action: 'tax/list',
      data,
    });
  }
  exportReportsPdf(reportId: string) {
    return this.POST<IExportResult>({
      action: 'downloadPdf',
      data: { reportId },
    }).then((res) => {
      if (res.downloadUrl) {
        res.downloadUrl = BaseSecuredApi.baseApiUrl + res.downloadUrl;
        return res;
      }
    });
  }
}

export const TaxReportsApi = new TaxReports();
