import React from 'react';
import { BaseComponent } from '..';
import { AccountStatusApi } from '../../api';
import { IFundDetails } from '../../api/AccountStatus';

interface IProps {
    accountId: string
}

interface IState {
    details: IFundDetails,
    isLoading: boolean,
    comment:string
}

export default class FundDetails extends BaseComponent<IProps, IState> {

    constructor(props: Readonly<IProps>) {
        super(props);
        this.state = {
            details: null,
            isLoading: true,
            comment: this.label("account_detailsdisc").replace('{date}', sessionStorage.getItem('updatedate'))
        };
        this.fetchData();
    }

    fetchData() {
        AccountStatusApi.getFundDetails(this.props.accountId).then(res => this.setState({
            isLoading: false,
            details: res
        }));
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.accountId !== prevProps.accountId) {
            this.setState({
                isLoading: true
            });
            this.fetchData();
        }
    }

    render() {
        return <div className="pane-content pane-content--fund">
            {!this.state || this.state.isLoading
                ?
                <div style={{ textAlign: 'center' }}>
                    <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                </div>
                :
                this.state.details && <>
                    <section className="data-section data-section--fund">
                        <div className="data-section__header data-section__header--data data-section__header--fund">
                            <h3 className="data-section__title">{this.label("account_DetailsTitle")}</h3>
                        </div>
                        <div className="details-table-block">
                            <table className="details-table">
                                <tbody>
                                    <tr>
                                        <th>{this.label("account_joinDate")}</th>
                                        <td><span className="number">{this.state.details.joinDate}</span></td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_seniority")}</th>
                                        <td><span className="number">{this.state.details.seniority}</span></td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_status")}</th>
                                        <td>{this.state.details.status}</td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_employer")}</th>
                                        <td>{this.state.details.employer}</td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_employerID")}</th>
                                        <td><span className="number">{this.state.details.employerId}</span></td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_agent")}</th>
                                        <td>{this.state.details.agent}</td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_agentContact")}</th>
                                        <td><span className="number">{this.state.details.agentDetails}</span></td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_treasureNo")}</th>
                                        <td>
                                            {this.state.details.treasureNumbers.map(x => <div key={x}><span className="number">{x}</span></div>)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section className="data-section data-section--fund">
                        <div className="data-section__header data-section__header--data">
                            <h3 className="data-section__title">{this.label("account_feeTitle")}</h3>
                        </div>
                        <div className="details-table-block">
                            <table className="details-table">
                                <tbody>
                                    <tr>
                                        <th>{this.label("account_saveFee")}</th>
                                        <td><span className="number">{this.state.details.saveFee}%</span></td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("accout_depositFee")}</th>
                                        <td><span className="number">{this.state.details.depositFee}%</span></td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_avgAssetFee")}</th>
                                        <td><span className="number">{this.state.details.averageAssetFee}%</span></td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_avgDepositFee")}</th>
                                        <td><span className="number">{this.state.details.averageDepositFee}%</span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <span>לנוחיותך <a target="_blank"  href="https://online.yl-invest.co.il/forms/calculator"> מחשבון</a> שיסייע לך לחישוב שיעור העלות השנתית הצפויה</span>

{/* //<span>\nידוע לי כי ניתן לקבל מידע בדבר העלות השנתית הצפויה ביחס לחשבונותיי, באמצעות  שבאתר האינטרנט של החברה\n</span> */}

{/* <a >המחשבון</a> */}
                            
                        </div>
                    </section>
                    <section className="data-section data-section--fund">
                        <div className="data-section__header data-section__header--data">
                            <h3 className="data-section__title">תשלומים צפויים מקופת גמל</h3>
                        </div>
                        <div className="details-table-block">
                            <table className="details-table">
                                <tbody>
                                    <tr>
                                        <th>{this.label("account_allowance")}</th>
                                        <td><span className="number">{this.state.details.allowance && this.state.details.allowance.toLocaleString()}</span></td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_SingleWithdraw")}</th>
                                        <td><span className="number">{this.state.details.singleWithdraw.toLocaleString()}</span></td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_risk")}</th>
                                        <td><span className="number">{this.state.details.risk && this.state.details.risk.toLocaleString()}</span></td>
                                    </tr>
                                    <tr>
                                        <th>{this.label("account_workLoss")}</th>
                                        <td><span className="number">{this.state.details.workLoss && this.state.details.workLoss.toLocaleString()}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section className="data-section data-section--fund">
                        <div className="data-section__header data-section__header--data data-section__header--abs">
                            <div className="row data-section__header-row">
                                <div className="col data-section__header-col">
                                    <h3 className="data-section__title">{this.label("account_returnTitle")}</h3>
                                    <p className="data-section__lead">{this.label("account_return_desc")}</p>
                                </div>
                                {this.state.details && this.state.details.returnDate && <div className="col-lg-auto data-section__date-col">
                                    <p className="info-check-date">{this.label("account_returnDate")}<time dateTime={this.state.details.returnDate} className="number">{this.state.details.returnDate}</time></p>
                                </div>}
                            </div>
                        </div>
                        <div className="details-table-block">
                            <table className="details-table">
                                <tbody>
                                    {this.state.details.courseReturn.map((x, i) => <tr key={i}>
                                        <th>{x.courseName}</th>
                                        <td><span className="number">{x.return}%</span></td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                        <br />
                    </section>
                </>
            }
            <style> {'.discFont{ font-size:14px; font-family:"Almoni DL AAA", sans-serif; white-space:pre-wrap }'} </style>
            {this.state.comment ? <pre style={{ fontSize: '14px' }} className={"account-table-block__table-notice discFont"} >{this.state.comment}</pre> : null}
        </div>;
    }
}
