import React from 'react';
import $ from 'jquery';

export const PopupService = {
  show: function ( title: string, text: string, buttons: { caption: string; onClick?: () => void }[], hide: boolean = true, isScrollText: boolean = false, fixBackgroundStyle:boolean =false , birthPopup:boolean =false) {
    
    const container = $('#popup-cotainer');
    container.find('.title').html(title);
    container.find('.text').html(text);

    
    if (isScrollText) {
      container.find('.generic-popup-container').addClass('scroll-generic-popup-container');
      container.find('.text').addClass('scroll-text');
    } else {
      container.find('.generic-popup-container').removeClass('scroll-generic-popup-container');
      container.find('.text').removeClass('scroll-text');
    }

    if(fixBackgroundStyle){
      container.find('.generic-popup-container').addClass('remove-background');
      container.find('.buttons-container').addClass('remove-color');
      container.find('.text').addClass('image-background-size');
    }else{
      container.find('.generic-popup-container').removeClass('remove-background');
      container.find('.buttons-container').removeClass('remove-color');
      container.find('.text').removeClass('image-background-size');



    }

    if(birthPopup){
      container.find('.generic-popup-container').addClass('remove-background-birth');
      container.find('.buttons-container').addClass('remove-color-birth');
      container.find('.text').addClass('image-background-birth-popup');
      container.find('.close-icon').addClass('show-close-button');

    }else{
      container.find('.generic-popup-container').removeClass('remove-background-birth');
      container.find('.buttons-container').removeClass('remove-color-birth');
      container.find('.text').removeClass('image-background-birth-popup');      
      container.find('.close-icon').removeClass('show-close-button');
    }


    const buttonContainer = container.find('.buttons-container').empty();
    buttons.forEach((button, index) => {
      const buttonDom = $('<button class="' + (index === 0 ? 'confirm_btn' : 'secondary_btn') + '">' + button.caption + '</button>');
      if (hide) {
        buttonDom.click(PopupService.hide);
      }
      if (button.onClick) {
        buttonDom.click(button.onClick);
      }
      buttonContainer.append(buttonDom);
    });
    container.css('display', 'flex');
  },
  alert: function (title: string, text: string, onConfirm: () => void, confirmCaption?: string) {
    PopupService.show(title, text, [
      {
        caption: confirmCaption || 'אישור',
        onClick: onConfirm
      }
    ]);
  },
  confirm: function (title: string, text: string, onConfirm: () => void) {
    PopupService.show(title, text, [
      {
        caption: 'אישור',
        onClick: onConfirm
      },
      {
        caption: 'ביטול'
      }
    ]);
  },
  hide: function () {
    $('#popup-cotainer').hide();
  }
};

export default class PopupBackground extends React.Component<{}, {}> {
  render = () => (
    <div className="generic-popup-background" id="popup-cotainer">
      <div className={`generic-popup-container`}>
        <div onClick={() => PopupService.hide()} className="close-icon">
          <div className='vertical-line rotate' />
          <div className="horizontal-line" />
        </div>
        <div className="title"></div>
        <div className="text"></div>
        <div className="buttons-container">
          <button className="confirm_btn"></button>
          <button className="secondary_btn"></button>
        </div>
      </div>
    </div>
  );
}
