import React from 'react';
import { BaseComponent } from '..';
import { TopDucks, CmsBanners } from '../Macros';
import { UserDataService } from '../../services';
import { RequestsStatusApi } from '../../api';
import { IGetRequest, IRequest } from '../../api/RequestsStatus';
import CustomSelect, { ICustomSelectItem } from '../Macros/CustomSelect';
import ResponsiveTable, { IResponsiveTableRow } from '../Macros/ResponsiveTable';
import { Collapse } from 'react-bootstrap';
import moment from 'moment';

interface IRequestsState {
  currentPage: number;

  selectedYear: string;
  years: ICustomSelectItem[];

  selectedType: string;
  types: any[];

  selectedAccount: string;
  accounts: ICustomSelectItem[];

  selectedDateFrom: string;
  selectedDateTo: string;

  requests?: IRequest[];
  isLoading: boolean;
  isDropDownOpen: boolean;
  currReq: any;
}

declare const $: any;

export default class RequestsStatus extends BaseComponent<{}, IRequestsState> {
  cmsPageAlias = 'requestsstatus';

  constructor(props: Readonly<{}>) {
    super(props);

    const accounts = UserDataService.data.accounts.map((x) => ({
      label: x.id.toString(),
      value: x.id.toString(),
    }));
    accounts.splice(0, 0, { label: 'כל החשבונות', value: null });

    this.state = {
      currentPage: 1,
      selectedYear: new Date().getFullYear().toString(),
      selectedDateFrom: null,
      selectedDateTo: null,
      selectedType: null,
      selectedAccount: null,

      years: [new Date().getFullYear(), new Date().getFullYear() - 1, new Date().getFullYear() - 2].map(
        (x) => ({ label: x.toString(), value: x.toString() } as ICustomSelectItem)
      ),

      types: ['כל הסוגים', 'העברה נכנסת לחשבון', 'העברה יוצאת מהחשבון', 'העברה בין מסלולים', 'משיכה'].map(
        (label, index) =>
          ({
            label,
            value: !index ? null : index.toString(),
          } as ICustomSelectItem)
      ),

      accounts,
      isLoading: false,
      currReq: null,
      isDropDownOpen: false,
    };
  }

  isMobile = () => {
    return document.documentElement.clientWidth < 992;
  };

  componentDidMount() {
    this.fetchData();
  }

  resetForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      selectedYear: new Date().getFullYear().toString(),
      selectedType: null,
      selectedAccount: null,
    });

    event.preventDefault();
  }

  submitForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.fetchData();
    event.preventDefault();
  }

  fetchData() {
    const types = ['', ['21', '1'], ['22'], ['23', '8'], ['24', '6']];
    const indexSelected = parseInt(this.state.selectedType);
    const requestTypes = !this.state.selectedType ? null : types[indexSelected];
    const data = {
      pageNo: this.state.currentPage,
      pageSize: 20,
      requestTypes,
      accountId: this.state.selectedAccount,
      fromDate: null,
      toDate: null,
    } as IGetRequest;

    this.setState({
      isLoading: true,
      currentPage: 1,
    });
    RequestsStatusApi.get(data)
      .then((x) => {
        this.setState({
          isLoading: false,
          requests: x.data.length ? x.data : null,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  getReqFiles(fileId: number, index: number) {
    if (!fileId) return;
    const requests = this.state.requests;
    requests[index].isLoading = true;
    this.setState({ requests });
    RequestsStatusApi.getFilesNames(fileId).then((files) => {
      requests[index].isLoading = false;
      if (files.status === 500) {
        requests[index].files = null;
      } else {
        requests[index].files = files;
      }
      this.setState({ requests });
    });
  }

  onDownloadFiles(files: any, index: number) {
    const requests = [...this.state.requests];
    requests[index].isFileLoading = true;
    this.setState({ requests });
    for (let i = 0; i < files.length; i++) {
      const currFile = files[i];
      this.onDownloadFile(currFile);
    }
    requests[index].isFileLoading = false;
    this.setState({ requests });
  }

  onDownloadFile(currFile: any) {
    RequestsStatusApi.DownloadFile(currFile).then((fileStream) => {
      if (window.navigator.msSaveOrOpenBlob) {
        const blobObject = new Blob([fileStream], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blobObject, currFile.fileName);
      } else {
        let file = new Blob([fileStream], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        let link = document.createElement('a');
        link.href = fileURL;
        link.download = currFile.fileName;
        link.click();
        window.open(fileURL, '_blank');
      }
    });
  }

  onToggleFileList = (index: number) => {
    const requests = [...this.state.requests];
    if (requests[index]) {
      requests[index].isFileListOpen = !requests[index].isFileListOpen;
      this.setState({ requests });
    }
  };

  FileButton(request: IRequest, index?: number) {
    return (
      <div className="button-container">
        {request.isFileLoading ? (
          <div style={{ textAlign: 'center' }}>
            <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
          </div>
        ) : (
          // document.documentElement.clientWidth > 992 ?

          //     <button onClick={() => this.onDownloadFiles(request.files, index)} className="files-btn">הורד קבצים</button> :
          <>
            <button
              onClick={() => {
                this.setState({ isDropDownOpen: !this.state.isDropDownOpen });
                this.onToggleFileList(index);
              }}
              className="files-btn"
            >
              הורד קבצים
            </button>
            <div className={`docs-items ${this.state.isDropDownOpen || request.isFileListOpen ? '' : 'hide'}`}>
              {request.files &&
                request.files.map((file) => (
                  <div className="item" key={file.fileName} onClick={() => this.onDownloadFile(file)}>
                    {file.fileName}
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    );
  }
  MobileFileButton(request: IRequest, index?: number) {
    return (
      <div className="button-container">
        {request.isFileLoading ? (
          <div style={{ textAlign: 'center' }}>
            <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
          </div>
        ) : (
          <>
            <button
              onClick={() => {
                this.setState({ isDropDownOpen: !this.state.isDropDownOpen });
                this.onToggleFileList(index);
                if (request.fileId && !request.files) this.getReqFiles(request.fileId, index);
              }}
              className="files-btn"
            >
              הורד קבצים
            </button>
            <div className={`docs-items ${this.state.isDropDownOpen || request.isFileListOpen ? '' : 'hide'}`}>
              {request.files &&
                request.files.map((file) => (
                  <div className="item" key={file.fileName} onClick={() => this.onDownloadFile(file)}>
                    {file.fileName}
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    );
  }

  getCollapseInfo(request: IRequest, index?: number) {
    switch (request.requestTypeID) {
      case 1:
      case 21:
        return (
          <Collapse in={request.isOpen}>
            <div className="collapsible-list-container">
              {request.isLoading && !this.isMobile() ? (
                <div style={{ textAlign: 'center' }}>
                  <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                </div>
              ) : (
                <div>
                  {!this.isMobile() && request.fileId && this.FileButton(request, index)}
                  <ul className="collapsible-list">
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">סטטוס בקשה</span>
                      <span className="collapsible-list-item-value">{request.statusRequest}</span>
                    </li>
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">מספר חשבון מעביר</span>
                      <span className="collapsible-list-item-value number">{request.accountNumberTransfer}</span>
                    </li>
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">שם קופה מעבירה</span>
                      <span className="collapsible-list-item-value">{request.transferName}</span>
                    </li>
                    {request.faultDescription && (
                      <li className="collapsible-list-item">
                        <span className="collapsible-list-item-title dangerous">ליקוי מהגוף המעביר</span>
                        <span className="collapsible-list-item-value">{request.faultDescription}</span>
                      </li>
                    )}
                  </ul>
                  {this.isMobile() && request.fileId && this.MobileFileButton(request, index)}
                </div>
              )}
            </div>
          </Collapse>
        );
      case 22:
        return (
          <Collapse in={request.isOpen}>
            <div className="collapsible-list-container">
              {request.isLoading && !this.isMobile() ? (
                <div style={{ textAlign: 'center' }}>
                  <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                </div>
              ) : (
                <div>
                  {!this.isMobile() && request.fileId && this.FileButton(request, index)}
                  <ul className="collapsible-list">
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">סטטוס בקשה</span>
                      <span className="collapsible-list-item-value">{request.statusRequest}</span>
                    </li>
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">מספר חשבון מקבל</span>
                      <span className="collapsible-list-item-value number">{request.accountNumberAccepts}</span>
                    </li>
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">שם קופה מקבלת</span>
                      <span className="collapsible-list-item-value">{request.nameAccepts}</span>
                    </li>
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">תאריך צפי יציאת כספים</span>
                      <span className="collapsible-list-item-value number">
                        {request.expectedDate && moment(request.expectedDate).format('DD/MM/YYYY')}
                      </span>
                    </li>
                    {request.faultDescription && (
                      <li className="collapsible-list-item">
                        <span className="collapsible-list-item-title dangerous">ליקוי בטפסים</span>
                        <span className="collapsible-list-item-value">{request.faultDescription}</span>
                      </li>
                    )}
                  </ul>
                  {this.isMobile() && request.fileId && this.MobileFileButton(request, index)}
                </div>
              )}
            </div>
          </Collapse>
        );
      case 8:
      case 23:
        return (
          <Collapse in={request.isOpen}>
            <div className="collapsible-list-container">
              {request.isLoading && !this.isMobile() ? (
                <div style={{ textAlign: 'center' }}>
                  <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                </div>
              ) : (
                <div>
                  {!this.isMobile() && request.fileId && this.FileButton(request, index)}
                  <ul className="collapsible-list">
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">סטטוס בקשה</span>
                      <span className="collapsible-list-item-value ">{request.statusRequest}</span>
                    </li>
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">שם מסלול מעביר</span>
                      <span className="collapsible-list-item-value ">{request.transferName}</span>
                    </li>
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">שם מסלול מקבל</span>
                      <span className="collapsible-list-item-value ">{request.nameAccepts}</span>
                    </li>
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">תאריך צפי ביצוע הבקשה</span>
                      <span className="collapsible-list-item-value number">
                        {request.expectedDate && moment(request.expectedDate).format('DD/MM/YYYY')}
                      </span>
                    </li>
                    {request.faultDescription && (
                      <li className="collapsible-list-item">
                        <span className="collapsible-list-item-title dangerous">ליקוי בטפסים</span>
                        <span className="collapsible-list-item-value">{request.faultDescription}</span>
                      </li>
                    )}
                  </ul>
                  {this.isMobile() && request.fileId && this.MobileFileButton(request, index)}
                </div>
              )}
            </div>
          </Collapse>
        );
      case 6:
      case 24:
        return (
          <Collapse in={request.isOpen}>
            <div className="collapsible-list-container">
              {request.isLoading && !this.isMobile() ? (
                <div style={{ textAlign: 'center' }}>
                  <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                </div>
              ) : (
                <div>
                  {!this.isMobile() && request.fileId && this.FileButton(request, index)}
                  <ul className="collapsible-list">
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">סטטוס בקשה</span>
                      <span className="collapsible-list-item-value ">{request.statusRequest}</span>
                    </li>
                    <li className="collapsible-list-item">
                      <span className="collapsible-list-item-title">תאריך צפי ביצוע הבקשה</span>
                      <span className="collapsible-list-item-value number">
                        {request.expectedDate && moment(request.expectedDate).format('DD/MM/YYYY')}
                      </span>
                    </li>
                    {request.faultDescription && (
                      <li className="collapsible-list-item">
                        <span className="collapsible-list-item-title dangerous">ליקוי בטפסים</span>
                        <span className="collapsible-list-item-value">{request.faultDescription}</span>
                      </li>
                    )}
                  </ul>
                  {this.isMobile() && request.fileId && this.MobileFileButton(request, index)}
                </div>
              )}
            </div>
          </Collapse>
        );
    }
  }

  toTable() {
    return {
      headers: ['סוג בקשה', 'מספר חשבון', 'סטטוס בקשה', 'שם קופה ומסלול', 'תאריך פתיחת בקשה', '', ''],
      rows: this.state.requests.map((request, i) => {
        return {
          cells: [
            {
              value: request.requestTypeName,
            },
            {
              value: request.accountNumberTransfer || request.accountNumberAccepts,
              valueType: 'number',
            },
            {
              value: request.statusRequest,
              valueType: request.statusRequest.includes('ליקוי') ? ' td--dangerous' : '',
            },
            {
              value:
                request.requestTypeID === 21 || request.requestTypeID === 1
                  ? request.nameAccepts
                  : request.transferName,
            },
            {
              value: request.dateAcceptRequest && moment(request.dateAcceptRequest).format('DD/MM/YYYY'),
              valueType: 'number',
            },

            {
              value: this.getCollapseInfo(request, i),
              class: 'inner-collaspe',
            },
            {
              value: '',
              valueType: '',
            },
          ],
        } as IResponsiveTableRow;
      }),
    };
  }

  render() {
    return (
      <>
        <TopDucks small={true} />
        <div className="status-intro">
          <div className="content-wrapper">
            <div className="row status-intro__row">
              <div className="col-lg status-intro__col-text">
                <h1 className="status-intro__heading">{this.cmsContent && this.cmsContent.title}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper content-wrapper--sheet">
          <div className="content-sheet">
            {/* {this.state.currReq && (
              <div className="request-details">
                <div onClick={() => this.setState({ currReq: null, isDropDownOpen: false })} className="close-head">
                  <img src={require('../../img/close.svg')} alt="close" />
                  <span className="text">סגור</span>
                </div>
                {this.getCollapseInfo(this.state.currReq)}
              </div>
            )} */}
            <div className="status-data">
              <div className="pane-content pane-content--requests">
                <section className="data-section data-section--concentration">
                  <div className="filter-block-wrapper">
                    <div className="filter-block">
                      <h3 className="filter-block__heading">סנן לפי</h3>
                      <button
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFilter"
                        aria-expanded="true"
                        aria-controls="collapseFilter"
                        aria-label="toggle controls"
                        className="filter-block__toggle"
                      >
                        <span className="caption">סנן לפי</span>
                      </button>
                      <div id="collapseFilter" className="filter-block__collapse collapse show">
                        <form id="req-filter-form" className="data-filter filter-block__form">
                          <div className="data-filter__row">
                            <div className="data-filter__inputs">
                              <div className="data-filter__inputs-row">
                                <div className="data-filter__col">
                                  <label htmlFor="df-accounts" className="sr-only">
                                    כל החשבונות
                                  </label>
                                  <CustomSelect
                                    id="df-accounts"
                                    buttonClassName="data-filter__input data-filter__input--accounts dropdown-user-id"
                                    options={this.state.accounts}
                                    value={this.state.selectedAccount}
                                    onChange={(value) => {
                                      this.setState({ selectedAccount: value });
                                    }}
                                  />
                                </div>
                                <div className="data-filter__col">
                                  <label htmlFor="df-type" className="sr-only">
                                    כל הסוגים
                                  </label>
                                  <CustomSelect
                                    id="df-type"
                                    buttonClassName="data-filter__input data-filter__input"
                                    options={this.state.types}
                                    value={this.state.selectedType}
                                    onChange={(value) => {
                                      this.setState({ selectedType: value });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="data-filter__buttons">
                              <div className="data-filter__buttons-row">
                                <div className="col-auto data-filter__buttons-col">
                                  <button
                                    type="button"
                                    className="data-filter__btn data-filter__btn--reset"
                                    onClick={this.resetForm.bind(this)}
                                  >
                                    נקה סינון
                                  </button>
                                </div>
                                <div className="col-auto data-filter__buttons-col">
                                  <button
                                    type="button"
                                    className="data-filter__btn"
                                    onClick={this.submitForm.bind(this)}
                                  >
                                    סינון
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="requests-table-block">
                    {this.state.isLoading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                      </div>
                    ) : document.documentElement.clientWidth > 992 ? (
                      <div className="table requests-table">
                        {this.state.requests && (
                          <div className="thead">
                            <div className="tr d-none d-lg-flex">
                              <div className="th">סוג בקשה</div>
                              <div className="th">מספר חשבון</div>
                              <div className="th">שם קופה ומסלול</div>
                              <div className="th">תאריך פתיחת בקשה</div>
                              <div className="th">סטטוס בקשה</div>
                              <div className="th"></div>
                            </div>
                          </div>
                        )}
                        <div className="tbody">
                          {!this.state.isLoading && !this.state.requests && (
                            <div className="empty-data">{this.label('no_data')}</div>
                          )}
                          {this.state.requests &&
                            this.state.requests.length > 0 &&
                            this.state.requests.map((request, i) => (
                              <div className="tr" key={i}>
                                <div className="td">{request.requestTypeName}</div>
                                <div className="td number">
                                  {request.requestTypeID === 21 || request.requestTypeID === 1
                                    ? request.accountNumberAccepts
                                    : request.accountNumberTransfer || request.accountNumberAccepts}
                                </div>
                                <div className="td">
                                  {request.requestTypeID === 21 || request.requestTypeID === 1
                                    ? request.nameAccepts
                                    : request.transferName}
                                </div>
                                <div className="td number">
                                  {request.dateAcceptRequest && moment(request.dateAcceptRequest).format('DD/MM/YYYY')}
                                </div>
                                <div
                                  className={'td' + (request.statusRequest.includes('ליקוי') ? ' td--dangerous' : '')}
                                >
                                  {request.statusRequest}
                                </div>
                                <div className="td">
                                  <button
                                    className={'btn-toggle' + (request.isOpen ? ' btn-toggled' : '')}
                                    onClick={() => {
                                      request.isOpen = !request.isOpen;
                                      this.forceUpdate();
                                      if (request.isOpen) this.getReqFiles(request.fileId, i);
                                    }}
                                  >
                                    {request.isOpen ? 'סגור' : 'לפרטים'}
                                  </button>
                                </div>
                                <div className="collapse-container">{this.getCollapseInfo(request, i)}</div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : (
                      this.state.requests && <ResponsiveTable name="concentration" table={this.toTable()} />
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <CmsBanners banners={this.cmsContent && this.cmsContent.banners} />
      </>
    );
  }
}
