import { BaseSecuredApi } from './BaseSecuredApi';
import { UserDataService } from '../services';

export interface IGetUserName {
  data: string;
  statusCode: number;
}

export interface ILoginResult {
  success: boolean;
  message: string;
  token: string;
  status?: number;
  customerId?: number;
  isRevaluationProcess?: boolean;
  isBlockedToAction?: boolean;
  result: any;
  jsonData: string;
  hasLoan?: boolean;
  toDisplayCourseMargePopUp?: boolean;
  margeMessageType?:number;
  isMergePpopupShow?: number


  displayLetterPopUp?:boolean;
  sumUnViewed ?: number;
  sumLetter?: number;
  isShowPopup?:number
}
export interface IRemoteLoginResult {
  success: boolean;
  username: string;
  status?: number;
  token: string;
  jsonData: string;
  hasLoan?: boolean;
  isFormsAuthorized?: boolean;
  isProd?: boolean;
  isDigitalLoanAuthorized?: boolean;
  toDisplayCourseMargePopUp?: boolean;
  isMergePpopupShow?: number

  margeMessageType?:number;
  displayLetterPopUp?:boolean;
  sumUnViewed ?: number;
  sumLetter?: number;
  isShowPopup?:number


}
export interface ISendOtpResult {
  success: boolean;
  message: string;
  status?: number;
  result: {
    token: string;
    cellnumber4Digits: string;
  };
  isRevaluationProcess?: boolean;
}
export interface ILoginOtpResult {
  success: boolean;
  message: string;
  status?: number;
  customerId?: number;
  result: {
    token: string;
    username: string;
    jsonData: string;
    hasLoan?: boolean;
    toDisplayCourseMargePopUp?: boolean;
    margeMessageType?:number;
    isMergePpopupShow?: number

    displayLetterPopUp?:boolean;
    sumUnViewed ?: number;
  sumLetter?: number;
  isShowPopup?:number


  };
    hasLoan?: boolean;
    isBlockedToAction?: boolean;
    
}


class Login extends BaseSecuredApi {
  constructor() {
    super('Login');
  }

  async login(username: string, password: string, personalId: string) {
    const { token: captchaToken } = await UserDataService.getReCaptchaToken();
    return this.POST_LOGIN<ILoginResult>({
      data: {
        captchaToken,
        username,
        password,
        personalId,
      },
    });
  }

  async sendOtp(personalId: string, cellnumber: string, method: number) {
    const { token: captchaToken } = await UserDataService.getReCaptchaToken();
    return this.POST_LOGIN<ISendOtpResult>({
      action: 'loginViaOtp',
      data: {
        personalId,
        cellnumber,
        captchaToken,
        method,
      },
    });
  }

  async loginViaOtp(token: string, code: string) {
    const { token: captchaToken } = await UserDataService.getReCaptchaToken();
    return this.POST_LOGIN<ILoginOtpResult>({
      action: 'loginViaOtp',
      data: {
        token,
        code,
        captchaToken,
      },
    });
  }

  async resendOtp(token: string, personalId: string, cellnumber: string, method: number) {
    const { token: captchaToken } = await UserDataService.getReCaptchaToken();
    return this.POST_LOGIN<ILoginResult>({
      action: 'resendOtp',
      data: {
        token,
        captchaToken,
        personalId,
        cellnumber,
        method,
      },
    });
  }

  sendResetPassword(personalId: string, cellnumber: string) {
    return this.POST<ILoginResult>({
      action: 'sendResetPassword',
      data: {
        personalId,
        cellnumber,
      },
    });
  }

  async sendGetUsername(personalId: string, dateofbirth: string, accnum: string) {
    const { token: captchaToken } = await UserDataService.getReCaptchaToken();
    return this.POST<IGetUserName>({
      action: 'sendGetUsername',
      data: {
        personalId,
        dateofbirth,
        accnum,
        captchaToken,
      },
    });
  }

  validateResetPassword(token: string) {
    return this.POST<ILoginResult>({
      action: 'validateResetPassword',
      data: {
        token,
      },
    });
  }

  resetPassword(token: string, newPassword: string) {
    return this.POST<ILoginResult>({
      action: 'resetPassword',
      data: {
        token,
        newPassword,
      },
    });
  }

  remoteLogin(token: string) {
    return this.POST<IRemoteLoginResult>({
      action: 'remoteLogin',
      data: {
        token,
      },
    });
  }
}

export const LoginApi = new Login();
