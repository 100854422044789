import { BaseSecuredApi } from "./BaseSecuredApi";
import { IChartProps } from "../components/Macros/Chart";

export interface IGetDashboardResult {
    updateDate?: string,
    total?: number,
    totalTransactionAmount?:any,
    charts?: IChartProps[],
    showGemel?:any
}

class Dashboard extends BaseSecuredApi {

    constructor() {
        super("Dashboard");
    }

    get() {
        return this.POST<IGetDashboardResult>({
            cache: true
        });
    }

}

export const DashboardApi = new Dashboard();