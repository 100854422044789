import { BaseSecuredApi } from "./BaseSecuredApi";

export interface ISendRequest {
    subjectId: string,
    phone: string,
    email: string,
    content: string
}

class ContactUs extends BaseSecuredApi {

    constructor() {
        super("ContactUs");
    }

    send(request: ISendRequest) {
        return this.POST<{ success: boolean }>({
            action: "send", data: request
        });
    }

}

export const ContactUsApi = new ContactUs();