import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import * as ServiceWorker from './serviceWorker';
import history from './history';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.min.css';
import './style/app.sass';

const rootElement = document.getElementById('root');
if (!history.location.pathname.includes('login') && history.location.pathname !== '/customers/home/dashboard') {
  sessionStorage.setItem('returnUrl', history.location.pathname);
}
ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  rootElement
);

ServiceWorker.unregister();
