import { BaseSecuredApi } from './BaseSecuredApi';
import { Subject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';



export interface IGetRequest {
  //   year: number;
  //   fundID: number;
  pageSize: number;
  pageNo: number;
  viewd:boolean;
}

export interface IExportResult {
  downloadUrl: string;
}


export interface ILetters {
  id: number;
  fundName: string;
  accountID: number;
  letterTypeID: number;
  letterCreatedDate: Date;
  syncDate: Date;
  displayName: string;
  isViewed: boolean;
}

export interface IShowLetter{
  id: number;
  isViewed: boolean;
  letterCreatedDate: Date;
  displayName: string;

}

export interface IshowResponse
{
  totalCount: number;
  totalUnViewedCount:number;
  letters:ILetters[]
}

class CustomerLetter extends BaseSecuredApi {
  constructor() {
    super('CustomerLetter');
  }

  get(data: IGetRequest) {
    return this.POST<IshowResponse>({
      action: '',
      data,
    });
  }


  delete(letterId: number) {
    return this.GET<any>({
      action: `delete/${letterId}`,
    });
  }


  deleteAll() {
    return this.GET<any>({
      action: 'delete/viewed'
    })
  }

  getUnReadNumber() {
    return this.POST<any>({
      action: 'statistics'
    })
  }

  Download(letterId: string) {
    return this.DownloadBlob<any>({
      action: 'pdf',
      data: { letterId: letterId }
    }).catch((err) => {
      console.log(err);

      return err;
    });;
  }
}

export const CustomerLetterApi = new CustomerLetter();
