import React from 'react';
import { BaseComponent } from '..';
import { TopDucks, CmsBanners, CustomSelect, FormWithValidation } from '../Macros';
import { ICmsPage } from '../../api/Cms';
import { ContactUsApi } from '../../api';
import { ISendRequest } from '../../api/ContactUs';

interface IContactUsCmsPage extends ICmsPage {
    phone: string;
    fax: string;
    email: string;
    address: string;
    addressdescription: string;
    subjects: { id: string, subject: string }[];
}

export default class ContactUs extends BaseComponent<{}, { sending: boolean, sent: boolean }> {

    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            sending: false,
            sent: false
        };
    }

    cmsPageAlias = 'contactus';

    rules = [
        {
            "input": "phone",
            "validators": ['phone']
        },
        {
            "input": "email",
            "validators": ['email']
        }
    ];

    get cmsContactUsContent() {
        return this.cmsContent as IContactUsCmsPage;
    }

    onSubmit(event: React.FormEvent<HTMLFormElement>) {

        if (!this.state.sending) {

            this.setState({
                sending: true
            });

            var request = this.deserializeForm<ISendRequest>(event.currentTarget);
            ContactUsApi.send(request).then(res => {
                this.setState({
                    sending: false,
                    sent: res.success
                });
            });

        }

        event.preventDefault();
    }

    render() {
        return <>

            <TopDucks small={true} />

            <div className="contact-intro">
                <div className="content-wrapper">
                    <h1 className="contact-intro__heading">{this.cmsContactUsContent && this.cmsContactUsContent.title}</h1>
                </div>
            </div>

            <div className="content-wrapper content-wrapper--sheet">
                <div className="content-sheet">
                    <div className="contact-data">
                        <div className="contact-data__header">
                            <h2 className="contact-data__heading">{this.cmsContactUsContent && this.cmsContactUsContent.subtitle}</h2>
                            <p className="contact-data__header-text" style={{ whiteSpace: "pre-line" }}>
                                {this.cmsContactUsContent && this.cmsContactUsContent.intro}
                            </p>
                        </div>

                        <div className="contact-data__details">
                            <ul className="contact-data__list">
                                <li className="contact-data__item">
                                    <span className="contact-data__item-type">
                                        <svg width="18" height="18" className="icon-phone">
                                            <use xlinkHref="#icon-phone"></use>
                                        </svg>
                                        טלפון
                                    </span>
                                    <p className="contact-data__item-value number">
                                        <a href={"tel:" + (this.cmsContactUsContent && this.cmsContactUsContent.phone)}>{this.cmsContactUsContent && this.cmsContactUsContent.phone}</a>
                                    </p>
                                </li>
                                <li className="contact-data__item">
                                    <span className="contact-data__item-type">
                                        <svg width="18" height="18" className="icon-fax">
                                            <use xlinkHref="#icon-fax"></use>
                                        </svg>
                                        פקס
                                    </span>
                                    <p x-ms-format-detection="none" className="contact-data__item-value number">
                                        {this.cmsContactUsContent && this.cmsContactUsContent.fax}
                                    </p>
                                </li>
                                <li className="contact-data__item">
                                    <span className="contact-data__item-type">
                                        <svg width="18" height="18" className="icon-mail">
                                            <use xlinkHref="#icon-mail"></use>
                                        </svg>
                                        דוא״ל
                                    </span>
                                    <p className="contact-data__item-value number">
                                        <a href={"mailto:" + (this.cmsContactUsContent && this.cmsContactUsContent.email)}>{this.cmsContactUsContent && this.cmsContactUsContent.email}</a>
                                    </p>
                                </li>
                                <li className="contact-data__item">
                                    <span className="contact-data__item-type">
                                        <svg width="18" height="18" className="icon-mapmarker">
                                            <use xlinkHref="#icon-mapmarker"></use>
                                        </svg>
                                        כתובת
                                    </span>
                                    <p className="contact-data__item-value">
                                        {this.cmsContactUsContent && this.cmsContactUsContent.address}
                                        <em className="notice" style={{ whiteSpace: "pre-line" }}>{this.cmsContactUsContent && this.cmsContactUsContent.addressdescription}</em>
                                    </p>
                                </li>
                            </ul>
                        </div>

                        {this.state.sent ?
                            <h3 className="text-success">תודה, פניתך התקבלה בהצלחה!</h3>
                            :
                            <div className="contact-data__form-block">
                                <FormWithValidation rules={this.rules} className="cp-form" onSubmit={this.onSubmit.bind(this)}>
                                    <h2 className="cp-form__heading">{this.label("contact_title")}</h2>
                                    <div className="cp-form__row">
                                        <div className="cp-form__col-inputs">
                                            <div className="cp-form__form-group">
                                                <label htmlFor="cp-topic" className="sr-only">בחירת נושא הפניה</label>
                                                <CustomSelect id="cp-topic" name="subjectId" required buttonClassName="cp-form__input"
                                                    options={this.cmsContactUsContent.subjects.map(x => { return { label: x.subject, value: x.id }; })}
                                                />
                                            </div>
                                            <div className="cp-form__form-group">
                                                <label htmlFor="cp-phone" className="sr-only">טלפון לחזרה</label>
                                                <input type="text" placeholder="טלפון לחזרה" name="phone" id="cp-phone" required className="cp-form__input" />
                                            </div>
                                            <div className="cp-form__form-group">
                                                <label htmlFor="cp-email" className="sr-only">דוא״ל</label>
                                                <input type="email" placeholder="דוא״ל" id="cp-email" required name="email" className="cp-form__input" />
                                            </div>
                                        </div>
                                        <div className="cp-form__col-fill">
                                            <div className="cp-form__form-group">
                                                <label htmlFor="cp-message" className="sr-only">פרטי הפנייה</label>
                                                <textarea name="content" id="cp-message" placeholder="פרטי הפנייה" maxLength={5000} required className="cp-form__input cp-form__input--textarea"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cp-form__footer">
                                        <button type="submit" className="cp-form__submit" disabled={this.state.sending}>שליחה</button>
                                    </div>
                                </FormWithValidation>
                            </div>
                        }

                    </div>

                </div>
            </div>


            <CmsBanners banners={this.cmsContent && this.cmsContent.banners} />

        </>;
    }
}
