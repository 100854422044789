import React from "react";
import { BaseComponent } from "../";

export default class LoginLayout extends BaseComponent<{}, {}> {
  render() {
    return (
      <header className="site-header">
        <div className="site-header__top">
          <div className="container-fluid">
            <div className="row site-header__row">
              <a href="https://www.yl-invest.co.il" title="go to Yalin Lapidot home page" className="navbar-brand yalin-brand">
                <img src="img/yalin-logo-desktop.svg" alt="Site logo" width="239" height="48" className="d-none d-lg-block" />
                <img src="img/yalin-logo-mobile.png" alt="Site logo" width="74" height="34" className="d-lg-none" />
              </a>
              {/* <div id="yalin-login-menu" className="collapse navbar-collapse yalin-login-collapse">
                <nav className="login-menu">
                  <ul className="navbar-nav login-menu__navbar-nav" style={{ visibility: "hidden" }}>
                    <li className="nav-item login-menu__nav-item active">
                      <a href="" className="login-menu__nav-link">
                        כניסת עמיתים
                        <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li className="nav-item login-menu__nav-item">
                      <a href="" className="login-menu__nav-link">
                        כניסת עמיתים
                      </a>
                    </li>
                    <li className="nav-item login-menu__nav-item">
                      <a href="" className="login-menu__nav-link">
                        כניסת מעסיקים
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
          </div>
        </div>
      </header>
    );
  }
}
