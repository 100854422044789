import React from 'react';
import { BaseComponent } from '..';
import { AccountStatusApi } from '../../api';
import { IGetTransactionsResponse } from '../../api/AccountStatus';
import CustomSelect, { ICustomSelectItem } from '../Macros/CustomSelect';
import CustomMultiSelect from '../Macros/CustomMultiSelect';

interface IProps {
  accountId: string;
}

interface IRequestsState {
  currentPage: number;

  totalPages: number;

  selectedYear: string;
  years: ICustomSelectItem[];

  selectedType: string;
  types: ICustomSelectItem[];

  selectedDateFrom: string;
  selectedDateTo: string;

  result?: IGetTransactionsResponse;
  isLoading: boolean;
  chkbox: boolean;
  comment: string;
  viewportWidth: number;
}

declare const $: any;

export default class Transactions extends BaseComponent<IProps, IRequestsState> {
  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
      currentPage: 1,
      totalPages: 0,
      selectedYear:
        sessionStorage.getItem('maxRevaluationDate') == null
          ? new Date().getFullYear().toString()
          : sessionStorage.getItem('maxRevaluationDate'),
      selectedDateFrom: null,
      selectedDateTo: null,
      selectedType: '0',
      viewportWidth: document.documentElement.clientWidth,
      years: this.getYearsArray('SYBT').map((x) => (
        { label: x.toString(), value: x.toString() } as ICustomSelectItem)),
      types: ['הפקדה', 'משיכה', 'העברה פנימה', 'העברה החוצה', 'דמי ניהול', 'שערוך חודשי', 'העברה בין מסלולים','העברה מחשבון אחר'].map(
        (label, index) => ({ label: label, value: index.toString() } as ICustomSelectItem)
      ),

      isLoading: false,
      chkbox: true,
      comment: this.label('account_transactionsdisc').replace('{date}', sessionStorage.getItem('updatedate')),
    };
  }

  componentDidMount() {
    $('.revaluationfilter').css('display', 'none');
    /* Date pickers */
    if ($('#transactions-filter-form').length) {
      //init:
      $('#transactions-filter-form').datepicker({
        inputs: $('.js-datepicker'),
        language: 'he',
        format: 'dd.mm.yyyy',
        todayHighlight: true,
        orientation: 'bottom',
        container: '#transactions-filter-form',
      });

      //on change:
      $('#df-date-from').on('changeDate', (event: Event) => {
        this.setState({
          selectedDateFrom: $(event.target).data('datepicker').getFormattedDate('yyyy-mm-dd'),
        });
        $('#df-date-from').datepicker('hide');
      });

      $('#df-date-to').on('changeDate', (event: Event) => {
        this.setState({
          selectedDateTo: $(event.target).data('datepicker').getFormattedDate('yyyy-mm-dd'),
        });
        $('#df-date-to').datepicker('hide');
      });
    }
    this.fetchData();
    $("input[value='0']").click();
    $(`input[value=${this.state.selectedYear}]`).click();

    window.addEventListener('resize', this._resize_mixin_callback.bind(this));
  }
  _resize_mixin_callback() {
    this.setState({
      viewportWidth: document.documentElement.clientWidth,
    });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this._resize_mixin_callback.bind(this));
  }

  fetchData() {
    this.setState({
      isLoading: true,
    });
    var year = $('.multiselect-selected-text:nth(1)').parent().attr('title');
    if (isNaN(year) && year.indexOf(',') == -1) {
      year =
        sessionStorage.getItem('maxRevaluationDate') == null
          ? new Date().getFullYear().toString()
          : sessionStorage.getItem('maxRevaluationDate');
    }
    AccountStatusApi.getTransactions({
      pageNumber: this.state.currentPage,
      year, //this should come from the state obj
      type: $('.multiselect-selected-text:nth(3)').parent().attr('title'), //this should come from the state obj
      fromDate: this.state.selectedDateFrom,
      toDate: this.state.selectedDateTo,
      revaluation: $('#chkrevaluation').prop('checked'),
      accountNumber: this.props.accountId,
    }).then((res) => {
      this.setState({
        isLoading: false,
        result: res,
        totalPages: res ? res.totalPages : 0,
      });
    });
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.accountId !== prevProps.accountId) {
      //this.fetchData();
      this.setState({ currentPage: 1 }, this.fetchData);
    }
  }

  clearFilter() {
    $('.multiselect-container input').each(function (i: number, o: any) {
      if (!$(o).closest('li').hasClass('active')) {
        $(o).click();
      }
    });

    $('.multiselect-container input').each(function (i: number, o: any) {
      if (!$(o).closest('li').hasClass('active')) {
        $(o).click();
      }
    });

    $('.data-filter__btn').click();
  }

  resetForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      selectedYear: new Date().getFullYear().toString(),
      selectedDateFrom: '',
      selectedDateTo: '',
      selectedType: '0',
    });

    document.querySelectorAll('.js-datepicker').forEach((x) => {
      $(x).datepicker('update', '');
    });

    event.preventDefault();
  }

  submitForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.fetchData();
    event.preventDefault();
  }

  filter() {
    var year = $('.multiselect-selected-text:nth(1)').parent().attr('title');
    var type = $('.multiselect-selected-text:nth(3)').parent().attr('title');

    if (
      (year.indexOf(',') == -1 && isNaN(year)) ||
      (type.indexOf(',') == -1 &&
        !['הפקדה', 'משיכה', 'העברה פנימה', 'העברה החוצה', 'דמי ניהול', 'שערוך חודשי', 'העברה בין מסלולים','העברה מחשבון אחר'].includes(
          type
        ))
    ) {
      alert('עליך לבחור שנה וסוג תנועה');
      return;
    }

    this.setState({ currentPage: 1 }, this.fetchData);
  }

  toggleView(id: number) {
    //    row-toggle row-toggle--open
    if ($('.closed_' + id).hasClass('hidden')) {
      $('.closed_' + id).removeClass('hidden');
      $('#toggle_' + id).addClass('row-toggle--open');
    } else {
      $('.closed_' + id).addClass('hidden');
      $('#toggle_' + id).removeClass('row-toggle--open');
    }
  }

  paging(page: number) {
    this.setState({ currentPage: page }, this.fetchData);
  }
  render() {
    return (
      <div className="pane-content pane-content--transactions">
        <section className="data-section data-section--transactions">
          <div className="data-section__header data-section__header--transactions">
            <h3 className="data-section__title">{this.label('account_movTitle')}</h3>
          </div>
          <div className="data-section__body">
            <div className="filter-block-wrapper">
              <div className="filter-block">
                <h3 className="filter-block__heading">סנן לפי</h3>
                <button
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFilter"
                  aria-expanded="true"
                  aria-controls="collapseFilter"
                  aria-label="toggle controls"
                  className="filter-block__toggle"
                >
                  <span className="caption">סנן לפי</span>
                </button>
                <div id="collapseFilter" className="collapse filter-block__collapse show">
                  <form id="transactions-filter-form" className="data-filter filter-block__form">
                    <div className="data-filter__row">
                      <div className="data-filter__inputs">
                        <div className="data-filter__inputs-row">
                          <div className="data-filter__col">
                            <label
                              htmlFor="df-yearMulti"
                              style={
                                this.state.viewportWidth < 900
                                  ? ({ width: '20px' } as React.CSSProperties)
                                  : ({ width: '20px' } as React.CSSProperties)
                              }
                            >
                              שנה
                            </label>
                            <CustomMultiSelect
                              id="df-yearMulti"
                              buttonClassName="data-filter__input number"
                              options={this.state.years}
                              label={'לא בוצעה בחירה'}
                              value={this.state.selectedYear}
                              onChange={(value) => {
                                this.setState({ selectedYear: value });
                              }}
                            />
                          </div>
                          <div className="data-filter__col" style={{ display: 'none' }}>
                            <label htmlFor="df-date-from" className="sr-only">
                              מתאריך
                            </label>
                            <input
                              id="df-date-from"
                              type="text"
                              name="df-date-from"
                              placeholder="מתאריך"
                              autoComplete="off"
                              className="data-filter__input js-datepicker"
                            />
                          </div>
                          <div className="data-filter__col" style={{ display: 'none' }}>
                            <label htmlFor="df-date-to" className="sr-only">
                              עד תאריך
                            </label>
                            <input
                              id="df-date-to"
                              type="text"
                              name="df-date-to"
                              placeholder="עד תאריך"
                              autoComplete="off"
                              className="data-filter__input js-datepicker"
                            />
                          </div>
                          <div className="data-filter__col">
                            <label
                              style={
                                this.state.viewportWidth < 900
                                  ? ({ width: '20px' } as React.CSSProperties)
                                  : ({ width: '14px' } as React.CSSProperties)
                              }
                              htmlFor="df-typeMulti"
                            >
                              סוג
                            </label>
                            <label htmlFor="df-type" className="sr-only">
                              סוג
                            </label>
                            <CustomMultiSelect
                              id="df-typeMulti"
                              buttonClassName="data-filter__input number"
                              options={this.state.types}
                              label={'לא בוצעה בחירה'}
                              value={this.state.selectedType}
                              onChange={(value) => this.setState({ selectedType: value })}
                            />
                          </div>
                          <div className="data-filter__col">
                            <label htmlFor="df-type" className="sr-only">
                              הצג תנועות לאחר תאריך שערוך אחרון
                            </label>
                            <input
                              id="chkrevaluation"
                              defaultChecked={this.state.chkbox}
                              type="checkbox"
                              style={{
                                position: 'relative',
                                top: '9px',
                                left: window.navigator.msSaveOrOpenBlob ? '10px' : '',
                              }}
                              onChange={(e) => {
                                e.target.checked
                                  ? $('.revaluationfilter').css('display', '')
                                  : $('.revaluationfilter').css('display', 'none');
                                this.setState({ chkbox: e.target.checked });
                              }}
                            />
                            <span style={{ position: 'relative', top: '7px', right: '6px' }}>
                              הצג תנועות לאחר תאריך שערוך אחרון
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="data-filter__buttons">
                        <div className="data-filter__buttons-row">
                          <div className="col-auto data-filter__buttons-col">
                            <button
                              type="reset"
                              className="data-filter__btn data-filter__btn--reset"
                              onClick={this.clearFilter}
                            >
                              נקה
                            </button>
                          </div>
                          <div className="col-auto data-filter__buttons-col">
                            <button
                              type="submit"
                              className="data-filter__btn"
                              onClick={(ev) => {
                                ev.preventDefault();
                                this.filter();
                              }}
                            >
                              הצג
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="transactions-table-block">
              {this.state.isLoading ? (
                <div style={{ textAlign: 'center' }}>
                  <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                </div>
              ) : this.state.totalPages > 0 ? (
                <>
                  <div className="transactions-table hidebelow992">
                    <div className="thead">
                      <div className="tr">
                        <div className="th hidebelow992">לאחר שערוך</div>
                        <div className="th hidebelow992">{this.label('account_type')}</div>
                        <div className="th">{this.label('account_date2')}</div>
                        <div className="th">{this.label('account_salaryMonth')}</div>
                        <div className="th">{this.label('account_salary')}</div>
                        <div className="th">תגמולי עמית/עובד</div>
                        <div className="th">תגמולי מעסיק</div>
                        <div className="th">פיצויים</div>
                        <div className="th hidebelow992 nowrap">{this.label('accout_total')}</div>
                      </div>
                    </div>
                    <div className="tbody">
                      {this.state.result &&
                        this.state.result.transactions.map((t, i) => {
                          if (!t.revaluation && !this.state.chkbox) {
                            return;
                          }
                          return (
                            <div className={t.revaluation ? 'tr' : 'tr revaluationfilter'} key={i}>
                              <div className="td resTitle">לאחר שערוך</div>
                              <div className="td Rectangle">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  style={{
                                    fill: '#003874',
                                    display: !t.revaluation && this.state.chkbox ? 'block' : 'none',
                                  }}
                                >
                                  <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                </svg>
                              </div>

                              <div className="td resTitle">{this.label('account_type')}</div>
                              <div className="td">{t.type}</div>
                              <div className="td resTitle">{this.label('account_date2')}</div>
                              <div className="td">{t.date}</div>
                              <div className="td resTitle">{this.label('account_salaryMonth')}</div>
                              <div className="td">{t.month}</div>
                              <div className="td resTitle">{this.label('account_salary')}</div>
                              <div className="td number">{t.salary && t.salary.toLocaleString()}</div>
                              <div className="td resTitle">תגמולי עמית/עובד</div>
                              <div className="td number">
                                <span className="number">{t.customer && t.customer.toLocaleString()}</span>
                              </div>
                              <div className="td resTitle">תגמולי מעסיק</div>
                              <div className="td number">
                                <span className="number">{t.employer && t.employer.toLocaleString()}</span>
                              </div>
                              <div className="td resTitle">פיצויים</div>
                              <div className="td number">
                                <span className="number">{t.comp && t.comp.toLocaleString()}</span>
                              </div>
                              <div className="td resTitle">{this.label('accout_total')}</div>
                              <div className="td number">
                                <span className="number">{t.total && t.total.toLocaleString()}</span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="transactions-table hideabove992">
                    <div className="thead">
                      <div className="tr">
                        <div className="th" style={{}}>
                          {this.label('account_type')}
                        </div>
                        <div className="th" style={{ display: 'block' }}>
                          {this.label('account_date2')}
                        </div>
                        <div className="th">{this.label('accout_total')}</div>
                      </div>
                    </div>

                    <div className="tbody">
                      {this.state.result &&
                        this.state.result.transactions.map((t, i) => (
                          <div className={t.revaluation ? 'tr' : 'revaluationfilter tr'} key={i}>
                            <div className={'tr openedtr opened_' + i.toString()}>
                              <span className="td">{t.type}</span>
                              <span className="td">{t.date}</span>
                              <span className="number td">{t.total && t.total.toLocaleString()}</span>
                              <span className="td">
                                <button
                                  className="row-toggle"
                                  onClick={() => this.toggleView(i)}
                                  id={'toggle_' + i.toString()}
                                ></button>
                              </span>
                            </div>

                            <div
                              className={'tr hidden closed_' + i.toString()}
                              style={{ borderTop: '0px', paddingTop: '0px' }}
                            >
                              <span className="td resTitle">לאחר שערוך</span>
                              <span className="td Rectangle">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  style={{ fill: '#003874', display: !t.revaluation! ? 'block' : 'none' }}
                                >
                                  <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                </svg>
                              </span>

                              <span className="td resTitle">{this.label('account_salaryMonth')}</span>
                              <span className="td">{t.month}</span>
                              <span className="td resTitle">{this.label('account_salary')}</span>
                              <div className="td number">{t.salary && t.salary.toLocaleString()}</div>
                              <span className="td resTitle">תגמולי עמית/עובד</span>
                              <div className="td number">
                                <span className="number">{t.customer && t.customer.toLocaleString()}</span>
                              </div>
                              <span className="td resTitle">תגמולי מעסיק</span>
                              <div className="td number">
                                <span className="number">{t.employer && t.employer.toLocaleString()}</span>
                              </div>
                              <span className="td resTitle">פיצויים</span>
                              <div className="td number">
                                <span className="number">{t.comp && t.comp.toLocaleString()}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <ul className="d-flex justify-content-center flex-row-reverse p-4 pagination pagination-sm">
                    {Array.apply(0, Array(this.state.totalPages)).map((x, i) => {
                      return (
                        <li key={i} className={i + 1 === this.state.currentPage ? 'page-item active' : 'page-item'}>
                          <a
                            href="#"
                            key={i}
                            className="page-link"
                            role="button"
                            onClick={(ev) => {
                              ev.preventDefault();
                              this.paging(i + 1);
                            }}
                          >
                            {i + 1}
                          </a>
                        </li>
                      );
                    })}
                  </ul>

                  <style>
                    {' '}
                    {'.discFont{ font-size:14px; font-family:"Almoni DL AAA", sans-serif; white-space:pre-wrap }'}{' '}
                  </style>
                  {this.state.comment ? (
                    <pre style={{ fontSize: '14px' }} className={'account-table-block__table-notice discFont'}>
                      {this.state.comment}
                    </pre>
                  ) : null}
                </>
              ) : (
                <div> {this.label('transactions_noresults')} </div>
              )}
            </div>
          </div>
        </section>

        {false && (
          <section className="data-section data-section--transactions">
            <div className="data-section__header data-section__header--transactions">
              <h3 className="data-section__title">תנועות שבוצעו לאחר שערוך</h3>
            </div>
            <div className="data-section__body">
              <div className="transactions-table-block">
                <div className="transactions-table">
                  <div className="thead">
                    <div className="tr">
                      <div className="th">{this.label('account_type')}</div>
                      <div className="th">{this.label('account_date2')}</div>
                      <div className="th">{this.label('account_salaryMonth')}</div>
                      <div className="th">{this.label('account_salary')}</div>
                      <div className="th">סכום עמית/עובד</div>
                      <div className="th">סכום מעסיק</div>
                      <div className="th">סכום פיצויים</div>
                      <div className="th">{this.label('accout_total')}</div>
                    </div>
                  </div>
                  <div className="tbody">
                    <div className="tr">
                      <div className="td"></div>
                      <div className="td"></div>
                      <div className="td"></div>
                      <div className="td"></div>
                      <div className="td"></div>
                      <div className="td"></div>
                      <div className="td"></div>
                      <div className="td"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
